import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { View } from 'react-native';
import { MaterialTextEditable } from 'materialTheme/src/theme/components/text/MaterialTextEditable';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import React, { useState } from 'react';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { getIconNameFromViewType, onPressViewOptions, renameView } from './ticketViewUtil';
import { I18n } from '../../i18n/I18n';
export default function TicketViewSelectorItem(props) {
    const [title, setTitle] = useState(props.view.name);
    const [isRenaming, setRenaming] = useState(false);
    const confirmRename = () => {
        const { view } = props;
        renameView(view.id, title);
        setRenaming(false);
    };
    const cancelRename = () => {
        setRenaming(false);
        setTitle(props.view.name);
    };
    const { view, index, onSelect, additionalData, viewDataHasChanged, selected } = props;
    const { userId } = CurrentUser;
    const canEdit = (!view.isPublic || CurrentUser.userId === view.createdBy) && !(view.id === 'main');
    let rightIcons = null;
    if (isRenaming)
        rightIcons = (<>
        <Icon icon="close" toolTip="" onPress={cancelRename}/>
        <Icon icon="check" toolTip="" onPress={confirmRename}/>
      </>);
    else if (canEdit)
        rightIcons = (<View style={{ position: 'absolute', right: 4 }}>
        <Icon icon="dots-vertical" toolTip="" onPress={onPressViewOptions(view, () => setRenaming(true), additionalData, viewDataHasChanged && selected)}/>
      </View>);
    const isOwnDefault = view.default && view.createdBy === userId;
    return (<View style={{ width: '100%', paddingVertical: 4, paddingHorizontal: ThemeManager.style.contentPaddingValue }}>
      <View key={`tabbarElement${index}`} style={{
            alignSelf: 'center',
            width: '100%',
            minWidth: 120,
            height: 48,
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
            backgroundColor: ThemeManager.style.formInputFilledBackgroundColor,
            borderRadius: ThemeManager.style.borderRadius,
            borderWidth: 1,
            borderColor: selected ? ThemeManager.style.brandPrimary : ThemeManager.style.formInputFilledBackgroundColor,
        }}>
        {isRenaming ? (<View style={{ flex: 1, paddingTop: 4, justifyContent: 'center', paddingHorizontal: 12 }}>
            <MaterialTextEditable placeholder={view.name} underlineColor="transparent" autoFocus autoSelectToEnd onChange={(text) => setTitle(text.substring(0, 56))} onSubmitByEnter={confirmRename} materailTextProps={{ textAlign: 'center' }}>
              {title}
            </MaterialTextEditable>
          </View>) : (<Ripple accessibilityLabel="tabbarElement" style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: 48,
                flexDirection: 'row',
                position: 'relative',
                paddingHorizontal: 12,
            }} onPress={onSelect}>
            <View style={{ position: 'absolute', left: 8 }}>
              <Icon icon={isOwnDefault ? 'star-outline' : getIconNameFromViewType(additionalData['type'] ?? 'list')} toolTip="" iconSize={20}/>
            </View>
            <View style={{
                height: 'auto',
                alignSelf: 'center',
                paddingRight: 40,
                paddingLeft: 44,
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
            }}>
              <MaterialText centeredText centeredBox numberOfLines={1} type={MaterialTextTypes.Button}>
                {view.name}
              </MaterialText>
              {viewDataHasChanged && selected && (<View style={{
                    marginLeft: 8,
                    height: 6,
                    width: 6,
                    borderRadius: 3,
                    backgroundColor: ThemeManager.style.defaultTextColor,
                }}/>)}
              {view.isPublic && (<View style={{ marginLeft: 8 }}>
                  <Icon icon="web" toolTip={I18n.m.getMessage('ticketsViewPublic')} iconSize={20} color={ThemeManager.style.brandPrimary}/>
                </View>)}
            </View>
          </Ripple>)}
        {rightIcons}
      </View>
    </View>);
}
