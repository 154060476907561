import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { TabBar } from 'materialTheme/src/theme/components/tabs/TabBar';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
import { AbsencesView } from '../absences/AbsencesView';
import { LogBook } from '../logbook/LogBook';
import { TimeTrackingView } from '../timetrackings/TimeTrackingView';
import { AllTicketsView } from './AllTicketsView';
import { CompanyUserInfo } from './CompanyUserInfo';
import DashboardView from './DashboardView';
import { GlobalBar } from './GlobalBar';
import { HomeProjectsView } from './HomeProjectsView';
export class HomeViewContent extends PureComponent {
    static getSiteIndex(s) {
        if (s === 'activities') {
            return 5;
        }
        if (s === 'timeTracking') {
            return 3;
        }
        if (s === 'allTickets') {
            return 2;
        }
        if (s === 'dashboard') {
            return 1;
        }
        if (s === 'absences') {
            return 4;
        }
        return 0;
    }
    static getDerivedStateFromProps(nextProps, nextSate) {
        const selectedTab = HomeViewContent.getSiteIndex(nextProps.site);
        if (nextSate.selectedTab !== selectedTab) {
            return { selectedTab, init: nextProps.init != null };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.mounted = true;
        this.updateCompanySettings = (companySettings) => this.setState({ companySettings });
        this.initFilter = () => {
            if (this.props.init == null) {
                const timeTrackingModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
                let ft;
                let fab;
                const f = SimpleStorage.get(`${CurrentUser.userId}_saved_projectfilter`);
                const fat = SimpleStorage.get(`${CurrentUser.userId}_saved_allticketsfilter`);
                if (timeTrackingModule) {
                    ft = SimpleStorage.get(`${CurrentUser.userId}_saved_timetrackingfilter2`);
                    fab = SimpleStorage.get(`${CurrentUser.userId}_saved_absencesfilter`);
                }
                const query = { ft, fab, fat, f, init: '1' };
                requestAnimationFrame(() => {
                    Routing.instance.changeQueryParameter(query);
                });
            }
        };
        this.backToPageOne = () => {
            Routing.instance.goTo('/projects');
        };
        this.changeTab = (index) => {
            this.setState({ selectedTab: index }, () => {
                if (index === 3) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/timeTracking'));
                }
                else if (index === 4) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/absences'));
                }
                else if (index === 2) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/allTickets'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
                else if (index === 1) {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/dashboard'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
                else {
                    Routing.instance.goTo(GlobalBar.getMainLinksWithSavedFilter('/projects'));
                    if (Fab.isOpened) {
                        Fab.instance?.close();
                    }
                }
            });
        };
        this.getItemKey = (item, _index) => `project_${item.id}`;
        this.state = {
            selectedTab: HomeViewContent.getSiteIndex(this.props.site),
            companySettings: CompanyUserInfo.companySettings,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CompanyUserInfo.companySettingsChanged.attach(this.updateCompanySettings);
        this.initFilter();
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        this.initFilter();
    }
    componentWillUnmount() {
        this.mounted = false;
        CompanyUserInfo.companySettingsChanged.detach(this.updateCompanySettings);
        if (Fab.instance != null)
            Fab.instance.close();
    }
    getTime() {
        return I18n.m.dateCurrent.getLocalTimeString(new Date());
    }
    getActivitiesView(_secondViewWidth, secondViewHeight) {
        return (<View style={{
                width: '100%',
                height: secondViewHeight + ThemeManager.style.headerHeight,
                backgroundColor: 'transparent',
                alignItems: 'center',
            }}>
        <LogBook height={secondViewHeight + ThemeManager.style.headerHeight}/>
      </View>);
    }
    getAbsencesView(secondViewWidth, secondViewHeight) {
        return <AbsencesView {...this.props} secondViewWidth={secondViewWidth} secondViewHeight={secondViewHeight}/>;
    }
    renderProjectsView() {
        return <HomeProjectsView {...this.props}/>;
    }
    renderTabBar() {
        const { size } = this.props;
        const { selectedTab, companySettings } = this.state;
        const sView = size.windowWidth <= ThemeManager.style.breakpointM;
        if (sView) {
            const tabs = [
                {
                    title: I18n.m.getMessage('projects'),
                    icon: 'office-building',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'projects',
                },
                {
                    title: I18n.m.getMessage('dashboard'),
                    icon: 'view-dashboard-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'dashboard',
                },
                {
                    title: I18n.m.getMessage('allTickets'),
                    icon: 'check-circle-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'allTickets',
                },
            ];
            if (companySettings != null && companySettings.hasModule('timeTracking')) {
                tabs.push({
                    title: I18n.m.getMessage('timeTrackingShort'),
                    icon: 'timer-outline',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'timeTracking',
                });
                tabs.push({
                    title: I18n.m.getMessage('absences'),
                    icon: 'calendar-arrow-right',
                    hideTitle: size.windowWidth < 600,
                    accessibilityLabel: 'absences',
                });
            }
            return (<View key="Tabbar" style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#000000',
                    height: ResizeEvent.current.contentBottom + 48,
                    width: ResizeEvent.current.windowWidth,
                }}>
          <TabBar indicatorOnTop textColor="rgb(255, 255, 255)" unfocusedTextColor="rgba(255, 255, 255, 0.5)" contentHeight={0} width={ResizeEvent.current.windowWidth} onChangeSelected={this.changeTab} selected={selectedTab} upperCase={false} underlineColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" borderHeight={4} minItemWidth={ResizeEvent.current.windowWidth / tabs.length} textProps={{
                    fontSize: 12,
                }} tabs={tabs}/>
        </View>);
        }
        return <View key="noTabbar"/>;
    }
    render() {
        const { companySettings } = this.state;
        const { contentHeight } = this.props.size;
        const { size } = this.props;
        const { selectedTab } = this.state;
        const sView = size.windowWidth <= ThemeManager.style.breakpointM;
        const menuHeight2 = !sView ? 0 : 48;
        const secondViewHeight = contentHeight - ThemeManager.style.headerHeight - menuHeight2;
        let visibleTab = <View />;
        if (selectedTab === 0) {
            visibleTab = this.renderProjectsView();
        }
        else if (selectedTab === 2) {
            visibleTab = <AllTicketsView {...this.props} goBackHome={this.backToPageOne} height={secondViewHeight}/>;
        }
        else if (selectedTab === 3) {
            visibleTab = (<TimeTrackingView size={size} ft={this.props.ft} companySettings={companySettings} goBackHome={this.backToPageOne} height={secondViewHeight}/>);
        }
        else if (selectedTab === 1) {
            visibleTab = <DashboardView width={size.contentWidth} height={secondViewHeight} size={size}/>;
        }
        else if (selectedTab === 4) {
            visibleTab = this.getAbsencesView(size.contentWidth, secondViewHeight);
        }
        else if (selectedTab === 5) {
            visibleTab = this.getActivitiesView(size.windowWidth, secondViewHeight);
        }
        if (this.props.init == null)
            visibleTab = <Spinner />;
        return [
            <View key="HOMEVIEWCONTENT" style={{
                    position: 'absolute',
                    paddingTop: ResizeEvent.current.contentTop,
                    top: 0,
                    left: ResizeEvent.current.contentLeft,
                    right: ResizeEvent.current.contentRight,
                    bottom: ResizeEvent.current.contentBottom,
                    backgroundColor: 'rgba(0,0,0,0.2)',
                }}>
        <View style={{ height: contentHeight, width: ResizeEvent.current.contentWidth }}>{visibleTab}</View>
      </View>,
            this.renderTabBar(),
        ];
    }
}
HomeViewContent.defaultProps = {
    title: 'upmesh',
    showAllTickets: false,
};
