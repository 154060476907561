import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AddTicketWatcher } from 'upmesh-core/src/client/commands/tickets/AddTicketWatcher';
import { ArchiveTicket } from 'upmesh-core/src/client/commands/tickets/ArchiveTicket';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { ChangeTicketApprover } from 'upmesh-core/src/client/commands/tickets/ChangeTicketApprover';
import { ChangeTicketCustomField } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { DeleteTicket } from 'upmesh-core/src/client/commands/tickets/DeleteTicket';
import { RemoveTicketWatcher } from 'upmesh-core/src/client/commands/tickets/RemoveTicketWatcher';
import { RestoreTicket } from 'upmesh-core/src/client/commands/tickets/RestoreTicket';
import { UnArchiveTicket } from 'upmesh-core/src/client/commands/tickets/UnArchiveTicket';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { RemoveTicketTag } from 'upmesh-core/src/client/commands/tickets/RemoveTicketTag';
import { ChangeTicketType } from 'upmesh-core/src/client/commands/tickets/ChangeTicketType';
import { SetTicketShouldCompleteDate } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
import { BulkChangeDialogDeleteArchive } from './BulkChangeDialogDeleteArchive';
import { BulkChangeDialogList } from './BulkChangeDialogList';
import { BulkChangeDialogConfirmation } from './BulkChangeDialogConfirmation';
import { BulkChangeDialogDueDate } from './BulkChangeDialogDueDate';
import { BulkChangeDialogSingleFields } from './BulkChangeDialogSingleFields';
import { BulkChangeDialogUserList } from './BulkChangeDialogUserList';
const supportedTypeArray = [
    'assignee',
    'approver',
    'watcher',
    'archive',
    'delete',
    'string',
    'number',
    'Date',
    'DateRange',
    'List',
    'CheckboxList',
    'MultiselectList',
    'person',
    'multiperson',
    'progress',
];
export const isSupportedByBulkChange = (value) => {
    return supportedTypeArray.includes(value);
};
export class BulkChangeDialogContent extends PureComponent {
    constructor(props) {
        super(props);
        this.renderConfirmationQuestion = () => {
            const { selectedIDs } = this.props;
            return (<BulkChangeDialogConfirmation onConfirm={this.select} onGoBack={() => this.setState({ page: 'selection' })} selectedTickets={selectedIDs.size}/>);
        };
        this.abort = false;
        this.cancelMulti = () => {
            this.abort = true;
            this.closeDialog();
        };
        this.renderProgressBar = () => {
            const { selectedIDs } = this.props;
            const { ticketsToBeChanged } = this.state;
            const maxTickets = ticketsToBeChanged != null ? ticketsToBeChanged : selectedIDs.size;
            return (<>
        <DialogContent dialogHasTitle={false}>
          <MaterialText>
            {I18n.m.getMessage('bulkChangesChangingTickets', {
                    count: this.state.changeProgress,
                    sum: maxTickets,
                })}
          </MaterialText>
          <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
          <ProgressBar progressInPercent={(this.state.changeProgress / maxTickets) * 100}/>
        </DialogContent>
        <DialogActions>
          <ContainedButton title={I18n.m.getMessage('cancel')} onPress={() => this.cancelMulti()} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        </DialogActions>
      </>);
        };
        this.renderSingleFields = () => {
            const { changedTopic } = this.props;
            if (typeof changedTopic !== 'string')
                return (<BulkChangeDialogSingleFields changedTopic={changedTopic} onCancel={() => {
                        this.setState({ selected: undefined });
                        Dialog.instance?.close();
                    }} onSave={(selected) => this.setState({ page: 'confirmation', selected })}/>);
            return <View />;
        };
        this.archiveRestoreTickets = (topic) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const asyncNow = async () => {
                if (selectedTickets.length != null) {
                    for (let i = 0; i < selectedTickets.length; i += 1) {
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ page: 'progress', changeProgress: i + 1 });
                            const t = selectedTickets[i];
                            try {
                                if (t.archived === true && topic === 'unarchive') {
                                    const c = new UnArchiveTicket({}, t.id);
                                    await c.execute();
                                }
                                else if (t.archived === false && topic === 'archive') {
                                    const c = new ArchiveTicket({}, t.id);
                                    await c.execute();
                                }
                            }
                            catch (e) {
                                if (e['message'] !== 'Field not in Layout')
                                    errorCount += 1;
                            }
                        }
                    }
                }
                Dialog.instance?.close();
                if (errorCount > 0)
                    errorDialog(errorCount);
                onChange();
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.deleteRestoreTickets = (topic) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const asyncNow = async () => {
                if (selectedTickets.length != null) {
                    for (let i = 0; i < selectedTickets.length; i += 1) {
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ page: 'progress', changeProgress: i + 1 });
                            const t = selectedTickets[i];
                            try {
                                if (topic === 'restore' && t.deleted === true) {
                                    const c = new RestoreTicket({}, t.id);
                                    await c.execute();
                                }
                                else if (topic === 'delete' && t.deleted !== true) {
                                    const c = new DeleteTicket({}, t.id);
                                    await c.execute();
                                }
                            }
                            catch (e) {
                                if (e['message'] !== 'Field not in Layout')
                                    errorCount += 1;
                            }
                        }
                    }
                }
                Dialog.instance?.close();
                if (errorCount > 0)
                    errorDialog(errorCount);
                onChange();
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.closeDialog = () => {
            setTimeout(() => {
                Dialog.instance?.close();
            }, 1000);
        };
        this.getSelectedTickets = () => {
            const { tickets, selectedIDs } = this.props;
            const selectedTickets = [];
            tickets.forEach((t) => {
                if (selectedIDs.has(t.id))
                    selectedTickets.push(t);
            });
            return selectedTickets;
        };
        this.renderDeleteArchiveDialog = (topic) => {
            let onSave = () => { };
            if (topic === 'archive' || topic === 'unarchive')
                onSave = () => this.archiveRestoreTickets(topic);
            else if (topic === 'delete' || topic === 'restore')
                onSave = () => this.deleteRestoreTickets(topic);
            return <BulkChangeDialogDeleteArchive topic={topic} onCancel={() => Dialog.instance?.close()} onSave={onSave}/>;
        };
        this.renderDueDate = () => {
            return (<BulkChangeDialogDueDate onCancel={() => Dialog.instance?.close()} onSave={(selected) => this.setState({ page: 'confirmation', selected })}/>);
        };
        this.select = () => {
            const { changedTopic } = this.props;
            const { selectedMember, selected, selectedList } = this.state;
            this.setState({ page: 'progress', changeProgress: 0 });
            if (selectedMember != null) {
                if (changedTopic === 'assignee') {
                    this.setNewAssignee(selectedMember).catch((err) => console.debug(err));
                }
                else if (changedTopic === 'approver') {
                    this.setNewApprover(selectedMember).catch((err) => console.debug(err));
                }
                else if (changedTopic === 'watcher' || changedTopic === 'removeWatcher') {
                    this.addRemoveWatcher(selectedMember).catch((err) => console.debug(err));
                }
                else if (typeof changedTopic !== 'string') {
                    this.setCustomField(selectedMember, changedTopic).catch((err) => console.debug(err));
                }
            }
            else if (selectedList != null) {
                if (changedTopic === 'tags' || changedTopic === 'removeTags') {
                    this.addRemoveTags(selectedList).catch((err) => console.debug(err));
                }
                else if (typeof changedTopic !== 'string')
                    this.setCustomList(selectedList, changedTopic).catch((err) => console.debug(err));
            }
            else if (selected != null) {
                if (changedTopic === 'dueDate') {
                    this.changeDueDate(selected).catch((err) => console.debug(err));
                }
                else if (changedTopic === 'type') {
                    this.changeType(selected).catch((err) => console.debug(err));
                }
                else if (typeof changedTopic !== 'string')
                    this.setCustomField(selected, changedTopic).catch((err) => console.debug(err));
            }
        };
        this.setCustomList = async (selectedList, layout) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            if (selectedTickets != null && selectedList != null) {
                const types = layout.onTypes;
                let filteredTickets = [];
                if (types != null && types.length > 0)
                    filteredTickets = selectedTickets.filter((t) => t.type != null && types.indexOf(t.type) > -1);
                if (filteredTickets != null && filteredTickets.length > 0) {
                    this.setState({ ticketsToBeChanged: filteredTickets.length });
                    const newList = [];
                    if (selectedList.length > 0) {
                        const idNotTitle = layout.customField != null && layout.customField.type === 'multiperson';
                        for (const s of selectedList) {
                            const item = idNotTitle ? s.id : s.title;
                            if (item != null && s.selected === true)
                                newList.push(item);
                        }
                        for (let i = 0; i < filteredTickets.length; i += 1) {
                            const ticket = filteredTickets[i];
                            if (this.abort) {
                                Dialog.instance?.close();
                            }
                            else {
                                this.setState({ changeProgress: i + 1 });
                                try {
                                    const c = new ChangeTicketCustomField({ fieldId: layout.id, value: newList }, ticket.id);
                                    await c.execute();
                                }
                                catch (e) {
                                    if (e['message'] !== 'Field not in Layout')
                                        errorCount += 1;
                                }
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
            this.setState({ ticketsToBeChanged: undefined });
        };
        this.setCustomField = async (selected, layout) => {
            const { onChange, errorDialog } = this.props;
            const selectedTickets = this.getSelectedTickets();
            let errorCount = 0;
            const types = layout.onTypes;
            let filteredTickets = [];
            if (types != null)
                filteredTickets = selectedTickets.filter((t) => t.type != null && types.indexOf(t.type) > -1);
            if (filteredTickets.length > 0) {
                this.setState({ ticketsToBeChanged: filteredTickets.length });
                if (types != null && types.length > 0) {
                    for (let i = 0; i < filteredTickets.length; i += 1) {
                        const ticket = filteredTickets[i];
                        if (this.abort) {
                            Dialog.instance?.close();
                        }
                        else {
                            this.setState({ changeProgress: i + 1 });
                            try {
                                const c = new ChangeTicketCustomField({ fieldId: layout.id, value: selected }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                if (e['message'] !== 'Field not in Layout')
                                    errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
            this.setState({ ticketsToBeChanged: undefined });
        };
        this.setNewApprover = async (userID) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        if (!(userID === '0' && ticket.approverUserId == null) && ticket.approverUserId !== userID) {
                            try {
                                const c = new ChangeTicketApprover({ approverUserId: userID }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                if (e['message'] !== 'Field not in Layout')
                                    errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.setNewAssignee = async (userID) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        if (!(userID === '0' && ticket.assignedToUserId == null) && ticket.assignedToUserId !== userID) {
                            try {
                                const c = new AssignTicketTo({ assignedToUserId: userID }, ticket.id);
                                await c.execute();
                            }
                            catch (e) {
                                if (e['message'] !== 'Field not in Layout')
                                    errorCount += 1;
                            }
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.changeDueDate = async (selected) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            const completionOn = new Date(selected);
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        try {
                            const c = new SetTicketShouldCompleteDate({ completionOn }, ticket.id);
                            await c.execute();
                        }
                        catch (e) {
                            errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.changeType = async (selected) => {
            const { onChange, errorDialog } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            if (selectedTickets != null) {
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        try {
                            const c = new ChangeTicketType({ ticketType: selected == null || selected === '0' ? null : selected }, ticket.id);
                            await c.execute();
                        }
                        catch (e) {
                            if (e['message'] !== 'Field not in Layout')
                                errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.addRemoveWatcher = async (userID) => {
            const { onChange, errorDialog, changedTopic } = this.props;
            let errorCount = 0;
            const selectedTickets = this.getSelectedTickets();
            for (let i = 0; i < selectedTickets.length; i += 1) {
                if (this.abort) {
                    Dialog.instance?.close();
                }
                else {
                    this.setState({ changeProgress: i + 1 });
                    const ticket = selectedTickets[i];
                    if (ticket.watchers == null)
                        ticket.watchers = [];
                    const containsWatcher = ticket.watchers.findIndex((j) => j.userId === userID) > -1;
                    try {
                        if (changedTopic === 'watcher' && !containsWatcher) {
                            const c = new AddTicketWatcher({ userId: userID }, ticket.id);
                            await c.execute();
                        }
                        else if (changedTopic === 'removeWatcher' && containsWatcher) {
                            const c = new RemoveTicketWatcher({ userId: userID }, ticket.id);
                            await c.execute();
                        }
                    }
                    catch (err) {
                        if (err['message'] !== 'Field not in Layout')
                            errorCount += 1;
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        this.addRemoveTags = async (selectedList) => {
            let errorCount = 0;
            const { onChange, errorDialog, changedTopic } = this.props;
            if (selectedList) {
                const selectedTickets = this.getSelectedTickets();
                for (let i = 0; i < selectedTickets.length; i += 1) {
                    if (this.abort) {
                        Dialog.instance?.close();
                    }
                    else {
                        this.setState({ changeProgress: i + 1 });
                        const ticket = selectedTickets[i];
                        try {
                            if (changedTopic === 'tags') {
                                for (const tag of selectedList) {
                                    if (tag.selected === true) {
                                        const c = new AddTicketTag({
                                            tag: {
                                                tagName: tag.title,
                                                groupName: tag.groupId,
                                                color: tag.color ? tag.color : ThemeManager.style.chipDefaultBg,
                                            },
                                        }, ticket.id);
                                        await c.execute();
                                    }
                                }
                            }
                            else if (changedTopic === 'removeTags') {
                                for (const tag of selectedList) {
                                    if (tag.selected === true && ticket.tags.findIndex((j) => j.tagName === tag.title) > -1) {
                                        const c = new RemoveTicketTag({
                                            tag: {
                                                tagName: tag.title,
                                                groupName: tag.groupId,
                                                color: tag.color ? tag.color : ThemeManager.style.chipDefaultBg,
                                            },
                                        }, ticket.id);
                                        await c.execute();
                                    }
                                }
                            }
                        }
                        catch (err) {
                            if (err['message'] !== 'Field not in Layout')
                                errorCount += 1;
                        }
                    }
                }
            }
            this.closeDialog();
            if (errorCount > 0)
                errorDialog(errorCount);
            onChange();
        };
        const team = CurrentProject.instance.getCurrentProjectTeam();
        const memberList = [];
        team.forEach((member) => {
            memberList.push(member);
        });
        memberList.sort((a, b) => (a.user.getFullName() < b.user.getFullName() ? -1 : 1));
        this.state = {
            memberList,
            selectedMember: undefined,
            changeProgress: 0,
            page: 'selection',
        };
    }
    render() {
        const { changedTopic } = this.props;
        const { page } = this.state;
        if (page === 'selection') {
            if (changedTopic === 'assignee' ||
                changedTopic === 'approver' ||
                changedTopic === 'watcher' ||
                changedTopic === 'removeWatcher') {
                return this.renderUserList(changedTopic);
            }
            if (changedTopic === 'tags' || changedTopic === 'removeTags' || changedTopic === 'type') {
                return this.renderList(changedTopic);
            }
            if (changedTopic === 'archive' ||
                changedTopic === 'unarchive' ||
                changedTopic === 'delete' ||
                changedTopic === 'restore')
                return this.renderDeleteArchiveDialog(changedTopic);
            if (changedTopic === 'dueDate')
                return this.renderDueDate();
            if (typeof changedTopic !== 'string' && changedTopic.customField !== undefined) {
                switch (changedTopic.customField.type) {
                    case 'person':
                        return this.renderUserList(changedTopic);
                    case 'string':
                        return this.renderSingleFields();
                    case 'number':
                        return this.renderSingleFields();
                    case 'Date':
                        return this.renderSingleFields();
                    case 'DateRange':
                        return this.renderSingleFields();
                    case 'progress':
                        return this.renderSingleFields();
                    case 'multiperson':
                        return this.renderUserList(changedTopic);
                    case 'CheckboxList':
                        return this.renderList(changedTopic);
                    case 'MultiselectList':
                        return this.renderList(changedTopic);
                    case 'List':
                        return this.renderList(changedTopic);
                    default:
                        return <View />;
                }
            }
        }
        else if (page === 'confirmation')
            return this.renderConfirmationQuestion();
        else if (page === 'progress')
            return this.renderProgressBar();
        return <View />;
    }
    componentDidMount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = () => I18n.m.getMessage('webBeforeUnloadHint');
        }
    }
    componentWillUnmount() {
        if (Platform.OS === 'web') {
            window.onbeforeunload = null;
        }
    }
    renderList(changedTopic) {
        return (<BulkChangeDialogList onSave={(items) => {
                this.setState({ page: 'confirmation', selectedList: items });
            }} onCancel={() => {
                this.setState({ selectedList: undefined });
                Dialog.instance?.close();
            }} changedTopic={changedTopic} onSelectSingleOption={(selected) => {
                this.setState({ selected, page: 'confirmation' });
            }}/>);
    }
    renderUserList(changedTopic) {
        return (<BulkChangeDialogUserList changedTopic={changedTopic} onSave={(items) => {
                this.setState({ page: 'confirmation', selectedList: items });
            }} onCancel={() => {
                this.setState({ selectedList: undefined });
                Dialog.instance?.close();
            }} onSelectSingleUser={(id) => this.setState({ page: 'confirmation', selectedMember: id })}/>);
    }
}
