"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AbsenceEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var TimeTrackingEntity_1 = require("./TimeTrackingEntity");
var WorkingTimeModelEntity_1 = require("./WorkingTimeModelEntity");
var AbsenceEntity = function (_ReadModelEntity_1$Re) {
  function AbsenceEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, AbsenceEntity);
    _this = _callSuper(this, AbsenceEntity, [obj]);
    _this.entityName = 'Absence';
    _this.deleted = false;
    _this.state = 'open';
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(AbsenceEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(AbsenceEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new AbsenceEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('memberId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('userId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('starts', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ends', new OdataEntity_1.OdataIndex(true));
      baseMap.set('note', new OdataEntity_1.OdataIndex());
      baseMap.set('taskId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('costCenterId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('state', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approvedBy', new OdataEntity_1.OdataIndex(true));
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }, {
    key: "getDayDiff",
    value: function getDayDiff() {
      this.starts = new Date(this.starts);
      this.ends = new Date(this.ends);
      var _MS_PER_DAY = 1000 * 60 * 60 * 24;
      var utc1 = Date.UTC(this.starts.getFullYear(), this.starts.getMonth(), this.starts.getDate());
      var utc2 = Date.UTC(this.ends.getFullYear(), this.ends.getMonth(), this.ends.getDate() + 1);
      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }
  }, {
    key: "convertToTimeTracking",
    value: function () {
      var _convertToTimeTracking = (0, _asyncToGenerator2.default)(function* (_w) {
        var countDays = this.getDayDiff();
        var tts = [];
        for (var i = 0; i < countDays; i += 1) {
          var starts = new Date(this.starts.getFullYear(), this.starts.getMonth(), this.starts.getDate() + i, 0, 0, 0, 0);
          var ends = new Date(starts.getFullYear(), starts.getMonth(), starts.getDate());
          var wt = yield _w(starts, this.memberId);
          if (i === 0) {
            starts.setHours(this.starts.getHours());
            starts.setMinutes(this.starts.getMinutes());
          }
          if (i === countDays - 1) {
            ends.setHours(this.ends.getHours());
            ends.setMinutes(this.ends.getMinutes());
          }
          if (wt != null && wt.targetStart != null && wt.targetEnd != null) {
            var wholeDay = (wt.targetStart.getHours() > starts.getHours() || wt.targetStart.getHours() === starts.getHours() && wt.targetStart.getMinutes() >= starts.getMinutes()) && (ends.getHours() === 0 || wt.targetEnd.getHours() < ends.getHours() || wt.targetEnd.getHours() === ends.getHours() && wt.targetEnd.getMinutes() <= ends.getMinutes());
            if (wt.targetStart.getHours() > starts.getHours()) {
              starts.setHours(wt.targetStart.getHours());
              starts.setMinutes(wt.targetStart.getMinutes());
            }
            if (ends.getHours() === 0 || wt.targetEnd.getHours() < ends.getHours()) {
              ends.setHours(wt.targetEnd.getHours());
              ends.setMinutes(wt.targetEnd.getMinutes());
            }
            var diff = ends.getTime() - starts.getTime();
            if (wt.holidayTimeInMS < diff) {
              var absendeOvertimeReduction = false;
              try {
                var rTasks = ReadModels_1.ReadModels.get('task');
                var reason = yield rTasks.getById(this.taskId);
                absendeOvertimeReduction = reason.taskTypeId === 'overtimeReduction';
              } catch (e) {}
              if (wt.holidayTimeInMS !== 0) {
                starts.setMilliseconds(starts.getMilliseconds() + wt.holidayTimeInMS);
              }
              tts.push(new TimeTrackingEntity_1.TimeTrackingEntity({
                starts: starts,
                ends: ends,
                pause: wholeDay && wt.targetPause ? WorkingTimeModelEntity_1.Daytime.fromDate(wt.targetPause) : undefined,
                absenceId: this.id,
                absendeOvertimeReduction: absendeOvertimeReduction,
                memberId: this.memberId,
                userId: this.userId,
                companyId: this.companyId,
                createdBy: this.createdBy,
                taskId: this.taskId,
                costCenterId: this.costCenterId,
                projectId: this.projectId,
                note: this.note
              }));
            }
          }
        }
        return tts;
      });
      function convertToTimeTracking(_x) {
        return _convertToTimeTracking.apply(this, arguments);
      }
      return convertToTimeTracking;
    }()
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.AbsenceEntity = AbsenceEntity;