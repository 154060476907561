import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ChangeView } from 'upmesh-core/src/client/commands/project/views/ChangeView';
import { CreateView } from 'upmesh-core/src/client/commands/project/views/CreateView';
import { PublishView } from 'upmesh-core/src/client/commands/project/views/PublishView';
import { RemoveView } from 'upmesh-core/src/client/commands/project/views/RemoveView';
import { ViewEntity } from 'upmesh-core/src/client/query/entities/ViewEntity';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export const getMainView = () => {
    const isOnS = ResizeEvent.current.windowWidth < ThemeManager.style.breakpointM;
    let type = SimpleStorage.get('ticketMainView');
    if (type == null)
        type = isOnS ? 'list' : 'table';
    return new ViewEntity({
        id: 'main',
        createdBy: CurrentUser.userId,
        name: I18n.m.getMessage('ticketsViewMainView'),
        isPublic: false,
        projectId: 'main',
        additionalData: `{"type": "${type}"}`,
        default: false,
        on: 'tickets',
    });
};
export const deleteView = (id) => {
    const c = new RemoveView({}, id);
    c.execute().catch(console.error);
};
export const duplicateView = (view) => {
    const c = new CreateView({
        name: view.name,
        on: view.on,
        projectId: view.projectId,
        additionalData: view.additionalData,
    });
    c.execute().catch(console.error);
};
export const renameView = (id, title) => {
    const c = new ChangeView({ name: title }, id);
    c.execute().catch(console.error);
};
export const setAsDefault = async (id) => {
    const { userId } = CurrentUser;
    const projectId = CurrentProject.instance.getCurrentProjectId();
    const currentDefault = await UpmeshClient.instance.modals.view.get({
        filter: `createdBy eq '${userId}' and projectId eq '${projectId}' and default eq true`,
    });
    for (const item of currentDefault) {
        const command = new ChangeView({ default: false }, item.id);
        await command.execute();
    }
    const c = new ChangeView({ default: true }, id);
    c.execute().catch(console.error);
};
export const removeDefault = (id) => {
    const c = new ChangeView({ default: false }, id);
    c.execute().catch(console.error);
};
export const saveAdditionalData = (id, additionalData) => {
    if (id === 'main' && additionalData.type) {
        SimpleStorage.set('ticketMainView', additionalData.type);
    }
    else {
        const dataString = JSON.stringify(additionalData);
        const c = new ChangeView({ additionalData: dataString }, id);
        c.execute().catch(console.error);
    }
};
export const publishView = (id, isPublic) => Routing.instance.alert.post({
    title: isPublic ? I18n.m.getMessage('ticketsViewPublishView') : I18n.m.getMessage('ticketsViewRevokePublish'),
    text: isPublic
        ? I18n.m.getMessage('ticketsViewPublishHelper')
        : I18n.m.getMessage('ticketsViewRevokePublishHelper'),
    buttons: [
        <ContainedButton key="no" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent"/>,
        <ContainedButton key="yes" title={isPublic ? I18n.m.getMessage('ticketsViewPublish') : I18n.m.getMessage('ticketsViewRevokePublish')} onPress={() => {
                Alert.instance?.close();
                const c = new PublishView({ isPublic }, id);
                c.execute().catch(console.error);
            }}/>,
    ],
});
export const onPressViewOptions = (view, enableRenaming, additionalData, viewDataHasChanged) => (e) => {
    const { id } = view;
    const asyncNow = async (e) => {
        const measurement = await Measurement.measure(e.currentTarget);
        const client = {
            height: measurement.height,
            width: measurement.width,
            x: measurement.pageX,
            y: measurement.pageY,
        };
        const items = [
            {
                onPress: () => {
                    Menu.instance?.close();
                    enableRenaming();
                },
                thumbnail: {
                    thumbnail: <Icon icon="pencil-outline" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewRename'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    if (view.default)
                        removeDefault(view.id);
                    else
                        setAsDefault(view.id).catch(console.error);
                },
                thumbnail: {
                    thumbnail: <Icon icon={view.default ? 'pin-off-outline' : 'pin-outline'} toolTip=""/>,
                    width: 40,
                },
                text: view.default
                    ? I18n.m.getMessage('ticketsViewSetDefaultOff')
                    : I18n.m.getMessage('ticketsViewSetDefault'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    duplicateView(view);
                },
                thumbnail: {
                    thumbnail: <Icon icon="content-duplicate" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewDuplicate'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    deleteView(id);
                },
                thumbnail: {
                    thumbnail: <Icon icon="delete-outline" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewDelete'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    saveAdditionalData(id, additionalData);
                },
                thumbnail: {
                    thumbnail: (<Icon icon="autorenew" toolTip="" color={viewDataHasChanged ? ThemeManager.style.defaultIconColor : ThemeManager.style.disabledColor}/>),
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewOverride'),
                disabled: !viewDataHasChanged,
            },
        ];
        const { userId } = CurrentUser;
        const canPublish = (await RightsManager.hasWriteRight(view.projectId, userId, 'commandChangeProjectTemplateStatus')) &&
            view.createdBy === userId;
        const isOwnPublicView = view.isPublic;
        if (canPublish || isOwnPublicView) {
            items.push({
                onPress: () => {
                    Menu.instance?.close();
                    publishView(view.id, !view.isPublic);
                },
                thumbnail: {
                    thumbnail: <Icon icon={view.isPublic ? 'web-off' : 'web'} toolTip=""/>,
                    width: 40,
                },
                text: view.isPublic ? I18n.m.getMessage('ticketsViewRevokePublish') : I18n.m.getMessage('ticketsViewPublish'),
            });
        }
        Menu.instance?.open({
            client,
            items,
            disabledTextColor: ThemeManager.style.disabledColor,
        });
    };
    asyncNow(e).catch(console.error);
};
export const sortTicketViews = (views) => {
    const { userId } = CurrentUser;
    const sorted = [...views].filter((v) => v.id !== 'main');
    sorted.sort((a, b) => {
        const aIsOtherPublic = a.isPublic && a.createdBy !== userId;
        const bIsOtherPublic = b.isPublic && b.createdBy !== userId;
        if (aIsOtherPublic && !bIsOtherPublic) {
            return 1;
        }
        if (!aIsOtherPublic && bIsOtherPublic) {
            return -1;
        }
        if (a.default && !b.default) {
            return -1;
        }
        if (!a.default && b.default) {
            return 1;
        }
        return 0;
    });
    const result = [];
    const hasDefault = sorted && sorted.length > 0 && sorted[0].default;
    if (hasDefault)
        result.push(sorted[0]);
    result.push(getMainView());
    if (hasDefault)
        result.push(...sorted.slice(1));
    else
        result.push(...sorted);
    return result;
};
export const createNewView = (type) => {
    Menu.instance?.close();
    let name = I18n.m.getMessage('ticketsViewCanban');
    if (type === 'list')
        name = I18n.m.getMessage('ticketsViewList');
    if (type === 'table')
        name = I18n.m.getMessage('ticketsViewTable');
    if (type === 'calendar')
        name = I18n.m.getMessage('ticketsViewCalendar');
    const c = new CreateView({
        name,
        on: 'tickets',
        projectId: CurrentProject.instance.getCurrentProjectId() ?? '',
        additionalData: `{"type": "${type}"}`,
    });
    c.execute().catch(console.error);
};
export const onPressNewView = (e) => {
    const client = {
        height: 0,
        width: 0,
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
    };
    const items = [
        {
            onPress: () => createNewView('canban'),
            thumbnail: {
                thumbnail: <Icon icon="poll" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewCanban'),
        },
        {
            onPress: () => createNewView('list'),
            thumbnail: {
                thumbnail: <Icon icon="format-list-bulleted-square" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewList'),
        },
        {
            onPress: () => createNewView('table'),
            thumbnail: {
                thumbnail: <Icon icon="table-large" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewTable'),
        },
        {
            onPress: () => createNewView('calendar'),
            thumbnail: {
                thumbnail: <Icon icon="calendar" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewCalendar'),
        },
    ];
    Menu.instance?.open({
        client,
        items,
    });
};
export const getIconNameFromViewType = (type) => {
    if (type === 'canban')
        return 'poll';
    if (type === 'calendar')
        return 'calendar';
    if (type === 'table')
        return 'table-large';
    return 'format-list-bulleted-square';
};
export const parseAdditinalViewData = (additionalData) => {
    let filter;
    let order = '';
    let sortDirection = '0';
    let parsed;
    let type = 'list';
    try {
        parsed = JSON.parse(additionalData);
        filter = parsed.f ? parsed.f : new TicketFilter();
        order = parsed.o ?? '';
        sortDirection = parsed.d ?? '';
        type = parsed.type ?? 'list';
    }
    catch (e) {
        filter = new TicketFilter();
        parsed = {};
    }
    return { parsed, filter, order, sortDirection, type };
};
