import React from 'react';
import { Datepicker } from 'materialTheme/src/theme/components/datepickerv2/Datepicker';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
export function BulkChangeDialogDueDate(props) {
    const onSelected = (date) => {
        if (date != null)
            props.onSave(date);
        else
            props.onCancel();
    };
    const renderSingleFields = () => {
        return <Datepicker mode="both" getDate={onSelected} range={false} timeFormat={CurrentUser.settings.timeFormat}/>;
    };
    return renderSingleFields();
}
