import { BottomSheet } from 'materialTheme/src/theme/components/BottomSheet';
import { OutlineButton } from 'materialTheme/src/theme/components/button/OutlineButton';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
import TicketViewSelectorItem from './TicketViewSelectorItem';
import { onPressNewView, sortTicketViews } from './ticketViewUtil';
export default function TicketViewSelector(props) {
    const [views, setViews] = useState(props.views);
    const [viewDataHasChanged, setViewDataHasChanged] = useState(props.viewDataHasChanged);
    const [selectedTab, setSelectedTab] = useState(props.selectedTab);
    const scrollRef = useRef(null);
    const prevViews = useRef(props.views);
    useEffect(() => {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const updateViews = (e) => {
            let nextSelected = selectedTab;
            let viewDataChangedAfterUpdate = viewDataHasChanged;
            let newViews = [...views];
            e.entities.forEach((val, key) => {
                if (val.entity == null || val.entity.projectId !== projectId)
                    return;
                const index = newViews.findIndex((e) => e.id === key);
                if (index === -1 && !val.entity.deleted) {
                    BottomSheet.instance?.close();
                    return;
                }
                if (val.entity.deleted && index >= 0) {
                    newViews.splice(index, 1);
                    if (index === selectedTab)
                        nextSelected = Math.min(selectedTab, newViews.length - 1);
                    else if (index < selectedTab)
                        nextSelected = selectedTab - 1;
                }
                else {
                    const defaultChanged = val.entity.default !== newViews[index].default;
                    newViews[index] = val.entity;
                    if (defaultChanged) {
                        newViews = sortTicketViews(newViews);
                        nextSelected = newViews.findIndex((el) => val.entity?.id === el.id);
                    }
                    if (selectedTab === index && newViews[selectedTab].additionalData !== views[selectedTab].additionalData) {
                        viewDataChangedAfterUpdate = false;
                    }
                }
            });
            setViewDataHasChanged(viewDataChangedAfterUpdate);
            setViews(newViews);
            setSelectedTab(nextSelected);
        };
        UpmeshClient.eventDispatcher.attach({
            attachKey: `ticketViewsSelector${projectId}`,
            readModelName: 'View',
            callback: updateViews,
        });
        return () => UpmeshClient.eventDispatcher.detach('View', `ticketViewsSelector${projectId}`);
    }, []);
    useEffect(() => {
        try {
            const newElement = views.length === prevViews.current.length + 1;
            if (!newElement)
                return;
            const newViewIndex = views.findIndex((v, i) => prevViews.current[i]?.id !== v.id);
            if (newViewIndex > -1)
                scrollRef.current?.scrollToIndex({ index: newViewIndex });
            prevViews.current = views;
        }
        catch (e) {
            console.error(e);
        }
    }, [views]);
    const changeTab = (index) => {
        if (index === selectedTab)
            return;
        BottomSheet.instance?.close(() => {
            setViewDataHasChanged(false);
            setSelectedTab(index);
            const { selectTab } = props;
            selectTab(index);
        });
    };
    const { additionalViewData } = props;
    return (<View style={{ height: (ResizeEvent.current.contentHeight / 3) * 2, width: '100%' }}>
      <FlatList ref={scrollRef} ListHeaderComponent={<View style={{ padding: ThemeManager.style.contentPaddingValue, paddingBottom: 4 }}>
            <MaterialText>{I18n.m.getMessage('ticketsViewViews')}</MaterialText>
          </View>} data={views} keyExtractor={(view) => `ViewSelector_${view.id}_${view.name}`} renderItem={({ item, index }) => (<TicketViewSelectorItem view={item} onSelect={() => changeTab(index)} index={index} additionalData={additionalViewData} viewDataHasChanged={viewDataHasChanged} selected={selectedTab === index}/>)}/>
      <View style={{
            width: '100%',
            paddingHorizontal: ThemeManager.style.contentPaddingValue,
            paddingTop: 4,
            paddingBottom: 32,
        }}>
        <OutlineButton title={I18n.m.getMessage('ticketsCreateNewView')} backgroundColor={ThemeManager.style.cardBackgroundColor} icon={{ icon: 'plus' }} full onPress={onPressNewView}/>
      </View>
    </View>);
}
