import ConnectionContext from 'materialTheme/src/connectionContext';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import * as mime from 'mime';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Platform, UIManager, View, Dimensions } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ArchiveProject } from 'upmesh-core/src/client/commands/project/ArchiveProject';
import { ChangeProjectSettings } from 'upmesh-core/src/client/commands/project/ChangeProjectSettings';
import { ChangeProjectQrCode } from 'upmesh-core/src/client/commands/project/ChangeProjectQrCode';
import { ChangeProjectTemplateStatus } from 'upmesh-core/src/client/commands/project/ChangeProjectTemplateStatus';
import { RemoveProjectImage } from 'upmesh-core/src/client/commands/project/RemoveProjectImage';
import { ProjectEntity } from 'upmesh-core/src/client/query/entities/ProjectEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../../i18n/I18n';
import { OfflineDataDownloader } from '../../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { StoredFileDownloader } from '../../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../../file/StoredFileDownloaderOptions';
import { CompanyUserInfo } from '../../root/CompanyUserInfo';
import { OfflineProjectSettingsPU } from '../../root/OfflineProjectSettingsPU';
import { CurrentProject } from '../CurrentProject';
import { ProjectImage } from '../ProjectImage';
import { ProjectTemplateStatus } from '../ProjectTemplateStatus';
import { UpgradeButton } from '../UpgradeButton';
import { CopyProjectDialog } from './CopyProjectDialog';
import { DeleteProjectDialog } from './DeleteProjectDialog';
const bytes = require('bytes');
const proFunctionImage = require('../../../assets/img/proFunctionDownloadZIP.png');
const howToDownloadAProjectImage = require('../../../assets/img/howToDownloadAProject.png');
const bottomLeftQrImage = require('../../../assets/img/bottomLeft.png');
const bottomRightQrImage = require('../../../assets/img/bottomRight.png');
const topLeftQrImage = require('../../../assets/img/topLeft.png');
const topRightQrImage = require('../../../assets/img/topRight.png');
export class ProjectProfileView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.qrCodePositions = [
            { index: 0, backendValue: 'Top left', title: I18n.m.getMessage('qrCodePositionTopLeft') },
            { index: 1, backendValue: 'Top right', title: I18n.m.getMessage('qrCodePositionTopRight') },
            { index: 2, backendValue: 'Bottom left', title: I18n.m.getMessage('qrCodePositionBottomLeft') },
            { index: 3, backendValue: 'Bottom right', title: I18n.m.getMessage('qrCodePositionBottomRight') },
        ];
        this.init = async () => {
            const userSubscription = await RightsManager.hasVersion(CurrentUser.userId);
            this.setState({ userSubscription });
            if (this.props.project != null && this.props.project.planQrCodePosition != null) {
                const qRCodePositionIndex = this.qrCodePositions.find((pos) => pos.backendValue === this.props.project?.planQrCodePosition)?.index;
                if (qRCodePositionIndex != null) {
                    this.setState({ planQrCodePosition: qRCodePositionIndex });
                    if (qRCodePositionIndex === 0 || qRCodePositionIndex === 2) {
                        this.setState({ horizontalOffsetTitle: I18n.m.getMessage('left') });
                    }
                    else {
                        this.setState({ horizontalOffsetTitle: I18n.m.getMessage('right') });
                    }
                    if (qRCodePositionIndex === 0 || qRCodePositionIndex === 1) {
                        this.setState({ verticalOffsetTitle: I18n.m.getMessage('top') });
                    }
                    else {
                        this.setState({ verticalOffsetTitle: I18n.m.getMessage('bottom') });
                    }
                }
            }
        };
        this.imageMap = {
            'Top left': topLeftQrImage,
            'Top right': topRightQrImage,
            'Bottom left': bottomLeftQrImage,
            'Bottom right': bottomRightQrImage,
        };
        this.onResize = () => {
            this.setState({ height: this.getHeight() });
        };
        this.updateOfflineProject = (e) => {
            if (e.settings.projectId === this.props.project?.id)
                this.setState({ offlineProject: { ...e } });
        };
        this.changeQrCodePosition = (planQrCodePosition) => {
            this.setState({ planQrCodePosition });
            if (planQrCodePosition === 0 || planQrCodePosition === 1) {
                this.setState({ verticalOffsetTitle: I18n.m.getMessage('top') });
            }
            else {
                this.setState({ verticalOffsetTitle: I18n.m.getMessage('bottom') });
            }
            if (planQrCodePosition === 0 || planQrCodePosition === 2) {
                this.setState({ horizontalOffsetTitle: I18n.m.getMessage('left') });
            }
            else {
                this.setState({ horizontalOffsetTitle: I18n.m.getMessage('right') });
            }
            if (this.props.project != null) {
                const mappedPosition = this.qrCodePositions.find((position) => position.index === planQrCodePosition)
                    ?.backendValue;
                if (mappedPosition) {
                    this.setState({
                        qrCodeImagePositioning: this.imageMap[mappedPosition],
                    });
                    const change = new ChangeProjectQrCode({
                        planQrCodePosition: mappedPosition,
                        planQrCodeVerticalOffset: this.state.planQrCodeVerticalOffset,
                        planQrCodeHorizontalOffset: this.state.planQrCodeHorizontalOffset,
                        showPlanQrCode: this.state.showPlanQrCode,
                        planQrCodeLabel: this.state.planQrCodeLabel,
                    }, this.props.project.id);
                    change.execute().catch((err) => {
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    });
                }
            }
        };
        this.qrCodePositionsOptions = () => {
            return this.qrCodePositions.map((position) => ({ title: position.title, id: position.title }));
        };
        this.changeQrCodeVisibility = (showPlanQrCode) => {
            const mappedPosition = this.qrCodePositions.find((position) => position.index === this.state.planQrCodePosition)
                ?.backendValue;
            this.setState({ showPlanQrCode });
            if (this.props.project != null) {
                const change = new ChangeProjectQrCode({
                    showPlanQrCode,
                    planQrCodePosition: mappedPosition,
                    planQrCodeVerticalOffset: this.state.planQrCodeVerticalOffset,
                    planQrCodeHorizontalOffset: this.state.planQrCodeHorizontalOffset,
                    planQrCodeLabel: this.state.planQrCodeLabel,
                }, this.props.project.id);
                change.execute().catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.changeVerticalAndHorizontalOffsets = (e, isVerticalOffset) => {
            const mappedPosition = this.qrCodePositions.find((position) => position.index === this.state.planQrCodePosition)
                ?.backendValue;
            if (isVerticalOffset) {
                this.setState({ planQrCodeVerticalOffset: e });
            }
            else {
                this.setState({ planQrCodeHorizontalOffset: e });
            }
            if (this.props.project != null) {
                const change = new ChangeProjectQrCode({
                    planQrCodePosition: mappedPosition,
                    planQrCodeVerticalOffset: isVerticalOffset ? e : this.state.planQrCodeVerticalOffset,
                    planQrCodeHorizontalOffset: isVerticalOffset ? this.state.planQrCodeHorizontalOffset : e,
                    showPlanQrCode: this.state.showPlanQrCode,
                    planQrCodeLabel: this.state.planQrCodeLabel,
                }, this.props.project.id);
                change.execute().catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.changeQrCodeLabel = (e) => {
            const mappedPosition = this.qrCodePositions.find((position) => position.index === this.state.planQrCodePosition)
                ?.backendValue;
            this.setState({ planQrCodeLabel: e });
            if (this.props.project != null) {
                const change = new ChangeProjectQrCode({
                    planQrCodePosition: mappedPosition,
                    planQrCodeVerticalOffset: this.state.planQrCodeVerticalOffset,
                    planQrCodeHorizontalOffset: this.state.planQrCodeHorizontalOffset,
                    showPlanQrCode: this.state.showPlanQrCode,
                    planQrCodeLabel: e,
                }, this.props.project.id);
                change.execute().catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.onChangeOption = (value) => {
            const { project } = this.props;
            if (project != null) {
                const change = new ChangeProjectSettings({ filesEditableTickets: value }, project.id);
                this.setState({
                    selectedFilesEditable: value === 'never' ? 0 : value === '24h' ? 1 : 2,
                });
                change.execute().catch((err) => {
                    this.setState({
                        selectedFilesEditable: project.filesEditableTickets === 'never' ? 0 : project.filesEditableTickets === '24h' ? 1 : 2,
                    });
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
                this.setState({});
            }
        };
        this.openHowToDownloadDialog = () => {
            Dialog.instance?.open({
                contentPadding: false,
                content: (<ProOnlyDialogContent headline={I18n.m.getMessage('projectDownloadHowToHeadline')} description={I18n.m.getMessage('projectDownloadHowToDescription')} buttonText={I18n.m.getMessage('projectDownloadProFunctionButton')} buttonFunction={Dialog.instance?.close} imageSource={howToDownloadAProjectImage} imagePosition="below"/>),
            });
        };
        this.openDownloadDialog = (project) => OfflineProjectSettingsPU.open(project.id);
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.changeProjectDetails = (e) => {
            Menu.instance?.close();
            Routing.instance.openDialog('editProject', { projectId: CurrentProject.instance.getCurrentProjectId() })(e);
        };
        this.onGotUserFile = async (response) => {
            const f = Array.isArray(response) ? response[0] : response;
            const { project } = this.props;
            if (project != null) {
                const ext = mime.getExtension(f.mime);
                if (ext == null) {
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('fileError'),
                    });
                    return;
                }
                const fileId = `${uuid.v1()}.${ext}`;
                try {
                    await Uploads.addUpload(`${UpmeshClient.instance.url}/project/${project.id}/image/${fileId}`, f.webFile != null ? f.webFile : f, 'projectImage', {}, 5, 1920);
                }
                catch (e) {
                    ErrorReporter.sendReport({ subject: 'cant add Project Image', data: e, type: 'warn' });
                    Routing.instance.alert.post({
                        text: `${I18n.m.getMessage('imageUploadError')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                    });
                }
            }
        };
        this.onMenuPressChangeProjectImage = (_e) => {
            Menu.instance?.close();
            if (this.imagePicker != null) {
                this.imagePicker.execute();
            }
        };
        this.onMenuPressDeleteProjectImage = (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('projectImageDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" onPress={Alert.instance?.close} title={I18n.m.getMessage('no')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="yes" onPress={this.deleteProjectImageNow} title={I18n.m.getMessage('yes')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                ],
            });
        };
        this.deleteProjectImageNow = (_e) => {
            Alert.instance?.close();
            const { project } = this.props;
            if (project != null) {
                const remove = new RemoveProjectImage({}, project.id, CurrentUser.token);
                remove.execute(AuthClient.instance.commandStore).catch((err) => {
                    ErrorReporter.sendReport({ subject: 'error removing user image', data: err, type: 'warn' });
                    let errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
                    if (err['messageCode'] != null) {
                        errorMessage = I18n.m.getMessage(err['messageCode'], err['messageData']);
                        if ((err['messageCode'] === 'badRequest' || err['messageCode'] === 'internalServer') &&
                            err['message'] != null) {
                            errorMessage += `: ${err['message']}`;
                        }
                    }
                    else if (err['message'] != null) {
                        errorMessage = err['message'];
                    }
                    Routing.instance.alert.post({
                        text: errorMessage,
                    });
                });
            }
        };
        this.openProOnlyDialog = (type) => () => {
            Menu.instance?.close();
            Dialog.instance?.open({
                scrollable: true,
                contentPadding: false,
                content: (<ProOnlyDialogContent headline={I18n.m.getMessage(type === 'Download' ? 'projectDownloadProFunction' : 'projectArchiveProFunction')} description={I18n.m.getMessage(type === 'Download' ? 'projectDownloadProFunctionDescription' : 'projectArchiveProFunctionDescription')} buttonText={I18n.m.getMessage(type === 'Download' ? 'projectDownloadProFunctionButton' : 'projectArchiveProFunctionButton')} buttonFunction={this.redirectToUpgradePage} imageSource={proFunctionImage}/>),
            });
        };
        this.onMenuPressCopyProject = async (_e) => {
            Menu.instance?.close();
            const { project } = this.props;
            Dialog.instance?.close();
            if (project == null)
                return;
            CopyProjectDialog.openDialog(project);
        };
        this.onMenuPressDeleteProject = async (_e) => {
            Menu.instance?.close();
            const { project } = this.props;
            Dialog.instance?.close();
            if (project == null)
                return;
            Dialog.instance?.open({
                scrollable: false,
                showCloseIcon: true,
                contentPadding: false,
                content: <DeleteProjectDialog projectId={project.id} downloadAction={this.onMenuPressDownloadProject}/>,
            });
        };
        this.onMenuPressMarkAsTemplate = async (_e) => {
            Menu.instance?.close();
            const { project } = this.props;
            if (project == null)
                return;
            const isCompanyProject = project.creator.startsWith('company');
            if (isCompanyProject) {
                Dialog.instance?.open({
                    content: I18n.m.getMessage('projectTemplateWarning'),
                    title: I18n.m.getMessage('projectTemplateTitle'),
                    buttons: [
                        <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
                        <ContainedButton key="markAsTemplate" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('save')} onPress={() => {
                                this.onPressMarkProjectAsTemplate().catch((err) => console.error(err));
                            }}/>,
                    ],
                });
            }
        };
        this.onMenuPressUnMarkAsTemplate = async (_e) => {
            Menu.instance?.close();
            const { project } = this.props;
            if (project == null)
                return;
            const isCompanyProject = project.creator.startsWith('company');
            if (isCompanyProject) {
                LoadingEvents.instance.startLoading();
                try {
                    const c = new ChangeProjectTemplateStatus({ template: false }, project.id);
                    await c.execute();
                }
                catch (err) {
                    console.error('could not un mark as template', err);
                    DefaultErrorHandler.showDefaultErrorAlert(err, I18n.m);
                }
                LoadingEvents.instance.stopLoading();
            }
        };
        this.onMenuPressArchiveProject = async (_e) => {
            Menu.instance?.close();
            const { project } = this.props;
            if (project == null)
                return;
            const isCompanyProject = project.creator.startsWith('company');
            Dialog.instance?.open({
                content: I18n.m.getMessage(isCompanyProject ? 'projectArchiveProjectDialogTextCompany' : 'projectArchiveProjectDialogText'),
                title: I18n.m.getMessage('projectArchiveProject'),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="archive" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('projectArchiveProjectDialogButton')} onPress={this.onPressArchiveProject}/>,
                ],
            });
        };
        this.onPressMarkProjectAsTemplate = async () => {
            const { project } = this.props;
            Dialog.instance?.close();
            if (project == null) {
                return;
            }
            LoadingEvents.instance.startLoading();
            try {
                const c = new ChangeProjectTemplateStatus({ template: true }, project.id);
                await c.execute();
            }
            catch (err) {
                console.error('could not mark as template', err);
                DefaultErrorHandler.showDefaultErrorAlert(err, I18n.m);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.onPressArchiveProject = (_e) => {
            const { project } = this.props;
            Dialog.instance?.close();
            if (project == null)
                return;
            LoadingEvents.instance.startLoading();
            const c = new ArchiveProject({}, project.id);
            c.execute()
                .then(() => {
                Routing.instance.goTo('/projects');
            })
                .catch((e) => {
                console.debug('Archive Error', e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
            })
                .finally(() => {
                LoadingEvents.instance.stopLoading();
            });
        };
        this.onMenuPressDownloadProject = (_e) => {
            Menu.instance?.close();
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
            }
            else {
                this.setState({ isLoading: true }, () => {
                    const asyncNow = async () => {
                        try {
                            const link = await CurrentProject.instance.createArchive();
                            this.setState({
                                link,
                                isLoading: true,
                                created: link != null && link.length > 0,
                            }, () => {
                                if (link != null && link.length > 0) {
                                    this.download()(_e);
                                }
                                else {
                                    this.setState({ isLoading: false });
                                }
                            });
                        }
                        catch (e) {
                            console.debug('Archive Error', e);
                            let message = '';
                            if (e.errorApplication != null && e.errorApplication.errorMessage != null) {
                                message = e.errorApplication.errorMessage;
                            }
                            if (message != null && message.length > 0) {
                                console.debug('error', message);
                                Routing.instance.alert.post({
                                    text: I18n.m.getMessage(message),
                                });
                            }
                            else {
                                Routing.instance.alert.post({
                                    text: I18n.m.getMessage('projectDownloadError'),
                                });
                            }
                            this.setState({
                                isLoading: false,
                                created: true,
                                error: message,
                            });
                        }
                    };
                    asyncNow().catch((err) => console.error(err));
                });
            }
        };
        this.download = () => (_e) => {
            const { link } = this.state;
            if (link != null) {
                const orgFilename = `${CurrentProject.instance.getCurrentProject()?.title}.zip`;
                StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                    link,
                    orgFilename,
                })).catch((e) => {
                    console.error('cant download', link, e);
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                    throw e;
                });
            }
        };
        this.openEditMenu = (e) => {
            const { project } = this.props;
            const { target } = e.nativeEvent;
            if (project != null) {
                UIManager.measureInWindow(target, (x, y, _width, height) => {
                    const client = {
                        x,
                        y,
                        height,
                        width: 320,
                    };
                    const items = [
                        {
                            text: I18n.m.getMessage('tooltipEditProjectDetails'),
                            onPress: this.changeProjectDetails,
                        },
                        {
                            text: I18n.m.getMessage('projectImageDropHint'),
                            onPress: this.onMenuPressChangeProjectImage,
                        },
                    ];
                    if (project != null && project.projectImageFileId != null && project.projectImageFileId.length > 0) {
                        items.push({
                            text: I18n.m.getMessage('projectImageRemoveHint'),
                            onPress: this.onMenuPressDeleteProjectImage,
                        });
                    }
                    let isAdmin = project != null && project.creator === CurrentUser.userId;
                    let isCompanyUserAndProjectManager = false;
                    if (project.creator.startsWith('company')) {
                        isAdmin = CompanyUserInfo.me?.role === 'admin';
                        if (CompanyUserInfo.me?.companyId === project.creator.substring(7)) {
                            isCompanyUserAndProjectManager = CurrentProject.instance.getCurrentRights().write.commandChangeProject;
                        }
                    }
                    if (isAdmin || isCompanyUserAndProjectManager) {
                        items.push({
                            text: I18n.m.getMessage('projectCopyProject'),
                            onPress: this.onMenuPressCopyProject,
                        });
                    }
                    if (isCompanyUserAndProjectManager) {
                        items.push({
                            text: I18n.m.getMessage('projectDownloadHint'),
                            onPress: project.projectSubscription != null && project.projectSubscription !== 'basic'
                                ? this.onMenuPressDownloadProject
                                : this.openProOnlyDialog('Download'),
                        });
                        items.push({
                            text: I18n.m.getMessage('projectArchiveProject'),
                            onPress: project.projectSubscription != null && project.projectSubscription !== 'basic'
                                ? this.onMenuPressArchiveProject
                                : this.openProOnlyDialog('Archive'),
                        });
                        if (project.template) {
                            items.push({
                                text: I18n.m.getMessage('projectTemplateUnmarkTitle'),
                                onPress: project.projectSubscription != null && project.projectSubscription !== 'basic'
                                    ? this.onMenuPressUnMarkAsTemplate
                                    : this.openProOnlyDialog('Archive'),
                            });
                        }
                        else {
                            items.push({
                                text: I18n.m.getMessage('projectTemplateTitle'),
                                onPress: project.projectSubscription != null && project.projectSubscription !== 'basic'
                                    ? this.onMenuPressMarkAsTemplate
                                    : this.openProOnlyDialog('Archive'),
                            });
                        }
                    }
                    if (isAdmin) {
                        items.push({
                            text: I18n.m.getMessage('projectDeleteProject'),
                            onPress: this.onMenuPressDeleteProject,
                        });
                    }
                    Menu.instance?.open({
                        client,
                        items,
                    });
                });
            }
        };
        const { project } = props;
        const qrCodeImagePositioning = this.imageMap[this.props.project?.planQrCodePosition || topLeftQrImage];
        this.state = {
            isLoading: false,
            created: false,
            offlineProject: OfflineDataDownloader.getProject(props.project?.id),
            height: this.getHeight(),
            planQrCodePosition: 0,
            showPlanQrCode: project?.showPlanQrCode ?? false,
            qrCodeImagePositioning,
            planQrCodeVerticalOffset: project?.planQrCodeVerticalOffset ?? 0,
            planQrCodeHorizontalOffset: project?.planQrCodeHorizontalOffset ?? 0,
            planQrCodeLabel: project?.planQrCodeLabel === 'Planstatus'
                ? I18n.m.getMessage('planStatus')
                : project?.planQrCodeLabel || I18n.m.getMessage('planStatus'),
            verticalOffsetTitle: I18n.m.getMessage('top'),
            horizontalOffsetTitle: I18n.m.getMessage('left'),
            costCenter: '',
            selectedFilesEditable: project.filesEditableTickets === 'never' ? 0 : project.filesEditableTickets === '24h' ? 1 : 2,
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.init().catch((e) => console.debug('cant init ProjectProfileView', e));
        OfflineDataDownloader.progress.attach(this.updateOfflineProject);
        const { project } = this.props;
        const companyId = CompanyUserInfo.company?.id;
        if (project != null && companyId != null) {
            UpmeshClient.instance.modals.costCenter
                .get({
                filter: `deleted ne true and companyId eq '${companyId}'`,
            })
                .then((e) => {
                e.forEach((c) => {
                    const ct = c.projectIds?.find((p) => p === project.id);
                    if (ct != null) {
                        this.setState({ costCenter: `${c.mark} - ${c.description}` });
                    }
                });
            })
                .catch((err) => console.debug('cant get costcenter', err));
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        OfflineDataDownloader.progress.detach(this.updateOfflineProject);
    }
    getHeight() {
        const { canSeeSettings, size } = this.props;
        if (!canSeeSettings)
            return size.contentHeight - ThemeManager.style.headerHeight;
        return size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(48);
    }
    renderProjectSize(project) {
        const storageUsageInBytes = project.storageUsageInBytes != null ? bytes(project.storageUsageInBytes) : '0';
        const maxSize = ProjectEntity.getMaxSize(project.projectSubscription);
        const percent = project.storageUsageInBytes != null ? Math.round((project.storageUsageInBytes / maxSize) * 100) : 0;
        const barColor = percent > 75 ? ThemeManager.style.brandDanger : ThemeManager.style.brandPrimary;
        return (<View style={{ marginTop: 16, width: '100%' }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSize')}</MaterialText>
        <View style={{
                width: '100%',
                maxWidth: 200,
                height: 7,
                backgroundColor: '#EBEBEB',
                borderRadius: 10,
                margin: 2,
            }}>
          <View style={{
                height: 7,
                borderRadius: 10,
                width: `${percent}%`,
                backgroundColor: barColor,
            }}/>
        </View>
        <MaterialText type={MaterialTextTypes.Caption}>
          {storageUsageInBytes} / {bytes(maxSize)}
        </MaterialText>
      </View>);
    }
    render() {
        const { project, canEdit } = this.props;
        const { height, costCenter, selectedFilesEditable } = this.state;
        if (project == null) {
            return null;
        }
        const { connectedToServer } = this.context;
        const { width } = Dimensions.get('window');
        const isSmallScreen = width < 768;
        return (<ScrollContainer style={{
                height,
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('projectOverview')}</MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>
          {this.renderProjectTitle(project)}
          <View style={{ padding: ThemeManager.style.contentPaddingValue, width: '100%' }}>
            <ProjectTemplateStatus project={project}/>
            {this.renderCostCenter(costCenter)}
            {this.renderProjectSubscription(project)}
            {this.renderProjectType(project)}
            {this.renderProjectPeriod(project)}
            {this.renderProjectBuildingPeriod(project)}
            {this.renderProjectDescription(project)}
            {this.renderOfflineAccessibility(project)}
            {this.renderProjectSize(project)}
          </View>
          <ImagePickerComp ref={this.setImagePicker} options={new ImagePickerOptions({
                maxWidth: 1920,
                maxHeight: 1920,
                includeBase64: false,
                dropMultiHandlerDialogText: I18n.m.getMessage('accountImageDropOnMultiSelect'),
                dropText: I18n.m.getMessage('projectImageDropHint'),
                selectMethod: 'any',
                includeExtra: true,
                cameraType: 'back',
                multiple: false,
                mediaType: 'photo',
                filePicketCompId: `projectImage_0`,
                quality: 0.9,
            })} callBack={(f) => {
                this.onGotUserFile(f).catch((err) => console.error(err));
            }}/>
        </Card>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, paddingTop: 8 }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('projectOptions')}</MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>
          <View style={{
                padding: ThemeManager.style.contentPaddingValue,
                width: '100%',
                flexDirection: isSmallScreen ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isSmallScreen ? 'flex-start' : 'center',
            }}>
            <View style={{ flex: 1, marginBottom: isSmallScreen ? 20 : 0 }}>
              <MaterialText type={MaterialTextTypes.Body1} strong>
                {I18n.m.getMessage('projectOptionsPeriodUploadedTickets')}
              </MaterialText>
              <MaterialText>{I18n.m.getMessage('projectOptionsFilesEditableTickets')}</MaterialText>
            </View>
            <View style={{ alignSelf: isSmallScreen ? 'stretch' : 'auto', minWidth: 300 }}>
              <SegmentedButton accessibilityLabel="Upload Files Edit" buttons={[
                { title: I18n.m.getMessage('projectOptionFilesEditableTicketsNever') },
                { title: I18n.m.getMessage('projectOptionFilesEditableTickets24h') },
                { title: I18n.m.getMessage('projectOptionFilesEditableTicketsEver') },
            ]} singleSelectSelected={selectedFilesEditable} onPress={(index) => {
                if (!canEdit) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
                }
                else if (!connectedToServer) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('commandOfflineNotPossible') });
                }
                else {
                    this.onChangeOption(index === 0 ? 'never' : index === 1 ? '24h' : 'ever');
                }
            }}/>
            </View>
          </View>
          <View style={{
                height: 1,
                backgroundColor: '#d3d3d3',
                alignSelf: 'stretch',
                marginHorizontal: 10,
                marginVertical: ThemeManager.style.contentPaddingValue / 2,
            }}/>
          <View style={{ padding: ThemeManager.style.contentPaddingValue, width: '100%' }}>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('planCurrentStatus')}
            </MaterialText>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flex: 2 }}>
                <MaterialText>{I18n.m.getMessage('qrCodeHintText')}</MaterialText>
              </View>
              <View>
                <Switch key={`qrCode_${project?.showPlanQrCode}`} value={this.state.showPlanQrCode} onPress={this.changeQrCodeVisibility}/>
              </View>
            </View>
            {this.state.showPlanQrCode && (<View style={{
                    width: '100%',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    justifyContent: 'space-between',
                }}>
                <View style={{
                    width: isSmallScreen ? '100%' : '40%',
                    marginBottom: isSmallScreen ? 20 : 0,
                }}>
                  <OpenableChip looksLikeFI formInputLabel={I18n.m.getMessage('qrCodePositionText')} chipsList={this.qrCodePositionsOptions()} selected={this.state.planQrCodePosition} onPressChip={this.changeQrCodePosition} dialogTitle={I18n.m.getMessage('qrCodePositionText')}/>
                  <FormInputFilled iconRight={{
                    icon: 'circle',
                }} unfocusedColor="#00cd93" color="#00cd93" disableUnfocusedLabelColor clearButton={false} labelText={`${I18n.m.getMessage('offset')} ${this.state.verticalOffsetTitle} ${I18n.m.getMessage('in')} mm`} onSubmitEditing={(value) => this.changeVerticalAndHorizontalOffsets(value, true)} submitOnBlur initValue={this.state.planQrCodeVerticalOffset.toString()} filterText={(t) => {
                    return t.replace(/[^0-9,.-]/gi, '');
                }}/>
                  <FormInputFilled iconRight={{
                    icon: 'circle',
                }} unfocusedColor="#0073ff" color="#0073ff" disableUnfocusedLabelColor clearButton={false} labelText={`${I18n.m.getMessage('offset')} ${this.state.horizontalOffsetTitle} ${I18n.m.getMessage('in')} mm`} onSubmitEditing={(value) => this.changeVerticalAndHorizontalOffsets(value, false)} submitOnBlur initValue={this.state.planQrCodeHorizontalOffset.toString()} filterText={(t) => {
                    return t.replace(/[^0-9,.-]/gi, '');
                }}/>
                  <FormInputFilled color="black" disableUnfocusedLabelColor clearButton={false} labelText={I18n.m.getMessage('qrCodeLabel')} onSubmitEditing={(e) => this.changeQrCodeLabel(e)} submitOnBlur initValue={this.state.planQrCodeLabel.toString()} filterText={(t) => {
                    return t.slice(0, 50);
                }}/>
                </View>
                <View style={{
                    alignItems: 'center',
                }}>
                  <CacheableImage resizeMode="contain" key={`${this.state.planQrCodePosition}-image`} lightBox width={220} height={220} source={this.state.qrCodeImagePositioning}/>
                </View>
              </View>)}
          </View>
        </Card>
      </ScrollContainer>);
    }
    renderProjectBuildingPeriod(project) {
        if (project.constructionStart == null && project.constructionEnd == null) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectBuildingPeriod')}</MaterialText>
        </View>
        <MaterialText>
          {project.constructionStart == null ? '' : I18n.m.dateCurrent.localeDateString(project.constructionStart)}

          {project.constructionStart != null && project.constructionEnd != null ? ' ' : ''}

          {project.constructionEnd == null
                ? ''
                : `${I18n.m.getMessage('till')} ${I18n.m.dateCurrent.localeDateString(project.constructionEnd)}`}
        </MaterialText>
      </View>);
    }
    renderOfflineAccessibility(project) {
        let textRight = '';
        let icon = {
            icon: 'cloud-outline',
            toolTip: I18n.m.getMessage('projectDownloadOfflineUnavailable'),
            onPress: this.openHowToDownloadDialog,
        };
        if (Platform.OS !== 'web') {
            const { offlineProject } = this.state;
            textRight = '';
            icon = {
                icon: 'cloud-outline',
                toolTip: I18n.m.getMessage('projectDownloadOfflineUnavailable'),
                onPress: this.openDownloadDialog(project),
            };
            if (offlineProject != null) {
                if (offlineProject.status !== 'removed') {
                    if (offlineProject.status === 'isSyncing') {
                        textRight = `${offlineProject.percentSynced}%`;
                        icon = {
                            icon: 'cloud-sync-outline',
                            toolTip: I18n.m.getMessage('projectDownloadOfflineAvailableSyncing'),
                            onPress: this.openDownloadDialog(project),
                        };
                    }
                    else if (offlineProject.status === 'synced') {
                        textRight = '';
                        icon = {
                            icon: 'cloud-check-outline',
                            toolTip: I18n.m.getMessage('projectDownloadOfflineAvailable'),
                            onPress: this.openDownloadDialog(project),
                        };
                    }
                }
            }
        }
        return (<Ripple onPress={icon.onPress} style={{ flexDirection: 'row', height: 36, alignItems: 'center', marginTop: 16 }}>
        <MaterialText centeredBox>{I18n.m.getMessage('projectDownload')}</MaterialText>
        <Icon {...icon}/>
        <MaterialText type={MaterialTextTypes.Caption} centeredBox>
          {textRight}
        </MaterialText>
      </Ripple>);
    }
    renderProjectDescription(project) {
        if (project.description == null || project.description.length === 0) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectDescription')}</MaterialText>
        </View>
        <MaterialText recognizeUrl>{project.description}</MaterialText>
      </View>);
    }
    renderProjectPeriod(project) {
        if (project.projectStart == null && project.projectEnd == null) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectPeriod')}</MaterialText>
        </View>
        <MaterialText>
          {project.projectStart == null ? '' : I18n.m.dateCurrent.localeDateString(project.projectStart)}

          {project.projectStart != null && project.projectEnd != null ? ' ' : ''}

          {project.projectEnd == null
                ? ''
                : `${I18n.m.getMessage('till')} ${I18n.m.dateCurrent.localeDateString(project.projectEnd)}`}
        </MaterialText>
      </View>);
    }
    renderProjectTitle(project) {
        const { canEdit } = this.props;
        return (<View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: ThemeManager.style.contentPaddingValue,
            }}>
        <View style={{
                flexDirection: 'row',
                width: '100%',
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: 72,
                alignItems: 'center',
            }}>
          <ProjectImage project={project} size={40} lightBox/>
          <View style={{ minHeight: 34, marginLeft: 8, paddingRight: 48, maxWidth: '100%' }}>
            <MaterialText type={MaterialTextTypes.H5} color={ThemeManager.style.black87} fixedWidth="100%">
              {project.title}
            </MaterialText>
          </View>
        </View>
        <View style={{ position: 'absolute', alignSelf: 'stretch', top: 0, right: 16 }}>
          <Icon toolTip={canEdit ? I18n.m.getMessage('tooltipEditProjectDetails') : I18n.m.getMessage('forbiddenCommand')} onPress={this.openEditMenu} icon="dots-vertical" color={ThemeManager.style.defaultIconColor} disabled={!canEdit}/>
        </View>
      </View>);
    }
    renderProjectType(project) {
        if (project.projectType == null || project.projectType.length === 0) {
            return null;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectType')}</MaterialText>
        </View>
        <MaterialText>{project.projectType}</MaterialText>
      </View>);
    }
    getUpgradeButton() {
        const { project } = this.props;
        const { userSubscription } = this.state;
        if (project == null || userSubscription == null)
            return null;
        return <UpgradeButton project={project} userSubscription={userSubscription}/>;
    }
    renderCostCenter(costCenter) {
        if (costCenter == null || costCenter.length === 0)
            return null;
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('costUnit')}</MaterialText>
        </View>
        <MaterialText>{costCenter}</MaterialText>
      </View>);
    }
    renderProjectSubscription(project) {
        let subscription = 'basic';
        if (project.projectSubscription != null && project.projectSubscription.length > 0) {
            subscription = project.projectSubscription;
        }
        return (<View>
        <View style={{ marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('projectSubscription')}</MaterialText>
        </View>
        <MaterialText>{I18n.m.getMessage(`${subscription}Project`)}</MaterialText>
        {this.getUpgradeButton()}
      </View>);
    }
    redirectToUpgradePage() {
        Dialog.instance?.close();
    }
}
ProjectProfileView.contextType = ConnectionContext;
