var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Overlay = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ResizeEvent = require("../ResizeEvent");
var _ThemeManager = require("../ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Overlay = exports.Overlay = function (_PureComponent) {
  function Overlay(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Overlay);
    _this = _callSuper(this, Overlay, [props]);
    _this.state = {
      opacity: new _reactNativeWeb.Animated.Value(0),
      showOverlay: props.showOverlay,
      onPress: props.onPress
    };
    return _this;
  }
  (0, _inherits2.default)(Overlay, _PureComponent);
  return (0, _createClass2.default)(Overlay, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var toValue = this.props.showOverlay === true ? this.props.opacity != null ? this.props.opacity : 0.6 : 0;
      _reactNativeWeb.Animated.timing(this.state.opacity, {
        toValue: toValue,
        duration: this.props.animationDuration == null ? 195 : this.props.animationDuration,
        useNativeDriver: false
      }).start();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, prevState, _snapshot) {
      if (prevState.showOverlay !== this.state.showOverlay) {
        var toValue = this.state.showOverlay === true ? this.props.opacity != null ? this.props.opacity : 0.6 : 0;
        _reactNativeWeb.Animated.timing(this.state.opacity, {
          toValue: toValue,
          duration: this.props.animationDuration,
          useNativeDriver: false
        }).start();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$props = this.props,
        pointerEvents = _this$props.pointerEvents,
        elevation = _this$props.elevation;
      var onPress = this.state.onPress;
      var backgroundColor = {
        backgroundColor: this.state.backgroundColor
      };
      var opacity = {
        opacity: this.state.opacity
      };
      var dimensions = {
        width: this.props.width != null ? this.props.width : _ResizeEvent.ResizeEvent.current.windowWidth,
        height: this.props.height != null ? this.props.height : _ResizeEvent.ResizeEvent.current.windowHeight
      };
      var elevationStyle = {
        zIndex: elevation
      };
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        pointerEvents: pointerEvents,
        style: [_ThemeManager.ThemeManager.style.overlay, backgroundColor, opacity, _ThemeManager.ThemeManager.style.absoluteStyle, elevationStyle],
        children: onPress != null ? (0, _jsxRuntime.jsx)(_reactNativeWeb.TouchableOpacity, {
          onPress: onPress != null ? onPress : undefined,
          style: [_ThemeManager.ThemeManager.style.overlay, dimensions]
        }) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: [_ThemeManager.ThemeManager.style.overlay, dimensions]
        })
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        elevation: undefined,
        backgroundColor: _ThemeManager.ThemeManager.style.overlayColor,
        opacity: 0.6,
        animationDuration: 195,
        showOverlay: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (prevState.showOverlay !== nextProps.showOverlay || nextProps.onPress !== prevState.onPress || nextProps.backgroundColor !== prevState.backgroundColor) {
        return {
          showOverlay: nextProps.showOverlay,
          onPress: nextProps.onPress,
          backgroundColor: nextProps.backgroundColor
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);