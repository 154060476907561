import { Url } from 'cqrs-shared/src/uri/Url';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import querystring from 'query-string';
import React from 'react';
import { ConfigAll } from './config/ConfigAll';
import { I18n } from './i18n/I18n';
export class VersionChecker {
    static startChecker() {
        if (VersionChecker.intervall)
            clearInterval(VersionChecker.intervall);
        const update = () => {
            VersionChecker.checkForUpdate().catch((err) => {
                console.error(err);
            });
        };
        VersionChecker.intervall = setInterval(update, 360000);
        setTimeout(update, 1000);
    }
    static async checkForUpdate() {
        if (Alert.instance == null) {
            setTimeout(() => {
                VersionChecker.checkForUpdate().catch(console.error);
            }, 1000);
            return;
        }
        const result = await fetch(`${ConfigAll.b2cURL}/releasenotes/current`);
        if (result.status === 200) {
            const v = await result.text();
            const lv = ConfigAll.getVersion().split('@');
            if (v !== '333' && lv.length > 1 && v !== lv[1]) {
                Routing.instance.alert.post({
                    title: I18n.m.getMessage('updateAppTitle'),
                    text: I18n.m.getMessage('updateAppText'),
                    buttons: [
                        <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor={ThemeManager.style.brandDanger}/>,
                        <ContainedButton key="yes" title={I18n.m.getMessage('updateNeededButton')} onPress={() => {
                                const uri = Url.getURLfromString(window.location.href);
                                const s = uri.search != null && uri.search.length > 0
                                    ? uri.search.substr(0, 1) === '?'
                                        ? uri.search.substr(1)
                                        : uri.search
                                    : '';
                                const parameterValue = { d: Date.now().toString() };
                                const o = { ...querystring.parse(s), ...parameterValue };
                                Object.keys(o).forEach((k) => {
                                    if (typeof o[k] === 'object') {
                                        o[k] = querystring.stringify(o[k]);
                                    }
                                });
                                uri.search = querystring.stringify(o);
                                window.location.href = uri.href;
                            }}/>,
                    ],
                });
            }
        }
    }
}
