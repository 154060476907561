import { Url } from 'cqrs-shared/src/uri/Url';
import * as _ from 'lodash';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { orderBy } from 'natural-orderby';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import querystring from 'querystring';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { PlanFilter } from 'upmesh-core/src/client/query/filter/PlanFilter';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { PlansFilterDialog } from '../plans/PlansFilterDialog';
import { PlanTagFilterIcon } from '../plans/PlanTagFilterIcon';
import { PlanThumb169 } from '../plans/PlanThumb169';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { openPlanSelectorDialog } from './PlanSelector';
import { TicketDialogFilter } from './TicketDialogFilter';
import { TicketNavigation } from './TicketNavigation';
import { TicketsDetailsView } from './TicketsDetailsView';
import { parseAdditinalViewData, saveAdditionalData, sortTicketViews } from './ticketViewUtil';
export class TicketsView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.filteredTickets = CurrentProject.instance.getCurrentTickets();
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.tickets = CurrentProject.instance.getCurrentTickets();
        this.fetchTicketViews = async () => {
            const { v } = this.props;
            const projectId = CurrentProject.instance.getCurrentProjectId();
            let all = await UpmeshClient.instance.modals.view.get({
                filter: `projectId eq '${projectId}' and deleted ne true`,
            });
            all = sortTicketViews(all);
            if (v == null) {
                this.selectTab(0, all);
            }
            else {
                const index = all.findIndex((vi) => `${vi.id}` === `${v}`);
                if (index > -1)
                    this.selectTab(index, all);
                else
                    this.selectTab(0, all);
            }
        };
        this.updateViews = (e) => {
            const { views, selectedTab } = this.state;
            let nextSelected = selectedTab;
            let { viewDataHasChanged, additionalViewData } = this.state;
            let newViews = [...views];
            const projectId = CurrentProject.instance.getCurrentProjectId();
            e.entities.forEach((val, key) => {
                if (!val.entity || val.entity.projectId !== projectId)
                    return;
                const index = newViews.findIndex((e) => e.id === key);
                if (index === -1 && !val.entity.deleted) {
                    newViews.push(val.entity);
                    newViews = sortTicketViews(newViews);
                    nextSelected = newViews.findIndex((el) => val.entity.id === el.id);
                }
                else if (val.entity.deleted && index >= 0) {
                    newViews.splice(index, 1);
                    if (index === selectedTab) {
                        nextSelected = Math.min(selectedTab, newViews.length - 1);
                        additionalViewData = parseAdditinalViewData(newViews[nextSelected].additionalData).parsed;
                        viewDataHasChanged = false;
                    }
                    else if (index < selectedTab)
                        nextSelected = selectedTab - 1;
                }
                else {
                    const defaultChanged = val.entity.default !== newViews[index].default;
                    newViews[index] = val.entity;
                    if (defaultChanged) {
                        newViews = sortTicketViews(newViews);
                        nextSelected = newViews.findIndex((el) => val.entity.id === el.id);
                    }
                    if (selectedTab === index && newViews[selectedTab].additionalData !== views[selectedTab].additionalData) {
                        viewDataHasChanged = false;
                        try {
                            additionalViewData = JSON.parse(newViews[selectedTab].additionalData);
                        }
                        catch (err) {
                            additionalViewData = {};
                        }
                    }
                }
            });
            if (nextSelected !== selectedTab && nextSelected >= 0)
                this.selectTab(nextSelected, newViews);
            else
                this.setState({ views: newViews, viewDataHasChanged, additionalViewData });
        };
        this.overrideViewData = (key, content) => {
            const { selectedTab, views, additionalViewData } = this.state;
            const currentView = views[selectedTab];
            if (currentView.createdBy !== CurrentUser.userId)
                return;
            if (JSON.stringify(content) === JSON.stringify(additionalViewData[key]))
                return;
            if (currentView.id === 'main') {
                this.setState((prevState) => {
                    const newAdditionalViewData = { ...prevState.additionalViewData };
                    newAdditionalViewData[key] = content;
                    saveAdditionalData(currentView.id, newAdditionalViewData);
                    const newViews = [...prevState.views];
                    newViews[selectedTab].additionalData = JSON.stringify(newAdditionalViewData);
                    return {
                        views: newViews,
                        additionalViewData: newAdditionalViewData,
                    };
                });
                return;
            }
            this.setState((prevState) => {
                const newAdditionalViewData = { ...prevState.additionalViewData };
                newAdditionalViewData[key] = content;
                return {
                    additionalViewData: newAdditionalViewData,
                    viewDataHasChanged: true,
                };
            }, () => Toast.instance?.open({
                toastVisibleTime: 5000,
                title: I18n.m.getMessage('ticketsViewChanged'),
                buttonTitle: I18n.m.getMessage('ticketsViewSave'),
                onPressButton: () => {
                    saveAdditionalData(currentView.id, this.state.additionalViewData);
                    Toast.instance?.close();
                },
            }));
        };
        this.gotoPlanDetails = (planId) => (_e) => {
            const { sortBy } = this.state;
            const id = planId == null ? 'all' : planId;
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const { searchWords, searchTicketsWords, ticketFilter } = this.state;
            const { v } = this.props;
            const f = ticketFilter != null ? querystring.stringify({ f: JSON.stringify(ticketFilter) }) : '';
            const fp = this.planFilter != null ? querystring.stringify({ fp: JSON.stringify(this.planFilter) }) : '';
            const url = Url.getURLfromString(`/projects/${projectId}/tickets/${id}/`);
            url.search = `v=${v}&qp=${searchWords}&q=${searchTicketsWords}&${f}&${fp}&o=${sortBy}`;
            Routing.instance.goTo(url);
        };
        this.onChangeTicketOrder = (tickets) => {
            TicketNavigation.setTickets(tickets);
        };
        this.planFilter = new PlanFilter();
        this.openPlanFilter = () => {
            PlansFilterDialog.open(this.plans, this.setPlanFilter, this.planFilter);
        };
        this.setPlanFilter = (filter) => {
            this.planFilter = filter;
            this.getVisibleTickets(this.state.selectedPlanId, this.state.ticketFilter)
                .then((t) => {
                TicketNavigation.setTickets(t.visibleTickets);
                this.setState(t);
            })
                .catch((err) => console.debug('cant update ticket', err));
        };
        this.startMoveOnX = null;
        this.startTreeWidth = null;
        this.onResponderGrant = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.touches != null) {
                this.startMoveOnX = e.nativeEvent.touches[0].pageX;
                this.startTreeWidth = this.state.planlistWidth;
            }
        };
        this.onResponderEnd = (_e) => {
            this.startMoveOnX = null;
            this.startTreeWidth = null;
            const { planlistWidth } = this.state;
            SimpleStorage.set('ticketPlanlistWidth', planlistWidth.toString());
        };
        this.onTouchMove = (e) => {
            const { startMoveOnX, startTreeWidth } = this;
            if (e != null &&
                e.nativeEvent != null &&
                e.nativeEvent.touches != null &&
                startMoveOnX != null &&
                startTreeWidth != null) {
                const diff = e.nativeEvent.touches[0].pageX - startMoveOnX;
                let planlistWidth = startTreeWidth + diff;
                planlistWidth = Math.min(planlistWidth, ResizeEvent.current.contentWidth / 3);
                planlistWidth = Math.max(planlistWidth, 0);
                if (planlistWidth !== this.state.planlistWidth) {
                    this.setState({ planlistWidth });
                }
            }
        };
        this.selectTab = (index, updatedViews) => {
            const asnycNow = async () => {
                const { selectedTab } = this.state;
                const views = updatedViews ?? this.state.views;
                const currentSelected = this.state.views[selectedTab];
                const newView = views[index];
                const isSameView = currentSelected?.id === newView?.id;
                if (isSameView && this.props.v != null) {
                    this.setState((prevState) => ({ selectedTab: index, views: updatedViews ?? prevState.views }), () => {
                        this.updateVisibleTickets().catch((err) => {
                            console.error(err);
                        });
                    });
                    return;
                }
                const { parsed, filter: nextFilter, order: nextOrder, sortDirection: nextSortDirection, } = parseAdditinalViewData(newView.additionalData);
                Routing.instance.changeQueryParameter({ v: newView.id });
                Toast.instance?.close();
                this.setState((prevState) => ({
                    selectedTab: index,
                    sortBy: nextOrder,
                    sortDirection: nextSortDirection,
                    ticketFilter: nextFilter,
                    viewDataHasChanged: false,
                    views: updatedViews ?? prevState.views,
                    additionalViewData: parsed,
                    visibleTickets: [],
                }), () => {
                    setTimeout(() => {
                        Routing.instance.changeQueryParameter({ v: newView.id });
                        this.updateVisibleTickets().catch((err) => {
                            console.error(err);
                        });
                    }, 195);
                });
            };
            asnycNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
        };
        this.openPlanSelector = (e) => {
            const { selectedPlanId } = this.state;
            openPlanSelectorDialog({
                withAll: true,
                plans: this.plans,
                currentFilter: this.planFilter,
                currentSelecedPlanId: selectedPlanId,
                onSelect: (planId, filter) => (e) => {
                    this.gotoPlanDetails(planId)(e);
                    this.setPlanFilter(filter);
                },
                onClose: (f) => this.setPlanFilter(f),
            })(e);
        };
        this.updateSortBy = (sortBy) => {
            this.overrideViewData('o', sortBy);
            this.setState({ sortBy }, () => {
                this.updateVisibleTickets().catch(console.error);
            });
        };
        this.updateSortDirection = (direction) => {
            this.overrideViewData('d', direction);
            this.setState({ sortDirection: direction }, () => {
                this.updateVisibleTickets().catch(console.error);
            });
        };
        this.getItemKey = (item, _index) => `plan_${item.id}`;
        this.targetWidth = 100;
        this.onPlanChange = _.debounce((plans) => {
            this.plans = plans;
            this.updateVisibleTickets().catch((err) => console.debug(err));
        }, 1500);
        this.onPressFilter = (_e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const { selectedPlanId } = this.state;
                this.getVisibleTicketsWithoutFilter(this.tickets, selectedPlanId)
                    .then((tickets) => {
                    TicketDialogFilter.open(tickets.visibleTickets, this.setFilter, this.state.ticketFilter, projectId);
                })
                    .catch((err) => console.debug(err));
            }
        };
        this.onSearch = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNow(text), 250);
        };
        this.onSearchTickets = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNowTickets(text), 250);
        };
        this.onTicketChange = _.debounce((tickets) => {
            this.tickets = tickets;
            this.updateVisibleTickets().catch((err) => console.debug('cant update visible tickets', err));
        }, 200);
        this.pressCreateTicket = (planId, planX, planY) => async (e) => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            if (projectId != null) {
                const pid = planId == null || planId.length === 0 || planId === 'all' ? undefined : planId;
                let x = planX;
                let y = planY;
                if (pid != null && (planX == null || planY == null)) {
                    try {
                        if (RouterControl.instance.currentUrl.search != null) {
                            const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
                            if (query != null && query['zTo'] != null) {
                                const s = query['zTo'].toString().split('x');
                                if (s.length >= 2) {
                                    x = Number.parseFloat(s[0]);
                                    y = Number.parseFloat(s[1]);
                                }
                            }
                        }
                    }
                    catch (e) {
                        console.debug('cant get query search', e);
                    }
                }
                const catched = await DefaultErrorHandler.getProjectErrors(projectId);
                if (catched) {
                    return;
                }
                const c = new CreateTicket({ projectId, title: '' });
                c.canI()
                    .then(() => Routing.instance.openDialog('ticket', { projectId, planId: pid, planX: x, planY: y, id: 'new' })(e))
                    .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
            }
        };
        this.renderRow = ({ item }) => {
            const { planId } = this.props;
            const { ticketsOnPlan } = this.state;
            const ticketsOnThisPlan = ticketsOnPlan[item.id] != null ? ticketsOnPlan[item.id] : 0;
            const selected = planId === item.id;
            const itemView = (<View style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    width: this.targetWidth,
                    overflow: 'visible',
                }} key={`PlanThumb_${item.id}`}>
        <PlanThumb169 plan={item} projectId={item.projectId} fileSource={item.activePlanId === 'MAP' ? 'map' : 'planVersion'} fileId={item.activePlanId} width={this.targetWidth - ThemeManager.style.getScreenRelativePixelSize(8)} selected={selected} badge={ticketsOnThisPlan.toString()} onPress={this.gotoPlanDetails(item.id)} title={item.title} bottomRightIcon={item.notes && item.notes.length > 0
                    ? { icon: 'information-outline', toolTip: '', onPress: this.showPlanNotes(item) }
                    : undefined}/>
      </View>);
            return itemView;
        };
        this.showPlanNotes = (plan) => () => {
            Dialog.instance?.open({
                title: I18n.m.getMessage('planDetailsPlanNotes'),
                content: plan.notes,
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('ok')} key="close" onPress={Dialog.instance?.close}/>,
                ],
            });
        };
        this.searchNow = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            Routing.instance.changeQueryParameter({ qp: text });
            this.setState({ searchWords: text }, () => {
                this.updateVisibleTickets().catch((err) => console.debug(err));
            });
        };
        this.searchNowTickets = (text) => () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNowTickets(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            Routing.instance.changeQueryParameter({ q: text });
            this.setState({ searchTicketsWords: text }, () => {
                this.updateVisibleTickets().catch((err) => console.debug('cant update visible tickets', err));
            });
        };
        this.setFilter = (ticketFilter) => {
            this.overrideViewData('f', ticketFilter);
            this.setState({ ticketFilter }, () => {
                this.updateVisibleTickets().catch((err) => {
                    console.error(err);
                });
            });
        };
        let ticketFilter = new TicketFilter();
        try {
            ticketFilter = props.f != null ? JSON.parse(props.f) : new TicketFilter();
        }
        catch (e) {
            console.debug('cant parse ticketfilter');
        }
        try {
            this.planFilter = props.fp != null ? JSON.parse(props.fp) : new PlanFilter();
        }
        catch (e) {
            this.planFilter = new PlanFilter();
            console.debug('cant parse planFilter');
        }
        this.plans = CurrentProject.instance.getCurrentPlans();
        this.tickets = CurrentProject.instance.getCurrentTickets();
        this.state = {
            ticketFilter,
            searchWords: props.p != null ? props.qp : '',
            searchTicketsWords: props.q != null ? props.q : '',
            visiblePlans: [],
            selectedPlanId: this.plans.length === 0 ? 'all' : undefined,
            selectedPlan: undefined,
            selectedPlanVersion: undefined,
            visibleTickets: CurrentProject.instance.getCurrentTickets(),
            ticketsOnPlan: {},
            amountTotal: this.tickets.length,
            planlistWidth: 0,
            views: [],
            selectedTab: 0,
            sortBy: 'number',
            viewDataHasChanged: false,
            additionalViewData: {},
            sortDirection: '0',
        };
    }
    componentDidMount() {
        CurrentProject.plansChanged.attach(this.onPlanChange);
        CurrentProject.ticketsChanged.attach(this.onTicketChange);
        this.onTicketChange(CurrentProject.instance.getCurrentTickets());
        this.mounted = true;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        this.fetchTicketViews()
            .then(() => {
            this.setPlan().catch((err) => console.debug(err));
        })
            .catch(console.error);
        UpmeshClient.eventDispatcher.attach({
            attachKey: `ticketViews${projectId}`,
            readModelName: 'View',
            callback: this.updateViews,
        });
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (this.plans.length > 0 && this.setPlanId !== this.props.planId) {
            this.setPlanId = this.props.planId;
            this.setPlan().catch((err) => console.debug(err));
        }
        if (_prevProps.q !== this.props.q) {
            this.updateVisibleTickets().catch((err) => console.debug(err));
        }
        if (_prevProps.v !== this.props.v) {
            if (this.props.v == null && this.state.views.length > 0) {
                this.selectTab(0);
            }
        }
    }
    componentWillUnmount() {
        TicketNavigation.setTickets([]);
        CurrentProject.plansChanged.detach(this.onPlanChange);
        CurrentProject.ticketsChanged.detach(this.onTicketChange);
        UpmeshClient.eventDispatcher.detach('View', `ticketViews${CurrentProject.instance.getCurrentProjectId()}`);
    }
    renderPlanSelection() {
        const { selectedPlanId, visiblePlans, searchWords, planlistWidth } = this.state;
        const { size } = this.props;
        const splitView = this.getSplit();
        const planThumbs = [];
        const tagIcon = (<PlanTagFilterIcon currentFilter={this.planFilter} setFilter={(f) => this.setPlanFilter(f)} plans={this.plans}/>);
        let page1Style = {
            position: 'absolute',
            width: '100%',
            height: size.contentHeight,
            overflow: 'hidden',
        };
        page1Style = {
            ...page1Style,
            maxWidth: planlistWidth,
            borderWidth: 0,
            borderRightWidth: ThemeManager.style.borderWidth,
            borderStyle: 'solid',
            borderColor: ThemeManager.style.borderColor,
        };
        planThumbs.push(...visiblePlans);
        let filterIcon;
        if (splitView || selectedPlanId == null || selectedPlanId.length === 0) {
            const isFilterActive = PlanFilter.isSet(this.planFilter);
            filterIcon = (<Icon key={`planFilter_${isFilterActive}`} icon={isFilterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={this.openPlanFilter} color={isFilterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        }
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        const projectId = CurrentProject.instance.getCurrentProjectId();
        const numColumns = this.createNumColumns(splitView ? planlistWidth : size.contentWidth);
        return (<PageView style={page1Style} headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('planSiteSearchPlans'),
                    searchOnChange: this.onSearch,
                    tooltip: I18n.m.getMessage('searchTickets'),
                },
                rightButtons: [filterIcon, tagIcon],
            }} scrollable={false}>
        <FabWithCamera size={size} additionalActionButtons={[
                {
                    icon: 'map-marker-check-outline',
                    onPress: (_e) => {
                        if (Fab.instance != null) {
                            Fab.instance.closeButtons();
                        }
                        this.pressCreateTicket(this.state.selectedPlanId)(_e).catch((err) => console.debug(err));
                    },
                    text: I18n.m.getMessage('ticketsAddTicket'),
                },
            ]} projectId={projectId}/>
        <View style={{
                position: 'relative',
                width: '100%',
                height: size.contentHeight - ThemeManager.style.headerHeight - sViewHeight,
            }}>
          <View style={{
                position: 'absolute',
                padding: 0,
                paddingLeft: ThemeManager.style.contentPaddingValue,
                paddingRight: ThemeManager.style.contentPaddingValue,
                paddingTop: ThemeManager.style.getScreenRelativePixelSize(8),
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                margin: 0,
                height: ThemeManager.style.getScreenRelativePixelSize(44),
                alignSelf: 'flex-start',
                width: '100%',
            }}>
            <ContainedButton onPress={this.gotoPlanDetails('all')} title={`${I18n.m.getMessage('allTickets')}(${this.filteredTickets.length})`} raised={false} full backgroundColor={ThemeManager.style.brandPrimary} textColor="#FFFFFF"/>
          </View>

          <View style={{
                position: 'absolute',
                width: '100%',
                height: size.contentHeight -
                    ThemeManager.style.headerHeight -
                    ThemeManager.style.getScreenRelativePixelSize(44) -
                    sViewHeight,
                top: ThemeManager.style.getScreenRelativePixelSize(44),
                padding: 0,
                marginLeft: ThemeManager.style.getScreenRelativePixelSize(8),
                marginRight: ThemeManager.style.getScreenRelativePixelSize(8),
            }}>
            <FlatList key={`planTicketsList_${numColumns}`} keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={numColumns} data={planThumbs} style={ThemeManager.style.absoluteStyle}/>
          </View>
        </View>
      </PageView>);
    }
    render() {
        const { selectedPlan, selectedPlanVersion, visibleTickets, searchTicketsWords, ticketFilter, planlistWidth, selectedTab, views, sortBy, sortDirection, viewDataHasChanged, additionalViewData, } = this.state;
        const { size, v } = this.props;
        const splitView = this.getSplit();
        let ticketsView = null;
        const filter = this.state.ticketFilter;
        const filterActive = this.isFilter(filter);
        const ticketsViewWidth = splitView ? size.contentWidth - planlistWidth : size.contentWidth;
        if (v != null && v.length > 0)
            ticketsView = (<View key="selectedPlan" style={{
                    position: 'absolute',
                    left: splitView ? planlistWidth : 0,
                    width: ticketsViewWidth,
                    height: '100%',
                }}>
          <TicketsDetailsView onChangeTicketOrder={this.onChangeTicketOrder} childHeight={!splitView && this.plans.length > 0 ? 48 : 0} size={size} pressCreateTicket={this.pressCreateTicket} zTo={this.props.zTo} backButton={false} onSearch={this.onSearchTickets} searchWords={searchTicketsWords} currentTicketFilter={ticketFilter} filterActive={filterActive} onPressFilter={this.onPressFilter} tickets={visibleTickets} key="planTicketsDetails" plan={selectedPlan} planVersion={selectedPlanVersion} splitViewWidth={splitView && planlistWidth > 0 ? planlistWidth : 0} showFab={!splitView} sortBy={sortBy} sortDirection={sortDirection == null ? '0' : sortDirection} updateTicketFilter={this.setFilter} allTickets={this.tickets} setFilter={this.setFilter} selectedView={this.props.v} initialTab={selectedTab} views={views} updateSortBy={this.updateSortBy} updateSortDirection={this.updateSortDirection} selectTab={this.selectTab} additionalViewData={additionalViewData} viewDataHasChanged={viewDataHasChanged} width={ticketsViewWidth} overrideViewData={this.overrideViewData}>
            {!splitView && this.plans.length > 0 && (<View style={{
                        height: 48,
                        width: '100%',
                        paddingHorizontal: 12,
                        justifyContent: 'center',
                        overflow: 'visible',
                    }}>
                <Ripple onPress={this.openPlanSelector} style={{
                        width: '100%',
                        height: 36,
                        elevation: 2,
                        ...ThemeManager.style.elevation2,
                        borderRadius: ThemeManager.style.borderRadius,
                        backgroundColor: '#FFFFFF',
                        paddingLeft: 8,
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                    }}>
                  <View style={{ flex: 1 }}>
                    <MaterialText type={MaterialTextTypes.Caption}>Plan wählen</MaterialText>
                    <MaterialText strong>
                      {selectedPlan != null ? selectedPlan.title : I18n.m.getMessage('allTickets')} (
                      {visibleTickets.length})
                    </MaterialText>
                  </View>
                  <View style={{ width: 48 }}>
                    <Icon icon="chevron-down" toolTip=""/>
                  </View>
                </Ripple>
              </View>)}
          </TicketsDetailsView>
        </View>);
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (<View style={{
                width: size.contentWidth,
                position: 'relative',
                flexDirection: 'row',
                height: size.contentHeight - sViewHeight,
            }}>
        {splitView ? this.renderPlanSelection() : null}
        {ticketsView}
        {splitView && (<View style={{
                    position: 'absolute',
                    bottom: 4,
                    left: Math.max(0, planlistWidth - 52),
                    width: 36,
                    height: 36,
                }} onResponderMove={this.onTouchMove} onMoveShouldSetResponderCapture={() => true} onResponderGrant={this.onResponderGrant} onResponderEnd={this.onResponderEnd}>
            <Icon borderStyle="solid" backgroundColor={ThemeManager.style.appBgColor} icon="arrow-left-right" toolTip=""/>
          </View>)}
      </View>);
    }
    createNumColumns(forWidth) {
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const width = forWidth - 2 * ThemeManager.style.contentPaddingValue;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = width / numColumns;
        this.targetWidth = targetWidth;
        return numColumns;
    }
    async filterTickets(tickets, filter) {
        this.filteredTickets = (await TicketFilter.filterTickets(tickets, filter));
        this.setState({ amountSelected: this.filteredTickets.length });
        return this.filteredTickets;
    }
    getSplit() {
        const { size } = this.props;
        return this.plans.length > 0 && size.windowWidth > ThemeManager.style.breakpointL;
    }
    isFilter(filter) {
        return filter != null && TicketFilter.isSet(filter);
    }
    async setPlan() {
        const { planId } = this.props;
        const { ticketFilter } = this.state;
        const planlistWidth = SimpleStorage.get('ticketPlanlistWidth');
        let selectedPlan;
        let selectedPlanVersion;
        let selectedPlanId = planId;
        if (planId != null && planId.length > 0 && planId !== 'all') {
            try {
                selectedPlan = await UpmeshClient.instance.modals.plan.getById(planId);
            }
            catch (e) {
                ErrorReporter.sendReport({
                    subject: `Cant get plan in TicketsView - ${planId}`,
                    data: e,
                    type: 'warn',
                });
            }
            if (selectedPlan != null && selectedPlan.activePlanId != null && selectedPlan.activePlanId.length > 3) {
                try {
                    selectedPlanVersion = await UpmeshClient.instance.modals.planVersion.getById(selectedPlan.activePlanId);
                }
                catch (e) {
                    e.description = `plan: ${planId}, details: ${e.description}`;
                    ErrorReporter.sendReport({
                        subject: `Cant get planversion in TicketsView - ${e.name} ${e.message}`,
                        data: e,
                        type: 'warn',
                    });
                }
            }
        }
        else if (this.plans.length === 0) {
            selectedPlanId = 'all';
        }
        const t = await this.getVisibleTickets(selectedPlanId, ticketFilter);
        this.setState({
            planlistWidth: planlistWidth != null ? Number.parseInt(planlistWidth, 10) : 256,
            selectedPlan,
            selectedPlanVersion,
            selectedPlanId,
            ...t,
        });
    }
    async getSearchedTickets(tickets, planId) {
        let visibleTickets = tickets;
        const { searchTicketsWords, selectedPlanId, sortBy, sortDirection } = this.state;
        const pId = planId != null ? planId : selectedPlanId;
        if (pId != null && pId !== 'all') {
            visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, [pId]));
            if (searchTicketsWords != null && searchTicketsWords.length > 0) {
                visibleTickets = (await TicketFilter.filterTicketsByText(searchTicketsWords, visibleTickets, true));
                this.filteredTickets = visibleTickets;
            }
        }
        else if (searchTicketsWords != null && searchTicketsWords.length > 0) {
            visibleTickets = (await TicketFilter.filterTicketsByText(searchTicketsWords, visibleTickets, true));
            this.filteredTickets = visibleTickets;
        }
        const sortedTickets = searchTicketsWords.length > 0 || sortBy == null || sortBy.length === 0 || sortBy === 'undefined'
            ? visibleTickets
            : this.sortTickets(visibleTickets, sortBy, sortDirection ?? '0');
        return sortedTickets;
    }
    async getVisibleTicketsWithoutFilter(vTickets, selectedPlanId) {
        let visibleTickets = await this.getSearchedTickets(vTickets, selectedPlanId);
        let visiblePlans = this.plans;
        const { searchWords } = this.state;
        if (selectedPlanId != null && selectedPlanId !== 'all') {
            visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, [
                selectedPlanId,
            ]));
        }
        if (searchWords != null && searchWords.length > 0) {
            visiblePlans = await PlanFilter.filterPlansByText(searchWords, visiblePlans);
            visiblePlans = await PlanFilter.filterPlans(visiblePlans, this.planFilter);
            const planIds = visiblePlans.map(({ id }) => id);
            this.filteredTickets = visibleTickets = (await TicketFilter.filterTicketsByPlanIds(visibleTickets, planIds));
        }
        const ticketsOnPlan = {};
        for (let i = 0; i < this.filteredTickets.length; i += 1) {
            if (this.filteredTickets[i].planId != null) {
                const id = this.filteredTickets[i].planId;
                if (ticketsOnPlan[id] == null) {
                    ticketsOnPlan[id] = 1;
                }
                else {
                    ticketsOnPlan[id] += 1;
                }
            }
        }
        return { visibleTickets, visiblePlans, ticketsOnPlan };
    }
    async getVisibleTickets(planId, ticketFilter) {
        let filtered = await this.filterTickets(this.tickets, ticketFilter);
        let t = await this.getVisibleTicketsWithoutFilter(filtered, planId);
        let { visiblePlans } = t;
        visiblePlans = await PlanFilter.filterPlans(visiblePlans, this.planFilter);
        if (PlanFilter.isSet(this.planFilter)) {
            const planIds = [];
            visiblePlans.forEach((p) => planIds.push(p.id));
            const f = [];
            filtered.forEach((t) => {
                if (t.planId != null && planIds.includes(t.planId))
                    f.push(t);
            });
            filtered = [...f];
            this.filteredTickets = filtered;
            t = await this.getVisibleTicketsWithoutFilter(filtered, planId);
        }
        const { visibleTickets, ticketsOnPlan } = t;
        if (this.isFilter(ticketFilter)) {
            const visiblePlans2 = [];
            for (let i = 0; i < visiblePlans.length; i += 1) {
                const ticketsOnThisPlan = ticketsOnPlan[visiblePlans[i].id] != null ? ticketsOnPlan[visiblePlans[i].id] : 0;
                if (ticketsOnThisPlan > 0) {
                    visiblePlans2.push(visiblePlans[i]);
                }
            }
            visiblePlans = visiblePlans2;
        }
        const { searchTicketsWords, sortBy, sortDirection } = this.state;
        const sortedTickets = searchTicketsWords.length > 0 || sortBy.length === 0 || sortBy === 'undefined'
            ? visibleTickets
            : this.sortTickets(visibleTickets, sortBy, sortDirection ?? '0');
        return { visiblePlans, ticketsOnPlan, visibleTickets: sortedTickets };
    }
    sortTickets(visibleTickets, sortBy, direction) {
        let sortedTickets = [];
        if (sortBy === 'title')
            return orderBy(visibleTickets, [sortBy], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'number')
            return orderBy(visibleTickets, ['ticketNumber'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'type')
            return orderBy(visibleTickets, ['type'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'craft')
            return orderBy(visibleTickets, ['craft'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'state')
            return orderBy(visibleTickets, ['ticketStatus'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'due')
            return orderBy(visibleTickets, ['completionOn'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'edit')
            return orderBy(visibleTickets, ['lastModifiedAt'], [direction === '0' ? 'asc' : 'desc']);
        if (sortBy === 'assignee')
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.assigneeUser == null ? '' : `${a.assigneeUser.firstname} ${a.assigneeUser.lastname}`, b.assigneeUser == null ? '' : `${b.assigneeUser.firstname} ${b.assigneeUser.lastname}`);
            });
        else if (sortBy === 'approver') {
            sortedTickets = visibleTickets.sort((a, b) => {
                return this.sortByString(a.approverUser == null ? '' : `${a.approverUser.firstname} ${a.approverUser.lastname}`, b.approverUser == null ? '' : `${b.approverUser.firstname} ${b.approverUser.lastname}`);
            });
        }
        else {
            let field;
            for (let i = 0; i < visibleTickets.length && !field; i += 1) {
                const cTicket = visibleTickets[i];
                if (cTicket.fields != null) {
                    field = cTicket.fields.find((cField) => cField.name === sortBy);
                }
            }
            if (field != null) {
                if (field.type === 'number' || field.type === 'progress') {
                    sortedTickets = visibleTickets.sort((a, b) => {
                        return this.sortByCustomFieldNumber(a, b, field?.name);
                    });
                }
                else if (field.type === 'Date') {
                    sortedTickets = visibleTickets.sort((a, b) => {
                        return this.sortByCustomDate(a, b, field?.name);
                    });
                }
                else if (field.type === 'string') {
                    sortedTickets = visibleTickets.sort((a, b) => {
                        return this.sortByCustomString(a, b, field?.name);
                    });
                }
                else if (field.type === 'person') {
                    sortedTickets = visibleTickets.sort((a, b) => {
                        return this.sortByCustomPerson(a, b, field?.name);
                    });
                }
                else if (field.type === 'List') {
                    sortedTickets = visibleTickets.sort((a, b) => {
                        return this.sortByCustomString(a, b, field?.name);
                    });
                }
                else {
                    sortedTickets = visibleTickets;
                }
            }
            else {
                sortedTickets = visibleTickets;
            }
        }
        if (direction === '1') {
            sortedTickets.reverse();
        }
        return sortedTickets;
    }
    async updateVisibleTickets() {
        const { ticketFilter, selectedPlanId } = this.state;
        const t = await this.getVisibleTickets(selectedPlanId, ticketFilter);
        TicketNavigation.setTickets(t.visibleTickets);
        this.setState(t);
    }
    sortByString(a, b) {
        if (a == null && b == null)
            return 0;
        if (a == null)
            return 1;
        if (b == null)
            return -1;
        const x = a.toLowerCase();
        const y = b.toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    }
    sortByDate(a, b) {
        if (a == null && b == null)
            return 0;
        if (a == null)
            return 1;
        if (b == null)
            return -1;
        return a.getTime() - b.getTime();
    }
    sortByTicketNumber(a, b) {
        return a.ticketNumber - b.ticketNumber;
    }
    sortByCustomFieldNumber(a, b, field) {
        const aValue = a.fields?.find((ae) => ae.name === field)?.value ?? Number.MIN_VALUE;
        const bValue = b.fields?.find((be) => be.name === field)?.value ?? Number.MIN_VALUE;
        return aValue - bValue;
    }
    sortByCustomDate(a, b, field) {
        const aValue = (a.fields?.find((ae) => ae.name === field)?.value ?? { date: new Date(0) }).date;
        const bValue = (b.fields?.find((be) => be.name === field)?.value ?? { date: new Date(0) }).date;
        return aValue.getTime() - bValue.getTime();
    }
    sortByCustomString(a, b, field) {
        const aValue = a.fields?.find((ae) => ae.name === field)?.value ?? '';
        const bValue = b.fields?.find((be) => be.name === field)?.value ?? '';
        return this.sortByString(aValue, bValue);
    }
    sortByCustomPerson(a, b, field) {
        const aValue = a.fields?.find((ae) => ae.name === field)?.formattedValue ?? '';
        const bValue = b.fields?.find((be) => be.name === field)?.formattedValue ?? '';
        return this.sortByString(aValue, bValue);
    }
}
TicketsView.defaultProps = {
    title: 'upmesh',
};
