var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LightboxExifContentView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AddressEntity = require("upmesh-auth-core/build/src/client/query/entities/AddressEntity");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _ListItem = require("../ListItem");
var _MaterialText = require("../text/MaterialText");
var _MapMarker = require("../zoom/MapMarker");
var _ZoomTile = require("../zoom/ZoomTile");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var bytes = require('bytes');
var LightboxExifContentView = exports.LightboxExifContentView = function (_PureComponent) {
  function LightboxExifContentView(props) {
    var _this;
    (0, _classCallCheck2.default)(this, LightboxExifContentView);
    _this = _callSuper(this, LightboxExifContentView, [props]);
    _this.openUrl = function (url) {
      return function (_e) {
        _reactNativeWeb.Linking.openURL(url).catch(function (err) {
          return void 0;
        });
      };
    };
    _this.state = {};
    return _this;
  }
  (0, _inherits2.default)(LightboxExifContentView, _PureComponent);
  return (0, _createClass2.default)(LightboxExifContentView, [{
    key: "renderDate",
    value: function renderDate() {
      var metadata = this.props.metadata;
      if (metadata == null || metadata.exif == null || metadata.exif.DateTimeOriginal == null) return null;
      try {
        var date = _I18n.I18n.m.dateCurrent.exifDateFromStringToDate(metadata.exif.DateTimeOriginal);
        return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          title: _I18n.I18n.m.dateCurrent.localeDateFormat(date, 'LL'),
          secondTextLine: `${_I18n.I18n.m.dateCurrent.localeDateFormat(date, 'dddd')}, ${_I18n.I18n.m.dateCurrent.localeDateFormat(date, 'LT')}`,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: "",
              icon: "calendar-today"
            }),
            width: 40
          }
        });
      } catch (e) {
        return null;
      }
    }
  }, {
    key: "renderFileData",
    value: function renderFileData() {
      var metadata = this.props.metadata;
      if (metadata == null || metadata.image == null || metadata.image.ImageOrgFilename == null) return null;
      var secondTextLine = [];
      if (metadata.image.ImageWidth != null && metadata.image.ImageHeight != null) {
        secondTextLine.push(`${Math.round(metadata.image.ImageWidth * metadata.image.ImageHeight / 10000) / 100} MP`);
        secondTextLine.push(`${metadata.image.ImageWidth} x ${metadata.image.ImageHeight}`);
      }
      if (metadata.image.ImageFileSize != null) {
        secondTextLine.push(`${bytes(metadata.image.ImageFileSize)}`);
      }
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
        title: metadata.image.ImageOrgFilename,
        secondTextLine: secondTextLine.length > 0 ? secondTextLine.join(' - ') : undefined,
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "image-outline"
          }),
          width: 40
        }
      });
    }
  }, {
    key: "renderCamData",
    value: function renderCamData() {
      var _metadata$exif, _metadata$exif6, _metadata$exif8, _metadata$exif9, _metadata$exif11;
      var metadata = this.props.metadata;
      if (metadata == null || metadata.image == null || metadata.image.Make == null) {
        return null;
      }
      var secondTextLine = [];
      if (((_metadata$exif = metadata.exif) == null ? void 0 : _metadata$exif.LensMake) != null) {
        var _metadata$exif2;
        if (((_metadata$exif2 = metadata.exif) == null ? void 0 : _metadata$exif2.LensModel) != null) {
          var _metadata$exif3, _metadata$exif4;
          secondTextLine.push(`${(_metadata$exif3 = metadata.exif) == null ? void 0 : _metadata$exif3.LensMake} ${(_metadata$exif4 = metadata.exif) == null ? void 0 : _metadata$exif4.LensModel}`);
        } else {
          var _metadata$exif5;
          secondTextLine.push((_metadata$exif5 = metadata.exif) == null ? void 0 : _metadata$exif5.LensMake);
        }
      }
      if (((_metadata$exif6 = metadata.exif) == null ? void 0 : _metadata$exif6.FNumber) != null) {
        var _metadata$exif7;
        secondTextLine.push(`f/${(_metadata$exif7 = metadata.exif) == null ? void 0 : _metadata$exif7.FNumber}`);
      }
      if (((_metadata$exif8 = metadata.exif) == null ? void 0 : _metadata$exif8.ExposureTime) != null) {
        secondTextLine.push(`1/${Math.round(1 / metadata.exif.ExposureTime)}s`);
      }
      if (((_metadata$exif9 = metadata.exif) == null ? void 0 : _metadata$exif9.FocalLength) != null) {
        var _metadata$exif10;
        secondTextLine.push(`${(_metadata$exif10 = metadata.exif) == null ? void 0 : _metadata$exif10.FocalLength}mm`);
      }
      if (((_metadata$exif11 = metadata.exif) == null ? void 0 : _metadata$exif11.ISO) != null) {
        var _metadata$exif12;
        secondTextLine.push(`ISO ${(_metadata$exif12 = metadata.exif) == null ? void 0 : _metadata$exif12.ISO}`);
      }
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
        title: `${metadata.image.Make} ${metadata.image.Model == null ? '' : metadata.image.Model}`,
        secondTextLine: secondTextLine.length > 0 ? secondTextLine.join(' - ') : undefined,
        thumbnail: {
          thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "camera-iris"
          }),
          width: 40
        }
      });
    }
  }, {
    key: "renderPlace",
    value: function renderPlace() {
      var metadata = this.props.metadata;
      if (metadata == null || metadata.gps == null) return null;
      var latitude = metadata.gps.GPSLatitude && metadata.gps.GPSLatitude.length === 3 ? (metadata.gps.GPSLatitude[2] / 60 + metadata.gps.GPSLatitude[1]) / 60 + metadata.gps.GPSLatitude[0] : metadata.gps.Latitude;
      var longitude = metadata.gps.GPSLongitude && metadata.gps.GPSLongitude.length === 3 ? (metadata.gps.GPSLongitude[2] / 60 + metadata.gps.GPSLongitude[1]) / 60 + metadata.gps.GPSLongitude[0] : metadata.gps.Longitude;
      if (!latitude || !longitude) return null;
      var address = new _AddressEntity.AddressEntity({
        latitude: latitude,
        longitude: longitude
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%'
        },
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            paddingLeft: 16
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Caption,
            color: _ThemeManager.ThemeManager.style.black54,
            children: _I18n.I18n.m.getMessage('lightBoxPlace')
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              height: 200,
              overflow: 'hidden'
            },
            children: (0, _jsxRuntime.jsx)(_ZoomTile.ZoomTile, {
              initialView: {
                point: {
                  x: longitude,
                  y: latitude
                },
                zoom: 16
              },
              type: "map",
              url: "",
              info: {
                dx: 0,
                dy: 0,
                height: 0,
                imageHeight: 0,
                imageWidth: 0,
                maxLevel: 19,
                orgImageHeight: 0,
                orgImageWidth: 0,
                width: 0
              },
              marker: [new _MapMarker.MapMarker(`marker_${address.bound.toString()}`, 'blue', {
                x: longitude,
                y: latitude
              })]
            }, `map_${address.bound.toString()}`)
          })]
        }), (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          title: metadata.gps.GPSText ? metadata.gps.GPSText : `${latitude}, ${longitude}`,
          secondTextLine: `${Math.round(latitude * 1000) / 1000}, ${Math.round(longitude * 1000) / 1000}`,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: "",
              icon: "directions",
              color: _ThemeManager.ThemeManager.style.brandPrimary,
              onPress: this.openUrl(`https://www.google.de/maps/dir//'${address.latitude},${address.longitude}'/@${address.latitude},${address.longitude},18z`)
            }),
            width: 40
          }
        })]
      });
    }
  }, {
    key: "renderCategory",
    value: function renderCategory() {
      var metadata = this.props.metadata;
      if (metadata && metadata.category && typeof metadata.category === 'string') {
        return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          title: metadata.category,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: "",
              icon: "comment-outline"
            }),
            width: 40
          }
        });
      }
      return null;
    }
  }, {
    key: "renderComment",
    value: function renderComment() {
      var metadata = this.props.metadata;
      if (metadata && metadata.comment && typeof metadata.comment === 'string') {
        return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          title: metadata.comment,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: "",
              icon: "comment-outline"
            }),
            width: 40
          }
        });
      }
      return null;
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          backgroundColor: '#FFFFFF',
          maxWidth: 750,
          width: '100%',
          borderTopRightRadius: _ThemeManager.ThemeManager.style.borderRadius,
          borderTopLeftRadius: _ThemeManager.ThemeManager.style.borderRadius,
          padding: 16
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.Caption,
          color: _ThemeManager.ThemeManager.style.black54,
          children: _I18n.I18n.m.getMessage('lightBoxDetails')
        }), this.renderDate(), this.renderFileData(), this.renderCamData(), this.renderPlace(), this.renderCategory(), this.renderComment()]
      });
    }
  }]);
}(_react.PureComponent);