var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutoCompleteWrapper = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../../ThemeManager");
var _ScaleAnimation = require("../../animations/ScaleAnimation");
var _GridList = require("../../GridList");
var _ListItem = require("../../ListItem");
var _jsxRuntime = require("../../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var AutoCompleteWrapper = exports.AutoCompleteWrapper = function (_PureComponent) {
  function AutoCompleteWrapper(props) {
    var _this;
    (0, _classCallCheck2.default)(this, AutoCompleteWrapper);
    _this = _callSuper(this, AutoCompleteWrapper, [props]);
    _this.calculatePosition = function (a) {
      var y = a.y,
        listData = a.listData;
      var size = _this.props.size;
      var fIHeight = a.fiHeight != null ? a.fiHeight : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(56);
      var top = y;
      var listDataHeight = listData == null ? 0 : listData.length * _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48);
      var autoCompleteHeight = Math.min(listDataHeight, a.height);
      var heightDown = Math.min(autoCompleteHeight, size.contentHeight - top - 8 - size.currentKeyboardHeight);
      var heightUp = Math.min(autoCompleteHeight, y - fIHeight - 8 - size.contentTop);
      var height = heightDown;
      if (_reactNativeWeb.Platform.OS === 'web' && heightUp > heightDown || _reactNativeWeb.Platform.OS !== 'web' && heightUp >= heightDown) {
        top = Math.max(8, y - fIHeight - autoCompleteHeight);
        height = heightUp;
      }
      return {
        height: height,
        top: top,
        listDataHeight: listDataHeight
      };
    };
    _this.keyboardDidHide = function () {
      _this.animation.toValue(0);
    };
    _this.keyboardDidShow = function () {};
    _this.renderAutoComplete = function (data) {
      var item = data.item;
      if (typeof item === 'string') {
        return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          selected: data.selectedIndex === data.index,
          title: item,
          onPress: _this.onPressAutoComplete(item),
          backgroundColor: "#ffffff"
        });
      }
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({}, item, {
        selected: data.selectedIndex === data.index,
        onPressText: _this.onPressAutoComplete(item)
      }));
    };
    _this.onPressAutoComplete = function (item) {
      return function () {
        var onPressAutoComplete = _this.props.a.onPressAutoComplete;
        if (onPressAutoComplete != null) {
          if (typeof item === 'string') {
            onPressAutoComplete(item)();
          } else {
            onPressAutoComplete(item.title)();
          }
        }
        _this.animation.toValue(0);
      };
    };
    _this.onSubmit = function (item, _selectedIndex) {
      _this.onPressAutoComplete(item)();
    };
    _this.animation = new _ScaleAnimation.ScaleAnimation(0, 195, 'y');
    return _this;
  }
  (0, _inherits2.default)(AutoCompleteWrapper, _PureComponent);
  return (0, _createClass2.default)(AutoCompleteWrapper, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.keyboardDidShowListener = _reactNativeWeb.Keyboard.addListener('keyboardDidShow', this.keyboardDidShow);
      this.keyboardDidHideListener = _reactNativeWeb.Keyboard.addListener('keyboardDidHide', this.keyboardDidHide);
      this.animation.toValue(1);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.keyboardDidShowListener != null) {
        this.keyboardDidShowListener.remove();
        this.keyboardDidShowListener = undefined;
      }
      if (this.keyboardDidShowListener != null) {
        this.keyboardDidHideListener.remove();
        this.keyboardDidHideListener = undefined;
      }
      this.animation.toValue(0);
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate() {}
  }, {
    key: "render",
    value: function render() {
      var _this$props$a = this.props.a,
        x = _this$props$a.x,
        width = _this$props$a.width,
        id = _this$props$a.id;
      var _this$calculatePositi = this.calculatePosition(this.props.a),
        top = _this$calculatePositi.top,
        height = _this$calculatePositi.height;
      var style = Object.assign({
        width: width,
        height: height,
        top: top,
        left: x,
        zIndex: 102,
        elevation: 24,
        borderStyle: 'solid',
        borderWidth: _ThemeManager.ThemeManager.style.borderWidth,
        borderColor: _ThemeManager.ThemeManager.style.borderColor,
        backgroundColor: '#FFFFFF'
      }, this.animation.createAnimations());
      var completekey = 'autoComplete';
      var listData = this.props.a.listData;
      if (listData == null) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        style: style,
        children: (0, _jsxRuntime.jsx)(_GridList.GridList, {
          selectable: true,
          selectedIndex: -1,
          style: {
            height: height - 2,
            width: width - 2,
            zIndex: 102
          },
          id: `${completekey}_${listData.length}`,
          listData: listData,
          renderRow: this.renderAutoComplete,
          onSubmit: this.onSubmit,
          itemSize: {
            width: width,
            height: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(48)
          },
          direction: "vertical",
          scrollBarVisible: false
        }, `${completekey}_${listData.length}`)
      }, id);
    }
  }]);
}(_react.PureComponent);