import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import React, { useEffect, useState } from 'react';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { openAddOrChangeProjectTicketLayoutDialog } from './AddOrChangeProjectTicketLayoutDialog';
export function CopyProjectTicketLayoutDialog(props) {
    const [layouts, setLayouts] = useState();
    useEffect(() => {
        if (props.companySettings) {
            const l = [];
            props.companySettings.ticketLayouts?.forEach((t) => {
                l.push({ layoutTitle: t.title, layout: t });
            });
            setLayouts(l);
        }
        else {
            UpmeshClient.instance.modals.project
                .get()
                .then((projects) => {
                const l = [];
                projects.forEach((p) => {
                    p.ticketLayouts?.forEach((t) => {
                        l.push({ projectTitle: p.title, layoutTitle: t.title, layout: t });
                    });
                });
                setLayouts(l);
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        }
    }, []);
    const selectLayout = (l) => (_e) => {
        l.onTypes = [];
        l.id = uuid.v1();
        openAddOrChangeProjectTicketLayoutDialog({ project: props.project, copiedLayout: l })(_e);
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('ticketLayoutCopyFromProjectTitle')}</DialogTitle>
      <DialogContent>
        {layouts &&
            layouts.map((m) => {
                return <ListItem title={m.layoutTitle} secondTextLine={m.projectTitle} onPress={selectLayout(m.layout)}/>;
            })}
      </DialogContent>
    </>);
}
export const openCopyProjectTicketLayoutDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        showCloseIcon: true,
        fullscreenResponsive: true,
        scrollable: true,
        contentPadding: false,
        content: <CopyProjectTicketLayoutDialog {...props}/>,
    });
};
