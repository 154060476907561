var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LeftImageOrTopLogoResponsive = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AccountConfig = require("../../../AccountConfig");
var _connectionContext = _interopRequireDefault(require("../../../connectionContext"));
var _SimpleReactNativeStorage = require("../../../odataDB/SimpleReactNativeStorage");
var _ScrollContainer = require("../../../theme/components/scroll/ScrollContainer");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _Ripple = require("../../../theme/components/utils/Ripple");
var _I18n = require("../../../theme/i18n/I18n");
var _ResizeEvent = require("../../../theme/ResizeEvent");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _DefaultHeaderContent = require("../../../views/components/DefaultHeaderContent");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LeftImageOrTopLogoResponsive = exports.LeftImageOrTopLogoResponsive = function (_PureComponent) {
  function LeftImageOrTopLogoResponsive(props) {
    var _this;
    (0, _classCallCheck2.default)(this, LeftImageOrTopLogoResponsive);
    _this = _callSuper(this, LeftImageOrTopLogoResponsive, [props]);
    _SimpleStorage.SimpleStorage.init(new _SimpleReactNativeStorage.SimpleReactNativeStorage());
    var storedCompanyLogo = _SimpleStorage.SimpleStorage.get('companyLogo');
    _this.state = {
      company: undefined,
      companyId: _this.props.companyId,
      config: _this.props.config,
      companyImage: storedCompanyLogo || undefined
    };
    return _this;
  }
  (0, _inherits2.default)(LeftImageOrTopLogoResponsive, _PureComponent);
  return (0, _createClass2.default)(LeftImageOrTopLogoResponsive, [{
    key: "fetchCompanyData",
    value: function () {
      var _fetchCompanyData = (0, _asyncToGenerator2.default)(function* () {
        try {
          var response = yield fetch(`${this.state.config}/company/${this.state.companyId}/public-logo`);
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error('Company not found');
            } else {
              throw new Error('response was not ok');
            }
          }
          var company = yield response.json();
          this.setState({
            company: company
          });
        } catch (error) {}
      });
      function fetchCompanyData() {
        return _fetchCompanyData.apply(this, arguments);
      }
      return fetchCompanyData;
    }()
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.companyId) {
        this.fetchCompanyData().catch(function (err) {});
      }
    }
  }, {
    key: "renderChildren",
    value: function renderChildren() {
      var children = this.props.children;
      var _this$context = this.context,
        offline = _this$context.offline,
        connectedToServer = _this$context.connectedToServer;
      if (offline || !connectedToServer) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            maxWidth: 512,
            alignSelf: 'center',
            padding: _ThemeManager.ThemeManager.style.contentPaddingValue
          },
          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            centeredBox: true,
            children: offline ? _I18n.I18n.m.getMessage('onlineOnly') : _I18n.I18n.m.getMessage('serverNotReachable')
          })
        });
      }
      return children;
    }
  }, {
    key: "renderHeader",
    value: function renderHeader() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%'
        },
        children: (0, _jsxRuntime.jsx)(_DefaultHeaderContent.DefaultHeaderContent, {})
      });
    }
  }, {
    key: "renderImage",
    value: function renderImage() {
      var size = this.props.size;
      var topPadding = this.props.topPadding || _ResizeEvent.ResizeEvent.current.contentTop;
      var _this$state = this.state,
        company = _this$state.company,
        companyId = _this$state.companyId,
        companyImage = _this$state.companyImage;
      var source = _AccountConfig.AccountConfig.config.logoWhite;
      if (companyId && company != null && company.logo) {
        source = {
          uri: `${this.state.config}/company/${companyId}/logo/${company.logo}`
        };
      } else if (companyId && !(company != null && company.logo)) {
        source = _AccountConfig.AccountConfig.config.logoWhite;
      } else if (!companyId && companyImage) {
        source = {
          uri: companyImage
        };
      } else {
        source = _AccountConfig.AccountConfig.config.logoWhite;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          height: size.contentHeight,
          top: topPadding,
          width: '100%',
          position: 'relative',
          backgroundColor: 'white'
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            height: size.contentHeight,
            top: 0,
            width: '100%',
            position: 'absolute',
            backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
            style: {
              height: size.contentHeight,
              position: 'absolute',
              width: '100%',
              opacity: 0.27
            },
            resizeMode: "cover",
            source: _AccountConfig.AccountConfig.config.bgImage
          }), (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            style: {
              position: 'absolute',
              height: '15%',
              width: '84%',
              alignSelf: 'center',
              top: '5%',
              maxWidth: 256,
              maxHeight: 256
            },
            onPress: _Routing.Routing.instance.goToButton('/'),
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
              style: {
                height: '98%',
                width: '98%'
              },
              resizeMode: "contain",
              source: source
            })
          })]
        })
      });
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var _this$state2 = this.state,
        company = _this$state2.company,
        companyId = _this$state2.companyId,
        companyImage = _this$state2.companyImage;
      var source = _AccountConfig.AccountConfig.config.logoWhite;
      if (companyId && company != null && company.logo) {
        source = {
          uri: `${this.state.config}/company/${companyId}/logo/${company.logo}`
        };
      } else if (companyId && !(company != null && company.logo)) {
        source = _AccountConfig.AccountConfig.config.logoBlack;
      } else if (!companyId && companyImage) {
        source = {
          uri: companyImage
        };
      } else {
        source = _AccountConfig.AccountConfig.config.logoBlack;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'relative',
          width: '100%',
          flexDirection: 'row',
          height: size.contentHeight
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 64,
            position: 'absolute',
            right: _ResizeEvent.ResizeEvent.current.contentRight,
            width: '100%',
            top: _ResizeEvent.ResizeEvent.current.contentTop,
            backgroundColor: '#FFFFFF'
          },
          children: this.renderHeader()
        }), size.contentWidth > _ThemeManager.ThemeManager.style.breakpointM && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '50%'
          },
          children: this.renderImage()
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            top: 64,
            width: size.contentWidth > _ThemeManager.ThemeManager.style.breakpointM ? '50%' : '100%'
          },
          children: (0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, {
            style: {
              height: size.contentHeight - 64,
              width: '100%',
              backgroundColor: '#FFFFFF'
            },
            contentContainerStyle: {
              minHeight: size.contentHeight - 64,
              backgroundColor: '#FFFFFF',
              justifyContent: size.contentWidth > _ThemeManager.ThemeManager.style.breakpointM ? 'center' : 'flex-start'
            },
            children: [size.contentWidth <= _ThemeManager.ThemeManager.style.breakpointM && (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
              style: {
                width: 480,
                height: 96,
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '90%'
              },
              onPress: _Routing.Routing.instance.goToButton('/'),
              children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
                source: source,
                style: {
                  width: 480,
                  height: 64,
                  maxWidth: '90%',
                  alignSelf: 'center'
                },
                resizeMode: "contain"
              })
            }), this.renderChildren()]
          })
        })]
      });
    }
  }]);
}(_react.PureComponent);
LeftImageOrTopLogoResponsive.contextType = _connectionContext.default;