import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import DraggableFlatList from 'materialTheme/src/theme/components/DraggableFlatList';
import { Draggable } from 'materialTheme/src/theme/components/dragndrop/Dragable';
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyTicketLayout } from 'upmesh-core/src/client/commands/companies/ticketlayouts/AddCompanyTicketLayout';
import { ChangeCompanyTicketLayout } from 'upmesh-core/src/client/commands/companies/ticketlayouts/ChangeCompanyTicketLayout';
import { AddProjectTicketLayout } from 'upmesh-core/src/client/commands/project/layouts/AddProjectTicketLayout';
import { ChangeProjectTicketLayout } from 'upmesh-core/src/client/commands/project/layouts/ChangeProjectTicketLayout';
import { CustomFieldListOptions, LayoutFields, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { CurrentProject } from '../../CurrentProject';
import { TicketLayoutCheckboxListFieldOptions } from './TicketLayoutCheckboxListOptions';
import { TicketLayoutDateFieldOptions } from './TicketLayoutDateOptions';
import { TicketLayoutDateRangeFieldOptions } from './TicketLayoutDateRangeOptions';
import { TicketLayoutDividerOptions } from './TicketLayoutDividerOptions';
import { TicketLayoutDueDateOptions } from './TicketLayoutDueDateOptions';
import { TicketLayoutListFieldOptions } from './TicketLayoutListOptions';
import { TicketLayoutMultiPersonFieldOptions } from './TicketLayoutMultiPersonFieldOptions';
import { TicketLayoutMultiselectListFieldOptions } from './TicketLayoutMultiselectListOptions';
import { TicketLayoutNumericFieldOptions } from './TicketLayoutNumericFieldOptions';
import { TicketLayoutPersonFieldOptions } from './TicketLayoutPersonFieldOptions';
import { TicketLayoutProgressOptions } from './TicketLayoutProgressOptions';
import { TicketLayoutStringFieldOptions } from './TicketLayoutStringFieldOptions';
export function AddOrChangeProjectTicketLayoutDialog(props) {
    const [currentLayout, setCurrentLayout] = useState(props.layout
        ? new TicketLayoutsEntity(props.layout)
        : props.copiedLayout
            ? new TicketLayoutsEntity(props.copiedLayout)
            : new TicketLayoutsEntity());
    const [highlightDropArea, setHighlightDropArea] = useState({});
    const [formError, setFormError] = useState(new Map());
    const [loading, setLoading] = useState(false);
    const ruleChildHandler = (item) => () => {
        MenuRaw.instance?.close();
        if (item?.customField) {
            const options = item.customField.options;
            if (!options.children) {
                options.children = [];
            }
            options.children.push({ id: uuid.v1(), onSelectedRule: [], fields: [] });
        }
        setCurrentLayout(new TicketLayoutsEntity(currentLayout));
    };
    const sView = props.size != null && props.size.windowWidth <= ThemeManager.style.breakpointM;
    const parentListOptions = (item) => {
        const chips = [];
        const optionsList = new CustomFieldListOptions(item?.customField?.options).list;
        if (optionsList && optionsList.length > 0) {
            for (let i = 0; i < optionsList?.length; i += 1) {
                chips.push({
                    title: optionsList[i],
                    id: optionsList[i],
                });
            }
        }
        return chips;
    };
    const onSaveField = (parentId = '', ruleSetId = '') => (field) => {
        const fields = [];
        let updated = false;
        if (parentId === '') {
            for (const f of currentLayout.fields) {
                if (f.id !== field.id)
                    fields.push(f);
                else {
                    updated = true;
                    fields.push(field);
                }
            }
            if (!updated) {
                fields.push(field);
            }
            setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields }));
        }
        else {
            const parentField = currentLayout.fields.find((element) => element.id === parentId);
            if (parentField?.customField) {
                const options = parentField.customField.options;
                if (!options.children) {
                    options.children = [];
                }
                const child = options.children.find((child) => child.id === ruleSetId);
                if (child && !child.fields) {
                    child.fields = [];
                }
                const children = [];
                if (child) {
                    child.fields.forEach((f) => {
                        if (f.id !== field.id)
                            children.push(f);
                        else {
                            updated = true;
                            children.push(field);
                        }
                    });
                    if (!updated) {
                        children.push(field);
                    }
                    child.fields = children;
                }
                setCurrentLayout(new TicketLayoutsEntity(currentLayout));
            }
        }
    };
    const openCompletionOnEdit = (field) => (e) => {
        MenuRaw.instance?.close();
        const openPosition = e == null ? undefined : { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        DialogBetween.instance?.open({
            openPosition,
            content: <TicketLayoutDueDateOptions currentFields={currentLayout.fields} field={field} onSave={onSaveField()}/>,
            contentPadding: false,
            fullscreenResponsive: true,
            showCloseIcon: false,
            closeOnTouchOutside: false,
        });
    };
    const openEdit = (field, newField = false, parentId, ruleSetId) => (e) => {
        MenuRaw.instance?.close();
        const openPosition = e == null ? undefined : { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        let content;
        if (field.customField) {
            const approverCanVisible = currentLayout.fields.find((a) => a.systemField === 'approver') != null;
            const assigneeCanEditVisible = currentLayout.fields.find((a) => a.systemField === 'assignee') != null;
            if (field.customField.type === 'number') {
                content = (<TicketLayoutNumericFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'string') {
                content = (<TicketLayoutStringFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'Date') {
                content = (<TicketLayoutDateFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'DateRange') {
                content = (<TicketLayoutDateRangeFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'List') {
                content = (<TicketLayoutListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'MultiselectList') {
                content = (<TicketLayoutMultiselectListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'CheckboxList') {
                content = (<TicketLayoutCheckboxListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'person') {
                content = (<TicketLayoutPersonFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'multiperson') {
                content = (<TicketLayoutMultiPersonFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'divider') {
                content = (<TicketLayoutDividerOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'progress') {
                content = (<TicketLayoutProgressOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField(parentId, ruleSetId)} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            if (content != null) {
                DialogBetween.instance?.open({
                    openPosition,
                    content,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    showCloseIcon: false,
                    closeOnTouchOutside: false,
                });
            }
        }
    };
    const addField = (p, parentId, ruleSetId) => {
        if (TicketLayoutsEntity.canBeAdded(p, currentLayout)) {
            if (p.customField != null) {
                openEdit(p, true, parentId, ruleSetId)(null);
            }
            else {
                const f = [...currentLayout.fields];
                f.push(p);
                setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields: f }));
            }
        }
    };
    const renderField = (title, type, parentId = '', ruleSetId = '') => {
        const field = {
            id: uuid.v1(),
            label: type.systemField ? title : '',
            description: '',
            customField: type.customField,
            systemField: type.systemField,
        };
        const isPossible = TicketLayoutsEntity.canBeAdded(field, currentLayout);
        const icon = TicketLayoutsEntity.getIconName(field);
        const iconColor = field.customField != null ? ThemeManager.style.brandSuccess : ThemeManager.style.brandPrimary;
        const b = (<View style={{
                padding: 8,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                ...ThemeManager.noSelectionWebStyle(),
                flexDirection: 'row',
                alignItems: 'center',
            }}>
        <View style={{ width: 48 }}>
          <Icon icon={icon} toolTip="" color={iconColor} accessibilityLabel={title}/>
        </View>
        <View style={{ flex: 1 }}>
          <MaterialText>{title}</MaterialText>
        </View>
      </View>);
        if (sView) {
            return (<View style={{ paddingVertical: 4, width: '100%' }}>
          <Ripple disabled={!isPossible} backgroundColor="transparent" style={{ width: '100%', opacity: !isPossible ? 0.5 : 1 }} onPress={() => {
                    DialogBetween.instance?.close(() => {
                        addField(field, parentId, ruleSetId);
                    });
                }}>
            {b}
          </Ripple>
        </View>);
        }
        if (!isPossible) {
            return <View style={{ paddingVertical: 4, opacity: 0.5 }}>{b}</View>;
        }
        return (<View style={{ paddingVertical: 4 }}>
        <Draggable onStartDrag={() => console.log('start Drag')} dropData={field}>
          {b}
        </Draggable>
      </View>);
    };
    const removeField = (field, isChild, parentId, ruleSetId, isRule) => () => {
        Alert.instance?.close();
        const fields = [];
        currentLayout.fields.forEach((f) => {
            if (isChild) {
                const parent = currentLayout.fields.find((element) => element.id === parentId);
                if (parent?.customField) {
                    const options = parent.customField.options;
                    if (!options.children) {
                        options.children = [];
                    }
                    if (isRule) {
                        const children = [];
                        options.children.forEach((child) => {
                            if (child.id !== ruleSetId)
                                children.push(child);
                        });
                        options.children = children;
                    }
                    else {
                        const child = options.children.find((child) => child.id === ruleSetId);
                        if (child) {
                            const children = [];
                            child.fields.forEach((f) => {
                                if (f.id !== field.id) {
                                    children.push(f);
                                }
                            });
                            child.fields = children;
                        }
                    }
                    setCurrentLayout(new TicketLayoutsEntity(currentLayout));
                }
            }
            else {
                if (f.id !== field.id)
                    fields.push(f);
                setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields }));
            }
        });
    };
    const renderFields = (parentId = '', ruleSetId = '') => {
        const showSystemFields = parentId === '';
        return (<ScrollView style={{ width: '100%', height: '100%' }}>
        {showSystemFields && (<View collapsable={false} style={{
                    width: '100%',
                    padding: 16,
                    ...ThemeManager.noSelectionWebStyle(),
                }}>
            <View style={{ paddingBottom: 4, width: '100%' }}>
              <MaterialText>{I18n.m.getMessage('systemFields')}</MaterialText>
            </View>
            {renderField(I18n.m.getMessage('ticketsDetailsState'), { systemField: 'status' })}
            {renderField(I18n.m.getMessage('ticketsDetailsDescription'), { systemField: 'description' })}
            {renderField(I18n.m.getMessage('ticketsDetailsPlan'), { systemField: 'plan' })}
            {renderField(I18n.m.getMessage('ticketDetailsDueDate'), { systemField: 'completionOn' })}
            {renderField(I18n.m.getMessage('ticketsDetailsTitleAssignedToUser'), {
                    systemField: 'assignee',
                })}
            {renderField(I18n.m.getMessage('ticketsDetailsTitleApprover'), { systemField: 'approver' })}
            {renderField(I18n.m.getMessage('ticketsDetailsTags'), { systemField: 'tags' })}
            {renderField(I18n.m.getMessage('ticketsDetailsCraft'), { systemField: 'craft' })}
          </View>)}
        <View collapsable={false} style={{ width: '100%', padding: 16, ...ThemeManager.noSelectionWebStyle() }}>
          <View style={{ paddingBottom: 4, width: '100%' }}>
            <MaterialText>{I18n.m.getMessage('customFields')}</MaterialText>
          </View>
          {renderField(I18n.m.getMessage('customFieldNumber'), {
                customField: { type: 'number', options: { delimiter: 0, dezimals: 0 } },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldText'), {
                customField: { type: 'string', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldDate'), {
                customField: { type: 'Date', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldDateRange'), {
                customField: { type: 'DateRange', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldList'), {
                customField: { type: 'List', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldMultiselectList'), {
                customField: { type: 'MultiselectList', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldCheckboxList'), {
                customField: { type: 'CheckboxList', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldPerson'), {
                customField: { type: 'person', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldMultiPerson'), {
                customField: { type: 'multiperson', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldDivider'), {
                customField: { type: 'divider', options: {} },
            }, parentId, ruleSetId)}
          {renderField(I18n.m.getMessage('customFieldProgress'), {
                customField: { type: 'progress', options: {} },
            }, parentId, ruleSetId)}
        </View>
      </ScrollView>);
    };
    const renderDropAreaOrButton = (parentId = '', ruleSetId = '') => {
        const toggleHighlight = (active, ruleSetId) => {
            setHighlightDropArea((prev) => ({ ...prev, [ruleSetId]: active }));
        };
        if (sView) {
            return (<ContainedButton full backgroundColor={ruleSetId ? '#F6F6F8' : 'white'} title="Feld hinzufügen" onPress={(e) => {
                    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
                    DialogBetween.instance?.open({
                        openPosition,
                        content: <View>{renderFields(parentId, ruleSetId)}</View>,
                        closeOnTouchOutside: false,
                        contentPadding: false,
                        scrollable: true,
                        fullscreenResponsive: true,
                        showCloseIcon: true,
                    });
                }} icon={{ icon: 'plus' }} textColor={ThemeManager.style.brandPrimary} borderStyle="dashed" outlineColor={ThemeManager.style.borderColor}/>);
        }
        return (<DropArea dropFilter={() => true} onDrop={async (field) => {
                if (parentId !== '' && field?.systemField) {
                    return;
                }
                addField(field, parentId, ruleSetId);
            }} toggleHighlight={(active) => toggleHighlight(active, ruleSetId)}>
        <View accessibilityLabel="DropArea" style={{
                width: '100%',
                height: 48,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                borderStyle: 'dashed',
                justifyContent: 'center',
                padding: 4,
                backgroundColor: highlightDropArea[ruleSetId]
                    ? ThemeManager.style.brandSuccess
                    : ruleSetId
                        ? '#F6F6F8'
                        : 'white',
            }}>
          <View style={{ padding: 4 }}>
            <MaterialText color={ThemeManager.style.black54}>{I18n.m.getMessage('dropNewFieldHere')}</MaterialText>
          </View>
        </View>
      </DropArea>);
    };
    const renderFieldOptions = ({ item, drag, isChild = false, parentId = '', ruleSetId = '' }) => {
        const iconColor = item.customField != null ? ThemeManager.style.brandSuccess : ThemeManager.style.brandPrimary;
        const icon = TicketLayoutsEntity.getIconName(item);
        const renderChildren = (fields, isChild, parentId, ruleSetId) => (<View>
        {fields.map((child) => (<View key={child.id}>{renderFieldOptions({ item: child, drag, isChild, parentId, ruleSetId })}</View>))}
      </View>);
        const b = (<View style={{
                padding: 8,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                ...ThemeManager.noSelectionWebStyle(),
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                backgroundColor: 'white',
            }}>
        <View style={{ width: 48 }}>
          <Icon icon={icon} toolTip="" color={iconColor}/>
        </View>
        <View style={{ flex: 1 }}>
          <MaterialText>
            {item.label}
            {item.customField &&
                item.customField.type !== 'divider' &&
                LayoutFields.getIdentifier(item) !== item.label &&
                ` (${LayoutFields.getIdentifier(item)})`}
          </MaterialText>
        </View>
        <View style={{ width: 40 }}>
          <Icon icon="dots-vertical" toolTip="" onPress={(e) => {
                const asyncNow = async () => {
                    let x = 0;
                    let y = 0;
                    try {
                        if (e.nativeEvent?.pageX != null && e.nativeEvent.pageY) {
                            x = e.nativeEvent.pageX;
                            y = e.nativeEvent.pageY;
                        }
                        else {
                            const s = await Measurement.measure(e.currentTarget);
                            x = s.pageX;
                            y = s.pageY;
                        }
                    }
                    catch (err) {
                        console.warn(err);
                    }
                    const client = {
                        height: 0,
                        width: 300,
                        x,
                        y,
                    };
                    const items = [];
                    if (item.systemField == null) {
                        items.push({
                            disabled: item.systemField != null,
                            thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                            title: I18n.m.getMessage('edit'),
                            onPress: item.systemField != null ? undefined : openEdit(item, false, parentId, ruleSetId),
                        });
                        if ((item.customField.type === 'List' || item.customField.type === 'MultiselectList') && !isChild) {
                            items.push({
                                disabled: item.systemField != null,
                                thumbnail: { thumbnail: <Icon icon="plus" toolTip=""/>, width: 40 },
                                title: I18n.m.getMessage('addNewCustomFieldRule'),
                                onPress: item.systemField != null ? undefined : ruleChildHandler(item),
                            });
                        }
                    }
                    else if (item.systemField === 'completionOn')
                        items.push({
                            disabled: item.systemField != null,
                            thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                            title: I18n.m.getMessage('edit'),
                            onPress: openCompletionOnEdit(item),
                        });
                    items.push({
                        thumbnail: { thumbnail: <Icon icon="delete-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('delete'),
                        onPress: () => {
                            Routing.instance.alert.post({
                                text: I18n.m.getMessage('ticketLayoutRemoveFieldQuestion', { title: item.label }),
                                buttons: [
                                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeField(item, isChild, parentId, ruleSetId, false)} backgroundColor={ThemeManager.style.brandDanger}/>,
                                ],
                            });
                            MenuRaw.instance?.close();
                        },
                    });
                    MenuRaw.instance?.open({
                        client,
                        items,
                    });
                };
                asyncNow().catch((err) => console.error(err));
            }}/>
        </View>
      </View>);
        const hasChildren = item.customField && item.customField.options.children && item.customField.options.children.length > 0;
        return (<View style={{
                paddingVertical: 4,
                marginVertical: hasChildren ? 4 : 0,
                padding: hasChildren ? 8 : 0,
                width: '100%',
                backgroundColor: isChild ? '#F6F6F8' : hasChildren ? '#F6F6F8' : 'white',
                borderRadius: ThemeManager.style.borderRadius,
            }}>
        <TouchableOpacity onPressIn={drag} style={{
                width: '100%',
                height: 56,
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: isChild ? ThemeManager.style.contentPaddingValue : 0,
            }}>
          {b}
        </TouchableOpacity>
        {hasChildren && (<View>
            {item?.customField?.options?.children.map((ruleSet) => (<React.Fragment key={ruleSet.id}>
                <View style={{
                        padding: 8,
                        paddingHorizontal: 12,
                        width: '100%',
                        ...ThemeManager.style.layoutRuleBorderStyle,
                        borderRadius: ThemeManager.style.borderRadius,
                        ...ThemeManager.noSelectionWebStyle(),
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#CCE3FF',
                    }}>
                  <View style={{
                        flex: 2,
                        flexDirection: !sView ? 'row' : 'column',
                    }}>
                    <View style={{ maxWidth: '60%', paddingRight: 5, paddingVertical: 8 }}>
                      <MaterialText>{I18n.m.getMessage('showRuleFields')}</MaterialText>
                    </View>
                    <View style={{
                        flex: 1,
                    }}>
                      <ChipGroup chips={ruleSet.onSelectedRule.map((rule) => ({ title: rule, id: rule }))} key={`key:_${ruleSet.onSelectedRule.join(',')}`} bordered={false} disableAutoSort textColor="white" backgroundColor="#0073ff" availableChips={parentListOptions(item)} onChanged={(items) => {
                        const options = item.customField.options;
                        const selectedRule = items.map((items) => items.title);
                        const child = options.children?.find((child) => child.id === ruleSet.id);
                        if (child) {
                            child.onSelectedRule = selectedRule;
                        }
                        setCurrentLayout(new TicketLayoutsEntity(currentLayout));
                    }}/>
                    </View>
                  </View>
                  <View>
                    <ContainedButton iconPosition="left" icon={{ icon: 'close', color: ThemeManager.style.defaultIconColor }} onPress={() => {
                        Routing.instance.alert.post({
                            text: I18n.m.getMessage('deleteRuleMessage'),
                            buttons: [
                                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeField(ruleSet, true, item.id, ruleSet.id, true)} backgroundColor={ThemeManager.style.brandDanger}/>,
                            ],
                        });
                        MenuRaw.instance?.close();
                    }} full={false} backgroundColor="transparent"/>
                  </View>
                </View>
                <TouchableOpacity onPressIn={drag}>
                  {renderChildren(ruleSet.fields, true, item.id, ruleSet.id)}
                </TouchableOpacity>
                <View style={{
                        paddingVertical: 4,
                        width: '100%',
                        paddingLeft: ThemeManager.style.contentPaddingValue,
                    }}>
                  {renderDropAreaOrButton(item.id, ruleSet.id)}
                </View>
              </React.Fragment>))}
          </View>)}
      </View>);
    };
    const setOrder = (data) => {
        setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields: data }));
    };
    const renderForm = () => {
        return (<>
        <DialogTitle>
          {props.layout
                ? I18n.m.getMessage('ticketLayoutTitleChange', { title: props.layout.title })
                : I18n.m.getMessage('ticketLayoutTitleCreate')}
        </DialogTitle>
        <DialogContent>
          <FormInputFilled helperText={formError.has('title') ? formError.get('title') : ''} error={formError.has('title')} labelText="Name" initValue={currentLayout.title} onChange={(v) => {
                setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, title: v }));
            }}/>
          <MaterialText>{I18n.m.getMessage('defaultFields')}</MaterialText>
          <View style={{ paddingVertical: 4, width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Chip title={I18n.m.getMessage('ticketsDetailsTitle')}/>
            <Chip title={I18n.m.getMessage('ticketsDetailsType')}/>
          </View>
          <View style={{
                marginVertical: 8,
                height: 1,
                backgroundColor: ThemeManager.style.borderColor,
                width: '100%',
            }}/>
          <DraggableFlatList style={{ width: '100%', marginRight: Platform.OS !== 'web' ? 32 : 0 }} data={currentLayout.fields} renderItem={renderFieldOptions} keyExtractor={(item) => item.id} onDragEnd={({ data }) => setOrder(data)}/>
          <View style={{ paddingVertical: 4, width: '100%' }}>{renderDropAreaOrButton()}</View>
        </DialogContent>
      </>);
    };
    const renderContent = () => {
        const height = props.size
            ? props.size.contentHeight * 0.9 - 112 - props.size.currentKeyboardHeight
            : ResizeEvent.current.contentHeight - ResizeEvent.current.currentKeyboardHeight;
        if (sView)
            return renderForm();
        return (<View style={{
                height,
                flexDirection: 'row',
                overflow: 'visible',
                ...ThemeManager.noSelectionWebStyle(),
            }}>
        <View style={{
                flex: 1,
                height,
                backgroundColor: ThemeManager.style.appBgColor,
                ...ThemeManager.noSelectionWebStyle(),
            }}>
          {renderFields()}
        </View>
        <View style={{ flex: 1.618, height, ...ThemeManager.noSelectionWebStyle() }}>{renderForm()}</View>
      </View>);
    };
    const saveForm = async () => {
        const validateFields = () => {
            let isValid = true;
            let isMissingField = false;
            let isMissingValue = false;
            const missingFieldParents = new Set();
            const missingValueParents = new Set();
            currentLayout.fields.forEach((field) => {
                if (field?.customField) {
                    const options = field.customField.options;
                    const children = options?.children;
                    if (children && children.length > 0) {
                        children.forEach((child) => {
                            const hasFields = child.fields && child.fields.length > 0;
                            const hasSelectedRule = child.onSelectedRule && child.onSelectedRule.length > 0;
                            if (!hasSelectedRule) {
                                isValid = false;
                                missingValueParents.add(field.label);
                                isMissingValue = true;
                            }
                            if (!hasFields) {
                                isValid = false;
                                missingFieldParents.add(field.label);
                                isMissingField = true;
                            }
                        });
                    }
                }
            });
            return { isValid, missingFieldParents, missingValueParents, isMissingField, isMissingValue };
        };
        const { isValid, missingFieldParents, missingValueParents, isMissingField, isMissingValue } = validateFields();
        if (!isValid) {
            let title;
            let text;
            if (isMissingField && !isMissingValue) {
                title = I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingField');
                text = `${I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingFieldText')}${[...missingFieldParents].join(', ')}`;
            }
            else if (isMissingValue && !isMissingField) {
                title = I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingValue');
                text = `${I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingValueText')}${[...missingValueParents].join(', ')}`;
            }
            else {
                title = I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingFieldAndValue');
                text = `${I18n.m.getMessage('projectSettingsTicketLayoutRuleMissingFieldText')}${[...missingFieldParents].join(', ')}, ${I18n.m.getMessage('projectSettingsTicketLayoutRuleAnd')}${[...missingValueParents].join(', ')}`;
            }
            Routing.instance.alert.post({
                title,
                text,
                buttons: [<ContainedButton key="no" title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close}/>],
            });
            return;
        }
        let canceled = false;
        if (props.companySettings != null) {
            if (props.layout == null) {
                const c = new AddCompanyTicketLayout({
                    companyId: props.companySettings.id,
                    fields: currentLayout.fields,
                    title: currentLayout.title,
                }, currentLayout.id);
                await c.execute();
            }
            else {
                const c = new ChangeCompanyTicketLayout({ title: currentLayout.title, fields: currentLayout.fields, companyId: props.companySettings.id }, currentLayout.id);
                await new Promise((resolve, reject) => {
                    UpmeshClient.eventDispatcher.attach({
                        readModelName: 'CompanySettings',
                        attachKey: 'ticketLayoutSaved',
                        once: true,
                        callback: () => {
                            resolve();
                        },
                    });
                    c.execute(ClientStore.commandStore).catch(reject);
                });
            }
        }
        else if (props.project != null) {
            if (props.layout == null) {
                const c = new AddProjectTicketLayout({
                    projectId: props.project.id,
                    fields: currentLayout.fields,
                    title: currentLayout.title,
                }, currentLayout.id);
                await c.execute();
            }
            else {
                const c = new ChangeProjectTicketLayout({ title: currentLayout.title, fields: currentLayout.fields, projectId: props.project.id }, currentLayout.id);
                const hasRemovedFields = await c.hasRemovedFields();
                if (hasRemovedFields.length === 0) {
                    await new Promise((resolve, reject) => {
                        CurrentProject.projectChanged.once(() => {
                            resolve();
                        });
                        c.execute().catch(reject);
                    });
                }
                else {
                    const removedFields = [];
                    hasRemovedFields.forEach((field) => {
                        removedFields.push(field.label);
                    });
                    await new Promise((resolve, reject) => {
                        Routing.instance.alert.post({
                            title: I18n.m.getMessage('projectSettingsTicketLayoutFieldsRemovedTitle', {
                                fields: removedFields.join(', '),
                            }),
                            text: I18n.m.getMessage('projectSettingsTicketLayoutFieldsRemovedText'),
                            buttons: [
                                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={() => {
                                        canceled = true;
                                        Alert.instance?.close(() => {
                                            resolve();
                                        });
                                    }}/>,
                                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={() => {
                                        Alert.instance?.close(() => {
                                            CurrentProject.projectChanged.once(() => {
                                                resolve();
                                            });
                                            c.execute().catch(reject);
                                        });
                                    }} backgroundColor={ThemeManager.style.brandDanger}/>,
                            ],
                        });
                    });
                }
            }
        }
        if (!canceled)
            Dialog.instance?.close();
        else
            setLoading(false);
    };
    const submitForm = (_e) => {
        if (currentLayout.title == null || currentLayout.title.length === 0) {
            const s = new Map();
            s.set('title', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        setLoading(true);
        saveForm().catch((err) => {
            setLoading(false);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    return (<>
      {renderContent()}
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => {
            const hasChanges = props.layout == null || JSON.stringify(props.layout) !== JSON.stringify(currentLayout);
            if (hasChanges) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('ticketLayoutDiscardChangesQuestion'),
                    buttons: [
                        <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                        <ContainedButton key="yes" title={I18n.m.getMessage('discard')} onPress={() => {
                                Alert.instance?.close();
                                Dialog.instance?.close();
                            }} backgroundColor={ThemeManager.style.brandDanger}/>,
                    ],
                });
            }
            else {
                Dialog.instance?.close();
            }
        }} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton loading={loading} onPress={submitForm} title={props.layout ? I18n.m.getMessage('save') : I18n.m.getMessage('create')}/>
      </DialogActions>
    </>);
}
export const openAddOrChangeProjectTicketLayoutDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        width: 1024,
        closeOnTouchOutside: false,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <AddOrChangeProjectTicketLayoutDialog {...props}/>,
    });
};
