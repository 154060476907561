"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServerConnection = void 0;
var B64 = require("base-64");
var Url_1 = require("cqrs-shared/build/src/uri/Url");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var RemoteODataDB_1 = require("odatarepos/build/src/db/remote/RemoteODataDB");
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var socket_io_client_1 = require("socket.io-client");
var ts_events_1 = require("ts-events");
var Config_1 = require("../Config");
var ServerConnection = function () {
  function ServerConnection(serverConfig) {
    var _this = this;
    (0, _classCallCheck2.default)(this, ServerConnection);
    this.onConnect = new ts_events_1.AsyncEvent();
    this.onConnectionChanged = new ts_events_1.AsyncEvent();
    this.onConnectionError = new ts_events_1.AsyncEvent();
    this.onDisconnect = new ts_events_1.AsyncEvent();
    this.onSyncCouldStart = new ts_events_1.AsyncEvent();
    this.onSyncShouldStop = new ts_events_1.AsyncEvent();
    this._connectionErrors = [];
    this.status = 'prepared';
    this.close = (0, _asyncToGenerator2.default)(function* () {
      return new Promise(function (resolve) {
        _this.status = 'closed';
        if (_this._socket.connected) {
          _this._socket.close();
          _this.onDisconnected('closed');
        }
        _this.removeListener();
        resolve();
      });
    });
    this.isConnecting = false;
    this.connect = (0, _asyncToGenerator2.default)(function* () {
      var waitFor = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 15000;
      return new Promise(function (resolve) {
        _this.status = 'started';
        if (_this._socket.connected) {
          resolve(true);
        } else {
          _this.status = 'started';
          try {
            if (!_this.isConnecting && !_this._socket.connected) {
              _this.isConnecting = true;
              _this._socket.connect();
            }
            if (_this._socket.connected || waitFor <= 0) {
              _this.isConnecting = false;
              resolve(_this._socket.connected);
            } else WaitFor_1.WaitFor.instance.waitFor(function () {
              return _this._socket.connected;
            }, 100, waitFor).then(function () {
              _this.isConnecting = false;
              resolve(_this._socket.connected);
            }).catch(function (err) {
              resolve(false);
            });
          } catch (e) {
            _this.isConnecting = false;
            resolve(false);
          }
        }
      });
    });
    this.onConnected = function () {
      if (_this.isConnected) {
        _this.onConnect.post(_this._socket);
      }
      _this.onConnectionChange();
    };
    this.attachedServerEvents = new Map();
    this.attachServerEvent = function (ev, callback) {
      _this.attachedServerEvents.set(ev, {
        callback: callback
      });
      _this._socket.off(ev);
      _this._socket.on(ev, function (msg) {
        _this.attachedServerEvents.forEach(function (a, key) {
          if (key === ev) a.callback(msg);
        });
      });
    };
    this.lastConnection = false;
    this.onConnectedError = function (error) {
      _this.onConnectionError.post(error);
      _this.onDisconnected(error.toString());
      if (_this._connectionErrors.length > 10) {
        _this._connectionErrors.shift();
      }
      var errorMessage = error['message'] != null ? error['message'] : error.toString();
      _this._connectionErrors.push({
        error: error,
        time: new Date(),
        errorMessage: errorMessage
      });
      if (_this.lastConnection) setTimeout(function () {
        _this.connect().catch(function (err) {
          return void 0;
        });
      }, 1000);
      _this.onConnectionChange();
    };
    this.onConnectedTimeOut = function () {
      _this.onDisconnected('timeout');
      _this.onConnectionChange();
    };
    this.onDisconnected = function (reason) {
      _this.onSyncShouldStop.post();
      _this.onDisconnect.post(_this._socket);
      _this.onConnectionChange();
      if (reason === 'io server disconnect') {
        setTimeout(function () {
          return _this.socket.connect();
        }, 5000);
      }
    };
    this.onReConnected = function (attempt) {
      if (_this.isConnected) {
        _this.onConnect.post(_this._socket);
      }
      _this.onConnectionChange();
    };
    this.onReconnecting = function (attempt) {};
    ts_events_1.AsyncEvent.setScheduler(function (callback) {
      setTimeout(callback, 0);
    });
    this.config = serverConfig;
    this.remoteDB = new RemoteODataDB_1.RemoteODataDB();
    this.remoteDB.initDB(serverConfig.remoteOptions).catch(function (err) {
      return void 0;
    });
    this._token = this.remoteDB.authToken;
    this.createSocket(serverConfig.autoConnect === true);
  }
  return (0, _createClass2.default)(ServerConnection, [{
    key: "isConnected",
    get: function get() {
      return this.status === 'started' && this._socket.connected;
    }
  }, {
    key: "connectionErrors",
    get: function get() {
      return this._connectionErrors;
    }
  }, {
    key: "socket",
    get: function get() {
      return this._socket;
    }
  }, {
    key: "token",
    get: function get() {
      return this._token;
    }
  }, {
    key: "getUserIdFromToken",
    value: function getUserIdFromToken(token) {
      try {
        var userId = '0';
        var bValue = JSON.parse(B64.decode(token.split('.')[1]));
        if (bValue != null && bValue._id != null && bValue._id.length > 0) {
          userId = bValue._id;
        }
        return {
          userId: userId
        };
      } catch (e) {
        throw new ApplicationError_1.ApplicationError(`TokenError sc: ${e.toString()}`, 'tokenError', ApplicationError_1.ApplicationErrorStatusCode.UnauthorizedError);
      }
    }
  }, {
    key: "changeAuthToken",
    value: function () {
      var _changeAuthToken = (0, _asyncToGenerator2.default)(function* (newToken) {
        if (this._token != null && this._token.length > 0) {
          try {
            var oldT = this.getUserIdFromToken(this._token);
            if (oldT.userId === 'all') return;
          } catch (e) {}
        }
        this.config.remoteOptions.authToken = newToken;
        this.remoteDB = new RemoteODataDB_1.RemoteODataDB();
        yield this.remoteDB.initDB(this.config.remoteOptions);
        this._token = newToken;
      });
      function changeAuthToken(_x) {
        return _changeAuthToken.apply(this, arguments);
      }
      return changeAuthToken;
    }()
  }, {
    key: "dispatchSyncCouldStart",
    value: function dispatchSyncCouldStart() {
      if (this.isConnected && this.token.length > 0) this.onSyncCouldStart.post();
    }
  }, {
    key: "fetch",
    value: function () {
      var _fetch = (0, _asyncToGenerator2.default)(function* (path, init) {
        return this.remoteDB.fetchData(path, init);
      });
      function fetch(_x2, _x3) {
        return _fetch.apply(this, arguments);
      }
      return fetch;
    }()
  }, {
    key: "addListener",
    value: function addListener() {
      var _this2 = this;
      this.removeListener();
      this._socket.on('connect', this.onConnected);
      this._socket.on('disconnect', this.onDisconnected);
      if (this.attachedServerEvents.size > 0) {
        this.attachedServerEvents.forEach(function (_a, ev) {
          if (!_this2._socket.hasListeners(ev)) {
            _this2._socket.on(ev, function (msg) {
              _this2.attachedServerEvents.forEach(function (a, key) {
                if (key === ev) a.callback(msg);
              });
            });
          }
        });
      }
      this._socket.io.on('error', this.onConnectedError);
      this._socket.io.on('reconnect', this.onReConnected);
      this._socket.io.on('reconnect_attempt', this.onReconnecting);
      this._socket.io.on('reconnect_error', this.onConnectedError);
      this._socket.io.on('reconnect_failed', this.onConnectedError);
      if (this.connectIntervall != null) clearInterval(this.connectIntervall);
      this.connectIntervall = setInterval(function () {
        if (_this2._socket != null && !_this2._socket.connected && _this2.status === 'started') {
          if (_this2._socket.io._readyState === 'closed') _this2._socket.connect();
        }
      }, 5000);
    }
  }, {
    key: "removeListener",
    value: function removeListener() {
      this._socket.removeAllListeners();
      this._socket.off('connect', this.onConnected);
      this._socket.off('disconnect', this.onDisconnected);
      this._socket.io.off('error', this.onConnectedError);
      this._socket.io.off('reconnect', this.onReConnected);
      this._socket.io.off('reconnect_attempt', this.onReconnecting);
      this._socket.io.off('reconnect_error', this.onConnectedError);
      this._socket.io.off('reconnect_failed', this.onConnectedError);
      if (this.connectIntervall != null) clearInterval(this.connectIntervall);
    }
  }, {
    key: "restartSocket",
    value: function () {
      var _restartSocket = (0, _asyncToGenerator2.default)(function* () {
        if (this._socket != null) {
          if (this._socket.connected) {
            yield this._socket.close();
          }
          this.createSocket(this.config.autoConnect === true);
        } else {
          this.createSocket(this.config.autoConnect === true);
        }
      });
      function restartSocket() {
        return _restartSocket.apply(this, arguments);
      }
      return restartSocket;
    }()
  }, {
    key: "createSocket",
    value: function createSocket(autoConnect) {
      if (this._socket != null) return;
      var uri = Url_1.Url.getURLfromString(this.config.remoteOptions.apiUrl);
      var options = Object.assign({
        autoConnect: false,
        reconnection: false,
        path: `${uri.pathname}/socket.io`,
        timeout: 20000,
        transports: ['websocket']
      }, this.config.socketOptions, {
        extraHeaders: this.config.query != null ? this.config.query : {},
        query: this.config.query != null ? Object.assign({
          type: Config_1.ConfigHandler.config.serverName
        }, this.config.query) : {
          type: Config_1.ConfigHandler.config.serverName
        }
      });
      if (!Config_1.ConfigHandler.config.onlyWebsocketTransport) {
        options.transports = ['websocket', 'polling'];
      }
      var manager = new socket_io_client_1.Manager(uri.origin, options);
      this._socket = manager.socket('/');
      this.addListener();
      if (autoConnect) this.status = 'started';
    }
  }, {
    key: "onConnectionChange",
    value: function onConnectionChange() {
      if (this.lastConnection !== this._socket.connected) {
        this.lastConnection = this._socket.connected;
        if (this._socket.connected) {
          this._connectionErrors = [];
        }
        this.onConnectionChanged.post(this.lastConnection);
      }
    }
  }]);
}();
exports.ServerConnection = ServerConnection;