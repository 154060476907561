var _a;
import { ReadModels } from 'cqrs-core/src/core/query/ReadModels';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { ProOnlyDialogContent } from 'materialTheme/src/theme/components/ProOnlyDialogContent';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { RemoveUserFromCompany } from 'upmesh-core/src/client/commands/companies/RemoveUserFromCompany';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddUserToCompanyDialog } from './AddUserToCompanyDialog';
import { ChangeCompanyUserDialog } from './ChangeCompanyUserDialog';
const maxLicensesReachedImage = require('../../assets/img/maxLicensesReached.png');
export class CompanyUserView extends PureComponent {
    static getDerivedStateFromProps(nextProps) {
        return {
            tableMaxHeight: nextProps.size.contentHeight -
                ThemeManager.style.headerHeight -
                ThemeManager.style.getScreenRelativePixelSize(140),
        };
    }
    constructor(props) {
        super(props);
        this.renderThumb = (item, columnData, _index, sLayout) => {
            if (item == null)
                return <View />;
            const { style } = columnData;
            return (<View style={[
                    {
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingRight: 16,
                        paddingLeft: 16,
                        flexDirection: 'row',
                        direction: sLayout ? 'rtl' : 'ltr',
                        overflow: 'hidden',
                        maxWidth: '100%',
                    },
                    style,
                ]} key={`ViewAroundThumb${item.id}}`}>
        <View style={{ paddingRight: sLayout ? 0 : 8, paddingLeft: sLayout ? 8 : 0, paddingVertical: 4 }}>
          <UserImage size={36} user={item.user == null ? new UserEntity({ firstname: item.username }) : item.user} lightBox/>
        </View>
      </View>);
        };
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.applySearch = (allUsers, searchValue) => {
            const { searchbarValue } = this.state;
            const s = searchValue?.toLowerCase() ?? searchbarValue;
            if (s.length === 0)
                return allUsers;
            const res = allUsers.filter((user) => user.firstName?.toLowerCase().includes(s) ||
                user.lastName?.toLowerCase().includes(s) ||
                user.email?.toLowerCase().includes(s) ||
                user.translatedRole?.toLowerCase().includes(s) ||
                user.staffNumber?.toLowerCase().includes(s) ||
                user.groupName?.toLowerCase().includes(s) ||
                user.phone?.toLowerCase().includes(s) ||
                user.workingTimeModelMark?.toLowerCase().includes(s));
            return res;
        };
        this.updateData = (en) => {
            const { company } = this.props;
            if (en.readModelName === 'User' || en.readModelName === 'CompanyMember') {
                en.entities.forEach((e) => {
                    if (e.entity?.entityName === 'CompanyMember' &&
                        e.entity != null &&
                        e.entity.companyId === company.id) {
                        this.updateUser();
                    }
                    else if (e.entity?.entityName === 'User' &&
                        this.state.users.findIndex((u) => u.userId === e.entity?.id) > -1) {
                        this.updateUser();
                    }
                });
            }
        };
        this.removeUser = (user) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveEnterpriseUserQuestion', { username: user.username }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('remove')} onPress={this.removeUserNow(user)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeUserNow = (companyUser, force) => (_e) => {
            const { company } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const asyncNow = async () => {
                    try {
                        const remove = new RemoveUserFromCompany({ companyId: company.id, force }, companyUser.id, CurrentUser.token);
                        await remove.execute(ClientStore.commandStore);
                    }
                    catch (e) {
                        console.error(e);
                        if (e['messageCode'] === 'cantRemoveUserHeIsInvolvedInEnterpriseProjects') {
                            Routing.instance.alert.post({
                                text: `${I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjects')}\r\n${I18n.m.getMessage('cantRemoveUserHeIsInvolvedInEnterpriseProjectsForceRemoveOrDowngrade')}`,
                                buttons: [
                                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="later" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                    <ContainedButton key="now" title={I18n.m.getMessage('companyUserForceDowngrade')} onPress={this.removeUserNow(companyUser, 'downgrade')}/>,
                                    <ContainedButton key="now" title={I18n.m.getMessage('companyUserForceRemove')} onPress={this.removeUserNow(companyUser, 'remove')}/>,
                                ],
                            });
                        }
                        else {
                            Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                        }
                    }
                };
                asyncNow()
                    .catch((err) => console.error(err))
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const permitted = this.myRole() === 'admin';
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddUserDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openAddUserDialog = (_e) => {
            const { company, companySettings } = this.props;
            const asyncNow = async () => {
                const companyMembers = ReadModels.get('companyMember');
                const usedTimeTrackerLicenses = await companyMembers.count(`companyId eq '${companySettings.id}' and deleted ne true and licenseFree ne true and role eq 'timeTracker'`);
                const usedManagerLicenses = await companyMembers.count(`companyId eq '${companySettings.id}' and deleted ne true and licenseFree ne true and (role eq 'admin' or role eq 'user')`);
                const usedCollaboratorLicenses = await companyMembers.count(`companyId eq '${companySettings.id}' and deleted ne true and licenseFree ne true and role eq 'collaborator'`);
                const usedLicenses = usedTimeTrackerLicenses + usedManagerLicenses + usedCollaboratorLicenses;
                let licenses = 0;
                if (companySettings.managerLicenses != null)
                    licenses += companySettings.managerLicenses;
                if (companySettings.collaboratorLicenses != null)
                    licenses += companySettings.collaboratorLicenses;
                if (companySettings.timeTrackingLicenses != null)
                    licenses += companySettings.timeTrackingLicenses;
                if (licenses <= usedLicenses) {
                    RouterControl.instance.openUnRoutedDialog({
                        scrollable: true,
                        contentPadding: false,
                        content: (<ProOnlyDialogContent headline={I18n.m.getMessage('maxLicensesReached')} description={I18n.m.getMessage('maxLicensesReachedText')} buttonText={I18n.m.getMessage('ok')} buttonFunction={() => {
                                Dialog.instance?.close();
                            }} imageSource={maxLicensesReachedImage}/>),
                    });
                }
                else {
                    Dialog.instance?.open({
                        closeOnTouchOutside: false,
                        content: <AddUserToCompanyDialog company={company}/>,
                        contentPadding: false,
                        fullscreenResponsive: true,
                        scrollable: false,
                        showCloseIcon: true,
                    });
                }
            };
            asyncNow().catch(DefaultErrorHandler.showDefaultErrorAlert);
        };
        this.openEditUserDialog = (user) => (_e) => {
            if (this.myRole() === 'admin' && user != null) {
                console.debug('openEditUserDialog', user);
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeCompanyUserDialog companyMember={user} company={company}/>,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    scrollable: false,
                    showCloseIcon: true,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.showMemberDetails = (u, e) => {
            Routing.instance.openDialog('user', { userId: u.userId })(e);
        };
        this.onChangeSearch = (s) => {
            const { users } = this.state;
            const searchedUsers = this.applySearch(users, s);
            Routing.instance.changeQueryParameter({ q: s });
            this.setState({ searchbarValue: s, searchedUsers });
        };
        this.state = {
            tableMaxHeight: ResizeEvent.current.contentHeight -
                ThemeManager.style.headerHeight -
                ThemeManager.style.getScreenRelativePixelSize(140),
            users: [],
            myRole: this.myRole(),
            searchedUsers: [],
            searchbarValue: '',
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.company !== this.props.company || prevProps.company.users !== this.props.company.users) {
            this.updateUser();
        }
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyUserView',
            readModelName: 'CompanyMember',
            callback: this.updateData,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyUserViewUSER',
            readModelName: 'User',
            callback: this.updateData,
        });
        this.updateUser();
        this.openFab();
    }
    updateUser() {
        const { company, q } = this.props;
        if (this.updateTO)
            clearTimeout(this.updateTO);
        this.updateTO = setTimeout(() => {
            UpmeshClient.instance.modals.companyMember
                .get({ filter: `companyId eq '${company.id}' and deleted ne true` })
                .then((members) => {
                const addUser = [];
                members.forEach((u) => {
                    addUser.push(_a.getUserInfo(u));
                });
                return Promise.all(addUser);
            })
                .then((users) => {
                const searchedUsers = this.applySearch(users, q);
                this.setState({ users, searchedUsers, myRole: this.myRole() });
            })
                .catch((e) => console.debug('cant get member', e));
        }, 200);
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('CompanyMember', 'CompanyUserView');
        UpmeshClient.eventDispatcher.detach('User', 'CompanyUserViewUSER');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    getTableColumns() {
        const resultColumns = [
            {
                title: { icon: 'account-circle', toolTip: I18n.m.getMessage('userImage') },
                keyInData: 'username',
                style: { width: 64 },
                sortable: false,
                cellRenderer: this.renderThumb,
                dataType: 'string',
                minWidth: 64,
            },
            {
                title: I18n.m.getMessage('firstName'),
                keyInData: 'firstName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('lastName'),
                keyInData: 'lastName',
                style: { width: 250 },
                sortable: true,
                dataType: 'string',
                minWidth: 180,
            },
            {
                title: I18n.m.getMessage('email'),
                keyInData: 'emails',
                style: { width: 250 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('addressPhoneCompany'),
                keyInData: 'phone',
                style: { width: 250 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('staffNumber'),
                keyInData: 'staffNumber',
                style: { width: 150 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('group'),
                keyInData: 'groupName',
                dataType: 'string',
                style: { width: 200 },
            },
            {
                title: I18n.m.getMessage('role'),
                keyInData: 'translatedRole',
                dataType: 'string',
                style: { width: 200 },
            },
        ];
        if (CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking')) {
            resultColumns.splice(7, 0, {
                title: I18n.m.getMessage('workingTimeModel'),
                keyInData: 'workingTimeModelMark',
                dataType: 'string',
                style: { width: 200 },
            });
            resultColumns.push({
                title: I18n.m.getMessage('payroll'),
                keyInData: 'payrollTranslated',
                dataType: 'string',
                style: { width: 150 },
            });
        }
        return resultColumns;
    }
    render() {
        const { users, searchedUsers, tableMaxHeight, myRole, searchbarValue } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { companySettings } = this.props;
        const timeTrackers = users.filter((u) => u.role === 'timeTracker' && !u.licenseFree);
        const usedCollaboratorLicences = users.filter((u) => u.role === 'collaborator' && !u.licenseFree).length;
        const usedEnterpriseLicences = users.filter((u) => (u.role === 'admin' || u.role === 'user') && !u.licenseFree).length;
        const licenses = `${usedEnterpriseLicences}/${companySettings.managerLicenses != null ? companySettings.managerLicenses : 0} ${I18n.m.getMessage('companyUserLicensesFull')}`;
        const collaboratorLicenses = `${usedCollaboratorLicences}/${companySettings.collaboratorLicenses != null ? companySettings.collaboratorLicenses : 0} ${I18n.m.getMessage('companyUserLicensesCollaborator')}`;
        const timeTrackinglicenses = `${timeTrackers.length}/${companySettings.timeTrackingLicenses != null ? companySettings.timeTrackingLicenses : 0} ${I18n.m.getMessage('companyUserLicensesTimeTracking')}`;
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('accountCompanySettingsUserListHeaderTitle')}
          </MaterialText>
          <InfoButton text={I18n.m.getMessage('companySettingsUserInformation')}/>
        </View>
        <View style={{ flexDirection: 'column', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText>• {licenses}</MaterialText>
          <MaterialText>• {collaboratorLicenses}</MaterialText>
          <MaterialText>• {timeTrackinglicenses}</MaterialText>
        </View>

        <View style={{ marginLeft: 8, marginTop: 8 }}>
          <SearchBar searchBarValue={searchbarValue} searchOnChange={this.onChangeSearch}/>
        </View>

        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table keyExtractor={(item) => `${item.id}_${item.lastModifiedAt}`} tableName="CompanyUser" maxHeight={tableMaxHeight - 16} actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => [
                    {
                        icon: 'pencil-outline',
                        onAction: this.openEditUserDialog,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    { icon: 'delete', onAction: this.removeUser, toolTip: I18n.m.getMessage('remove') },
                ]
                : undefined} emptyTableImage={maxLicensesReachedImage} emptyTableText="" emptyTableHint="" data={searchedUsers} sortBy="lastName" sortDirection="asc" onRowPress={this.showMemberDetails} columns={this.getTableColumns()}/>
        </Card>
      </View>);
    }
}
_a = CompanyUserView;
CompanyUserView.getUserInfo = async (u) => {
    const users = ReadModels.get('User');
    let user;
    try {
        user = u.userId != null && u.userId.length > 0 ? await users.getById(u.userId) : undefined;
    }
    catch (e) {
        console.debug('cant get user', e);
    }
    const newUser = u;
    newUser.user = user;
    newUser.username = user != null ? user.getFullName() : `${u.firstName} ${u.lastName}`;
    if (user != null && (u.firstName !== user.firstname || u.lastName !== user.lastname)) {
        newUser.firstName = user.firstname;
        newUser.lastName = user.lastname;
    }
    newUser.translatedRole = I18n.m.getMessage('companyRoleManager');
    newUser.payrollTranslated = u.payroll ? I18n.m.getMessage('yes') : I18n.m.getMessage('no');
    if (newUser.role === 'admin')
        newUser.translatedRole = I18n.m.getMessage('companyRoleAdmin');
    else if (newUser.role === 'collaborator')
        newUser.translatedRole = I18n.m.getMessage('companyRoleUser');
    else if (newUser.role === 'timeTracker')
        newUser.translatedRole = I18n.m.getMessage('companyRoleTimetracker');
    else if (newUser.role === 'inactive')
        newUser.translatedRole = I18n.m.getMessage('companyRoleInactive');
    newUser.emails = user != null && user.emails != null ? user.emails.join(', ') : u.email != null ? u.email : '';
    if (u.phone != null) {
        newUser.phone = u.phone;
    }
    else if (user != null && user.phone != null) {
        newUser.phone = user.phone;
    }
    if (u.groupId != null) {
        try {
            const group = await UpmeshClient.instance.modals.group.getById(u.groupId);
            newUser.groupName =
                group.groupName === GroupEntity.getDefaultName()
                    ? I18n.m.getMessage('companyGroupDefaultName')
                    : group.groupName;
        }
        catch (e) {
            console.error('group not found', e);
        }
    }
    if (u.workingTimeModelId != null) {
        try {
            const workingTimeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(u.workingTimeModelId);
            newUser.workingTimeModelMark = workingTimeModel.mark;
        }
        catch (e) {
            console.error('workingTimeModel not found', e);
        }
    }
    return newUser;
};
