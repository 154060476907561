import React, { useEffect, useState } from 'react';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { View } from 'react-native';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export const ChangeTimeTrackDialog = React.memo((props) => {
    const [projectPickerList, setProjectPickerList] = useState([]);
    const [costCenterList, setCostCenterList] = useState([]);
    const [costUnitList, setCostUnitList] = useState([]);
    const [projectAndCostHelperText, setProjectAndCostHelperText] = useState(undefined);
    const [selectedSegment, setSelectedSegment] = useState(0);
    const [selectedProject, setSelectedProject] = useState(0);
    const [selectedProjectAndCost, setSelectedProjectAndCost] = useState({
        type: '',
        projectId: props.timeTrackingEntry.projectId,
        costId: props.timeTrackingEntry.costCenterId ?? '',
        costName: '',
    });
    const [taskList, setTaskList] = useState([]);
    const [selectedTask, setSelectedTask] = useState(0);
    const [startTimeQuickSelection, setStartTimeQuickSelection] = useState([]);
    const [start, setStart] = useState(new Date(props.timeTrackingEntry.starts));
    const [end, setEnd] = useState(props.timeTrackingEntry.ends != null ? new Date(props.timeTrackingEntry.ends) : undefined);
    const [endTimeQuickSelection, setEndTimeQuickSelection] = useState([]);
    const [date, setDate] = useState(new Date(props.timeTrackingEntry.starts));
    const [dateQuickSelection, setDateQuickSelection] = useState([]);
    const [pauseTimeQuickSelection, setPauseTimeQuickSelection] = useState([]);
    const [pause, setPause] = useState(props.timeTrackingEntry.pause != null
        ? new Date(1970, 0, 1, props.timeTrackingEntry.pause.hours, props.timeTrackingEntry.pause.minutes, 0, 0)
        : undefined);
    const [notes, setNotes] = useState(props.timeTrackingEntry.note != null ? props.timeTrackingEntry.note : '');
    const [isLoading, setIsLoading] = useState(true);
    const init = async () => {
        const { timeTrackingEntry } = props;
        const projects = await UpmeshClient.instance.modals.project.get({
            filter: `archived ne true and deleted ne true`,
        });
        const tmpProjectPickerList = [];
        projects.forEach((pr, index) => tmpProjectPickerList.push({ title: pr.title, onPressChipData: { type: 'project', id: pr.id, index } }));
        if (timeTrackingEntry.projectId != null &&
            timeTrackingEntry.projectTitle != null &&
            tmpProjectPickerList.findIndex((p) => p.onPressChipData.id === timeTrackingEntry.projectId) === -1) {
            tmpProjectPickerList.push({
                onPressChipData: { type: 'project', id: timeTrackingEntry.projectId, index: tmpProjectPickerList.length },
                title: timeTrackingEntry.projectTitle,
            });
        }
        const tmpCostUnitList = [];
        const tmpCostCenterList = [];
        const tmpCostCenters = await UpmeshClient.instance.modals.costCenter.get({
            filter: `companyId eq '${CompanyUserInfo.company?.id}' and deleted ne true and active ne false`,
        });
        tmpCostCenters.forEach((cc) => {
            if (cc.costType === 'costUnit')
                tmpCostUnitList.push({
                    title: `${cc.mark} - ${cc.description}`,
                    onPressChipData: { id: cc.id, type: 'costUnit', index: costUnitList.length },
                });
            else
                tmpCostCenterList.push({
                    title: `${cc.mark} - ${cc.description}`,
                    onPressChipData: { id: cc.id, type: 'costCenter', index: costCenterList.length },
                });
        });
        let tmpSelectedProject = 0;
        let tmpSelectedSegment = 2;
        if (timeTrackingEntry.projectId != null && timeTrackingEntry.projectId.length > 0) {
            const pi = tmpProjectPickerList.findIndex((t) => t.onPressChipData.id === timeTrackingEntry.projectId);
            if (pi !== -1) {
                tmpSelectedProject = pi;
                tmpSelectedSegment = 0;
            }
        }
        else if (timeTrackingEntry.costCenterId != null) {
            const pi = tmpCostCenterList.findIndex((t) => t.onPressChipData.id === timeTrackingEntry.costCenterId);
            if (pi !== -1) {
                tmpSelectedProject = pi;
                tmpSelectedSegment = 1;
            }
        }
        const tasks = await UpmeshClient.instance.modals.task.get({ filter: `deleted ne true and active ne false and productive eq true`, orderby: 'mark' }, CurrentUser.userId);
        const tmpTaskList = [];
        tasks.forEach((ta) => tmpTaskList.push({ title: `${ta.mark} - ${ta.description}`, data: ta.id }));
        let tmpSelectedTask = 0;
        if (timeTrackingEntry.taskId != null) {
            const ti = tasks.findIndex((t) => t.id === timeTrackingEntry.taskId);
            if (ti !== -1) {
                tmpSelectedTask = ti;
            }
        }
        const tmpStartTimeQuickSelection = [
            { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 6, 0, 0, 0)), time: { hour: 6, minute: 0 } },
            { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 7, 0, 0, 0)), time: { hour: 7, minute: 0 } },
            { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 8, 0, 0, 0)), time: { hour: 8, minute: 0 } },
            { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 9, 0, 0, 0)), time: { hour: 9, minute: 0 } },
        ];
        const tmpEndTimeQuickSelection = [
            {
                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 16, 0, 0, 0)),
                time: { hour: 16, minute: 0 },
            },
            {
                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 17, 0, 0, 0)),
                time: { hour: 17, minute: 0 },
            },
            {
                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 18, 0, 0, 0)),
                time: { hour: 18, minute: 0 },
            },
            {
                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 19, 0, 0, 0)),
                time: { hour: 19, minute: 0 },
            },
        ];
        const tmpPauseTimeQuickSelection = [
            { name: '0:15', time: { hour: 0, minute: 15 } },
            { name: '0:30', time: { hour: 0, minute: 30 } },
            { name: '0:45', time: { hour: 0, minute: 45 } },
            { name: '1:00', time: { hour: 1, minute: 0 } },
        ];
        const today = new Date();
        const tmpDateQuickSelection = [
            { name: I18n.m.getMessage('today'), days: today.getTime() },
            {
                name: I18n.m.getMessage('yesterday'),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0).getTime(),
            },
            {
                name: I18n.m.getMessage('dayBeforeYesterday'),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2, 0, 0, 0).getTime(),
            },
        ];
        if (CompanyUserInfo.me != null && CompanyUserInfo.me.workingTimeModelId != null) {
            try {
                const workingTimeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(CompanyUserInfo.me.workingTimeModelId);
                const d = workingTimeModel.getCorrectDaytime(new Date());
                if (d != null) {
                    if (d.start != null) {
                        const find = tmpStartTimeQuickSelection.findIndex((e) => e.time.hour === d.start.hours && e.time.minute === d.start.minutes);
                        if (find === -1) {
                            tmpStartTimeQuickSelection.unshift({
                                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.start.hours, d.start.minutes, 0, 0)),
                                time: { hour: d.start.hours, minute: d.start.minutes },
                            });
                        }
                    }
                    if (d.stop != null) {
                        const find = tmpEndTimeQuickSelection.findIndex((e) => e.time.hour === d.stop.hours && e.time.minute === d.stop.minutes);
                        if (find === -1) {
                            tmpEndTimeQuickSelection.unshift({
                                name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.stop.hours, d.stop.minutes, 0, 0)),
                                time: { hour: d.stop.hours, minute: d.stop.minutes },
                            });
                        }
                    }
                    if (d.pause != null) {
                        const find = tmpPauseTimeQuickSelection.findIndex((e) => e.time.hour === d.pause.hours && e.time.minute === d.pause.minutes);
                        if (find === -1) {
                            tmpPauseTimeQuickSelection.unshift({
                                name: `${d.pause.hours}:${d.stop.minutes > 9 ? d.pause.minutes : `0${d.pause.minutes}`}`,
                                time: { hour: d.pause.hours, minute: d.pause.minutes },
                            });
                        }
                    }
                }
            }
            catch (e) {
                console.debug('cant get working Time Model for user', e);
            }
        }
        const tmpSelectedProjectAndCost = {
            costName: '',
            costId: '',
            projectId: '',
            type: '',
        };
        if (timeTrackingEntry.costCenterId) {
            tmpSelectedProjectAndCost.costId = timeTrackingEntry.costCenterId;
            const unitIndex = tmpCostUnitList.findIndex((c) => c.onPressChipData.id === timeTrackingEntry.costCenterId);
            if (unitIndex > -1) {
                tmpSelectedProjectAndCost.costName = tmpCostUnitList[unitIndex].title;
                tmpSelectedProjectAndCost.type = 'costUnit';
            }
            else {
                const centerIndex = tmpCostCenterList.findIndex((c) => c.onPressChipData.id === timeTrackingEntry.costCenterId);
                if (centerIndex > -1) {
                    tmpSelectedProjectAndCost.costName = tmpCostCenterList[centerIndex].title;
                    tmpSelectedProjectAndCost.type = 'costUnit';
                }
            }
            if (timeTrackingEntry.projectId) {
                tmpSelectedProjectAndCost.projectId = timeTrackingEntry.projectId;
                tmpSelectedProjectAndCost.type = 'project';
                if (tmpSelectedProjectAndCost.costName.length === 0) {
                    const projectcc = await UpmeshClient.instance.modals.costCenter.get({
                        filter: `id eq '${timeTrackingEntry.costCenterId}'`,
                    });
                    if (projectcc && projectcc.length > 0)
                        tmpSelectedProjectAndCost.costName = projectcc[0].getName();
                }
            }
        }
        setSelectedProjectAndCost(tmpSelectedProjectAndCost);
        setProjectPickerList(tmpProjectPickerList);
        setSelectedTask(tmpSelectedTask);
        setSelectedSegment(tmpSelectedSegment);
        setSelectedProject(tmpSelectedProject);
        setTaskList(tmpTaskList);
        setCostUnitList(tmpCostUnitList);
        setCostCenterList(tmpCostCenterList);
        setStartTimeQuickSelection(tmpStartTimeQuickSelection);
        setEndTimeQuickSelection(tmpEndTimeQuickSelection);
        setPauseTimeQuickSelection(tmpPauseTimeQuickSelection);
        setDateQuickSelection(tmpDateQuickSelection);
        setIsLoading(false);
    };
    useEffect(() => {
        init().catch((err) => console.error('cant load data', err));
    }, []);
    const costCenterCheckForError = async (selected) => {
        let errorMessage;
        let projectAndCost = {
            type: selected.type,
            projectId: selected.type === 'project' ? selected.id : undefined,
            costId: selected.type !== 'project' ? selected.id : '',
            costName: '',
        };
        if (selected.type === 'project') {
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `deleted ne true`,
            });
            const center = costCenters.find((c) => c.projectIds != null && c.projectIds.indexOf(selected.id) > -1);
            projectAndCost = {
                projectId: projectAndCost.projectId,
                costId: center != null ? center.id : '',
                type: projectAndCost.type,
                costName: center != null ? center.getName() : '',
            };
            if (projectAndCost.costId === '') {
                errorMessage = I18n.m.getMessage('costCenterProjectWithoutCenterError');
            }
        }
        else if (selected.type === 'costUnit') {
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `deleted ne true and id eq '${selected.id}'`,
            });
            const { projectIds } = costCenters[0];
            if (projectIds == null || projectIds.length === 0) {
                errorMessage = I18n.m.getMessage('costCenterNoProjectError');
            }
            else if (projectIds.length === 1) {
                projectAndCost = {
                    projectId: projectIds[0] != null ? projectIds[0] : undefined,
                    costId: projectAndCost.costId,
                    type: projectAndCost.type,
                    costName: costCenters[0].getName(),
                };
            }
            else {
                const getProjects = await UpmeshClient.instance.modals.project.get({
                    filter: projectIds.length === 1
                        ? `id eq '${projectIds[0]}' and deleted ne true`
                        : `(id in ${JSON.stringify(projectIds)}) and deleted ne true`,
                });
                if (getProjects.length === 0) {
                    errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                }
                else if (getProjects.length > 1) {
                    errorMessage = I18n.m.getMessage('costCenterMultipleProjectsError');
                }
                else {
                    projectAndCost = {
                        projectId: getProjects[0].id != null ? getProjects[0].id : undefined,
                        costId: projectAndCost.costId,
                        type: projectAndCost.type,
                        costName: costCenters[0].getName(),
                    };
                }
            }
        }
        return { errorMessage, projectAndCost };
    };
    const onPressProjectChip = (newSelected) => {
        costCenterCheckForError(newSelected)
            .then((checked) => {
            setSelectedSegment(newSelected.type === 'project' ? 0 : newSelected.type === 'costCenter' ? 1 : 2);
            setSelectedProjectAndCost(checked.projectAndCost);
            setProjectAndCostHelperText(checked.errorMessage);
            setSelectedProject(newSelected.index);
        })
            .catch((err) => {
            console.error(err);
        });
    };
    const saveNow = (data) => () => {
        const create = new ChangeTimeTracking(data, props.timeTrackingEntry.id);
        create
            .execute(ClientStore.commandStore)
            .then(() => {
            Dialog.instance?.close();
        })
            .catch((e) => {
            console.debug('errors', e);
            setIsLoading(false);
            DefaultErrorHandler.showDefaultErrorAlert(e);
        });
    };
    const save = () => {
        if (date == null)
            return;
        setIsLoading(true);
        const s = start != null ? start : new Date();
        const starts = new Date(date.getFullYear(), date.getMonth(), date.getDate(), s.getHours(), s.getMinutes());
        const ends = end == null
            ? undefined
            : new Date(date.getFullYear(), date.getMonth(), date.getDate(), end.getHours(), end.getMinutes());
        if (ends != null && ends.getTime() < starts.getTime()) {
            ends.setDate(ends.getDate() + 1);
        }
        const data = {
            ends,
            starts,
            changeTime: new Date(),
            pause: pause != null ? { hours: pause.getHours(), minutes: pause.getMinutes() } : undefined,
            taskId: taskList.length > 0 ? taskList[selectedTask].data : undefined,
            note: notes,
            projectId: selectedProjectAndCost.projectId == null ? '' : selectedProjectAndCost.projectId,
            costCenterId: selectedProjectAndCost.costId,
        };
        props.checkAndSaveTimeTracking(new TimeTrackingEntity({ ...props.timeTrackingEntry, ...data }), saveNow(data));
    };
    const hasChanged = () => {
        const { timeTrackingEntry } = props;
        const note = timeTrackingEntry.note == null ? '' : timeTrackingEntry.note;
        return (note !== notes ||
            (timeTrackingEntry.starts == null && start != null) ||
            (timeTrackingEntry.starts != null && start == null) ||
            (timeTrackingEntry.starts != null &&
                start != null &&
                new Date(timeTrackingEntry.starts).getTime() !== start.getTime()) ||
            (timeTrackingEntry.ends == null && end != null) ||
            (timeTrackingEntry.ends != null && end == null) ||
            (timeTrackingEntry.ends != null &&
                end != null &&
                new Date(timeTrackingEntry.ends).getTime() !== end.getTime()) ||
            (timeTrackingEntry.pause == null && pause != null) ||
            (timeTrackingEntry.pause != null && pause == null) ||
            (timeTrackingEntry.pause != null &&
                pause != null &&
                (timeTrackingEntry.pause.hours !== pause.getHours() ||
                    timeTrackingEntry.pause.minutes !== pause.getMinutes())) ||
            (taskList.length > 0 && timeTrackingEntry.taskId !== taskList[selectedTask].data) ||
            (projectPickerList.length > selectedProject &&
                projectPickerList[selectedProject] != null &&
                timeTrackingEntry.projectId !== projectPickerList[selectedProject].onPressChipData.id) ||
            (costCenterList.length > selectedProject &&
                selectedSegment === 1 &&
                costCenterList[selectedProject] != null &&
                timeTrackingEntry.costCenterId !== costCenterList[selectedProject].onPressChipData.id) ||
            date == null ||
            I18n.m.date().localeDateString(new Date(timeTrackingEntry.starts)) !==
                I18n.m.date().localeDateString(new Date(date)));
    };
    const getProjectAndCostHelperText = () => {
        let costCenterPickerHelperText = projectAndCostHelperText || '';
        if ((costCenterPickerHelperText == null || costCenterPickerHelperText.length === 0) &&
            selectedProjectAndCost.costName &&
            selectedProjectAndCost.costName.length > 0 &&
            selectedProjectAndCost.type === 'project') {
            costCenterPickerHelperText = `${I18n.m.getMessage('costCenter')}: ${selectedProjectAndCost.costName}`;
        }
        return costCenterPickerHelperText;
    };
    const getProjectAndCostLabel = () => {
        let costCenterPickerLabel = I18n.m.getMessage('costCenter');
        if (selectedProjectAndCost.type === 'costUnit')
            costCenterPickerLabel = I18n.m.getMessage('costUnit');
        else if (selectedProjectAndCost.type === 'project') {
            costCenterPickerLabel = I18n.m.getMessage('project');
        }
        return costCenterPickerLabel;
    };
    return (<>
        <DialogTitle key="title">{I18n.m.getMessage('timeTrackingChangeTimeTrack')}</DialogTitle>
        <DialogContent key="content">
          <View style={{ padding: 0 }}>
            {projectPickerList.length === 0 && costCenterList.length === 0 && costUnitList.length === 0 ? null : (<OpenableChip key={`picker_${projectPickerList.length}_${costCenterList.length}_${costUnitList.length}`} looksLikeFI formInputLabel={getProjectAndCostLabel()} dialogTitle={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} formInputHelperText={getProjectAndCostHelperText()} formInputError={projectAndCostHelperText != null} selectedSegment={selectedSegment} selected={selectedProject} onPressChip={onPressProjectChip} segmentedChipsList={[
                { chips: projectPickerList, title: I18n.m.getMessage('projects'), type: 'project' },
                { chips: costCenterList, title: I18n.m.getMessage('costCenters'), type: 'costCenter' },
                { chips: costUnitList, title: I18n.m.getMessage('costUnits'), type: 'costUnit' },
            ]}/>)}
            {taskList.length === 0 ? null : (<FormInputPicker list={taskList} labelText={I18n.m.getMessage('journalEmployeesJob')} selectedIndex={selectedTask} onChangePicker={setSelectedTask}/>)}
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flex: 1, paddingRight: 8 }}>
                <DateInputFormFilled clearButton={false} selectDate={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStart')} timeQuickSelections={startTimeQuickSelection} value={start} helperText={start == null ? I18n.m.getMessage('timetrackingErrorNoStart') : undefined} error={start == null} onChange={setStart}/>
              </View>
              <View style={{ flex: 1, paddingLeft: 8 }}>
                <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStop')} helperText={end == null ? I18n.m.getMessage('timetrackingErrorNoEnd') : undefined} error={end == null} value={end} timeQuickSelections={endTimeQuickSelection} onChange={setEnd}/>
              </View>
            </View>
            <View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flex: 1, paddingRight: 8 }}>
                <DateInputFormFilled clearButton={false} labelText={I18n.m.getMessage('date')} value={date} quickSelections={dateQuickSelection} helperText={date == null ? I18n.m.getMessage('required') : undefined} error={date == null} onChange={setDate}/>
              </View>
              <View style={{ flex: 1, paddingLeft: 8 }}>
                <DateInputFormFilled selectDate={false} selectTime timeFormat="24" clearButton={false} labelText={I18n.m.getMessage('workingTimeModelsPause')} timeQuickSelections={pauseTimeQuickSelection} value={pause} onChange={setPause}/>
              </View>
            </View>
            <FormInputFilled labelText={I18n.m.getMessage('timeTrackingNotes')} initValue={notes} onChange={setNotes}/>
          </View>
        </DialogContent>
        <DialogActions key="actions" onBottom>
          <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()} disabled={isLoading}/>
          <ContainedButton title={I18n.m.getMessage('save')} onPress={save} loading={isLoading} disabled={!hasChanged || projectAndCostHelperText != null || end == null || start == null || date == null}/>
        </DialogActions>
      </>);
});
