import * as _ from 'lodash';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { BottomSheet } from 'materialTheme/src/theme/components/BottomSheet';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ExcelDownloadHelper } from 'materialTheme/src/theme/components/file/ExcelDownloadHelper';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { createWorkBook } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { MapMarker } from 'materialTheme/src/theme/components/zoom/MapMarker';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, UIManager, View } from 'react-native';
import { TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Config } from '../../config/Config';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddFilesPU } from '../file/AddFilesPU';
import { SharedFiles } from '../file/SharedFiles';
import { PlanZoomTileMapper } from '../plans/PlanZoomTileMapper';
import { CurrentProject } from '../project/CurrentProject';
import { PageView } from '../root/PageView';
import { openTicketExcelImportDialog } from './import/TicketExcelImportDialog';
import { ReportIcon } from './ReportIcon';
import { TicketCallOut } from './TicketCallOut';
import { TicketCardContent } from './TicketCardContent';
import { openTicketSortDialog } from './TicketSortDialog';
import { TicketsOverView } from './TicketsOverView';
import TicketTab from './TicketTab';
import { TicketTable } from './TicketTable';
import { TicketTagFilterIcon } from './TicketTagFilterIcon';
import { openTagFilter } from './ticketTagFilterUtil';
import TicketViewSelector from './TicketViewSelector';
import { getIconNameFromViewType, onPressNewView, parseAdditinalViewData } from './ticketViewUtil';
import { TicketWithPlanDownloader } from './TicketWithPlanDownloader';
const MINIMUM_TAB_WIDTH = 120;
export class TicketsDetailsView extends PureComponent {
    constructor(props) {
        super(props);
        this.onChangeMultiSelectStatus = (status) => {
            Menu.instance?.close();
            this.setState({ activeMultiselect: status });
        };
        this.openSortDialog = () => {
            Menu.instance?.close();
            const { tickets, sortBy, sortDirection, updateSortBy, updateSortDirection } = this.props;
            openTicketSortDialog(tickets, sortBy, sortDirection, updateSortBy, updateSortDirection);
        };
        this.onRegionChange = _.debounce((c) => {
            const { planVersion } = this.state;
            try {
                const p = planVersion != null
                    ? planVersion.getRealpointInCMFromPagepoint({
                        x: c.center.point.x,
                        y: c.center.point.y,
                    })
                    : c.center.point;
                const zTo = `${p.x.toString(10)}x${p.y.toString(10)}x${c.zoom.toString()}`;
                this.lastZoom = zTo;
                Routing.instance.changeQueryParameter({ zTo }, true);
            }
            catch (err) {
                console.debug('cant update hash', err);
            }
        }, 300);
        this.mounted = false;
        this.onLayout = (_e) => {
            const { splitViewWidth, size, childHeight } = this.props;
            const planSize = TicketsDetailsView.getMaxPlanSize(splitViewWidth, size, childHeight);
            this.setState({
                maxPlanWidth: planSize.width,
                maxPlanHeight: planSize.height,
            });
        };
        this.showPlan = () => {
            const { maxPlanHeight } = this.state;
            this.setState({ planHeight: maxPlanHeight });
        };
        this.showTickets = () => {
            this.setState({ planHeight: 0 });
        };
        this.pressedDown = () => {
            const { maxPlanHeight } = this.state;
            if (this.state.planHeight === 0) {
                this.setState({ planHeight: maxPlanHeight / 2 });
            }
            else {
                this.setState({ planHeight: maxPlanHeight });
            }
        };
        this.pressedUp = () => {
            const { maxPlanHeight } = this.state;
            if (this.state.planHeight === maxPlanHeight) {
                this.setState({ planHeight: maxPlanHeight / 2 });
            }
            else {
                this.setState({ planHeight: 0 });
            }
        };
        this.goBack = () => {
            const { currentUrl } = RouterControl.instance;
            const path = currentUrl.pathname.split('/');
            path.splice(path.length - 2, 1);
            const pathNew = `${path.join('/')}?${currentUrl.search}`;
            Routing.instance.goTo(pathNew, true);
        };
        this.openTicket = (e) => {
            Routing.instance.openDialog('ticket', { id: e.id })(e);
        };
        this.setMapRef = (r) => {
            this.zoomTile = r;
        };
        this.createTicket = (point) => {
            const { plan, planVersion } = this.props;
            if (plan != null && (plan.activePlanId === 'MAP' || planVersion != null)) {
                const position = plan.activePlanId === 'MAP' ? point : planVersion.getRealpointInCMFromPagepoint(point);
                this.props.pressCreateTicket(plan.id, position.x, position.y)(point);
            }
        };
        this.downloadVersionNow = () => {
            Menu.instance?.close();
            Alert.instance?.close();
            const { planVersion, tickets, plan } = this.state;
            if (plan != null && planVersion != null) {
                const project = CurrentProject.instance.getCurrentProject();
                if (!project?.showPlanQrCode) {
                    const ex = planVersion.orgFilename.substr(planVersion.orgFilename.lastIndexOf('.'));
                    CurrentProject.instance.downloadPlanNow(plan, planVersion, ex).catch((error) => {
                        DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                    });
                }
                else {
                    const pdfStarter = new TicketWithPlanDownloader(tickets, planVersion, plan, project, Config.b2cURL);
                    pdfStarter.open(false).catch((e) => {
                        console.error('error on downloading/creating pdf with tickets as download', e);
                    });
                }
            }
            else {
                console.debug('plan version is null');
            }
        };
        this.downloadVersionNowWithTickets = () => {
            Menu.instance?.close();
            const { planVersion, tickets, plan } = this.state;
            if (planVersion != null && plan != null) {
                const project = CurrentProject.instance.getCurrentProject();
                const pdfStarter = new TicketWithPlanDownloader(tickets, planVersion, plan, project, Config.b2cURL);
                pdfStarter.open(true).catch((e) => {
                    console.error('error on downloading/creating pdf with tickets as download', e);
                });
            }
        };
        this.printHandler = () => {
            Menu.instance?.close();
            const ticketTable = new TicketTable({ tickets: [] });
            const columns = ticketTable.getColumns();
            const name = `TicketTable${CurrentProject.instance.getCurrentProjectId()}`;
            const sheetName = name.length > 30 ? name.slice(0, 30) : name;
            const workBook = createWorkBook(this.props.tickets, new Set(), columns, null, sheetName);
            if (workBook != null) {
                ExcelDownloadHelper.saveAndOpen(sheetName, workBook).catch((err) => {
                    console.error('could not save excel file', err);
                });
            }
        };
        this.openExportImportMenu = (e) => {
            Menu.instance?.close();
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const { planVersion } = this.state;
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const items = [];
                if (planVersion != null) {
                    items.push({
                        text: I18n.m.getMessage('exportPlan'),
                        onPress: this.downloadVersionNow,
                    });
                    items.push({
                        text: I18n.m.getMessage('exportPlanWithTickets'),
                        onPress: this.downloadVersionNowWithTickets,
                    });
                }
                items.push({
                    text: I18n.m.getMessage('importExcelToolTip'),
                    onPress: openTicketExcelImportDialog({}),
                });
                items.push({
                    text: I18n.m.getMessage('exportExcelToolTip'),
                    onPress: () => this.printHandler(),
                });
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.getViewIcon = () => {
            const type = this.props.additionalViewData['type'] ?? 'list';
            let icon = {
                icon: 'format-list-bulleted-square',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
            };
            if (type === 'table') {
                icon = {
                    icon: 'table-large',
                    rotation: 0,
                    iconIconMoon: false,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                };
            }
            else if (type === 'calendar') {
                icon = {
                    icon: 'calendar',
                    rotation: 0,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                };
            }
            else if (type === 'canban') {
                icon = {
                    icon: 'poll',
                    rotation: 180,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                };
            }
            icon.onPress = this.openViewMenu;
            icon.toolTip = I18n.m.getMessage('changeView');
            return icon;
        };
        this.openViewMenu = (e) => {
            const { target } = e.nativeEvent;
            const { overrideViewData } = this.props;
            const onPressChangeView = (type) => () => {
                Menu.instance?.close();
                overrideViewData('type', type);
            };
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const asyncNow = async () => {
                    Menu.instance?.open({
                        client,
                        items: [
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToCanban'),
                                onPress: onPressChangeView('canban'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="poll" rotation={180}/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
                                onPress: onPressChangeView('list'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="format-list-bulleted-square"/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                                onPress: onPressChangeView('table'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="table-large"/>,
                                    width: 40,
                                },
                            },
                            {
                                text: I18n.m.getMessage('ticketsDetailsViewChangeToCalendarView'),
                                onPress: onPressChangeView('calendar'),
                                thumbnail: {
                                    thumbnail: <Icon toolTip="" icon="calendar"/>,
                                    width: 40,
                                },
                            },
                        ],
                    });
                };
                asyncNow().catch((err) => console.error(err));
            });
        };
        this.openThreeDots = (e) => {
            Menu.instance?.close();
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, (x, y, _width, height) => {
                const { planVersion } = this.state;
                const { additionalViewData } = this.props;
                const selectedView = additionalViewData['type'] ?? 'list';
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                const items = [];
                if (selectedView !== 'table') {
                    items.push({
                        text: I18n.m.getMessage('sort'),
                        onPress: this.openSortDialog,
                        thumbnail: {
                            thumbnail: (<Icon toolTip={I18n.m.getMessage('sort')} icon="sort" onPress={() => this.onChangeMultiSelectStatus(true)}/>),
                            width: 40,
                        },
                    });
                }
                const { filterActive, onPressFilter, currentTicketFilter, setFilter, allTickets } = this.props;
                const { tickets } = this.state;
                const reportButton = new ReportIcon({ selectedTickets: tickets, filterActive, onPressFilter }, {});
                items.push({
                    text: I18n.m.getMessage('ticketsViewFilterTags'),
                    onPress: (e) => {
                        openTagFilter(currentTicketFilter, allTickets, setFilter)(e);
                        Menu.instance?.close();
                    },
                    thumbnail: {
                        thumbnail: (<TicketTagFilterIcon currentFilter={currentTicketFilter} setFilter={setFilter} tickets={allTickets}/>),
                        width: 40,
                    },
                }, {
                    text: I18n.m.getMessage('report'),
                    onPress: (e) => {
                        reportButton.openDialogButton(e);
                        Menu.instance?.close();
                    },
                    thumbnail: {
                        thumbnail: (<ReportIcon key="reportButton" selectedTickets={tickets} filterActive={filterActive} onPressFilter={onPressFilter}/>),
                        width: 40,
                    },
                }, {
                    text: I18n.m.getMessage('bulkChangesIconTooltip'),
                    onPress: () => this.onChangeMultiSelectStatus(true),
                    thumbnail: {
                        thumbnail: (<Icon toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" onPress={() => this.onChangeMultiSelectStatus(true)}/>),
                        width: 40,
                    },
                });
                items.push({
                    text: I18n.m.getMessage('importExcelToolTip'),
                    onPress: openTicketExcelImportDialog({}),
                    thumbnail: {
                        thumbnail: (<Icon icon="microsoft-excel" toolTip={I18n.m.getMessage('importExcelToolTip')} onPress={openTicketExcelImportDialog({})}/>),
                        width: 40,
                    },
                });
                items.push({
                    text: I18n.m.getMessage('exportExcelToolTip'),
                    onPress: this.printHandler,
                    thumbnail: {
                        thumbnail: (<Icon icon="microsoft-excel" toolTip={I18n.m.getMessage('exportExcelToolTip')} onPress={this.printHandler}/>),
                        width: 40,
                    },
                });
                if (planVersion != null) {
                    items.push({
                        text: I18n.m.getMessage('exportPlan'),
                        onPress: this.downloadVersionNow,
                        thumbnail: {
                            thumbnail: <Icon toolTip={I18n.m.getMessage('exportPlan')} icon="download-outline"/>,
                            width: 40,
                        },
                    });
                    items.push({
                        text: I18n.m.getMessage('exportPlanWithTickets'),
                        onPress: this.downloadVersionNowWithTickets,
                        thumbnail: {
                            thumbnail: (<Icon toolTip={`${I18n.m.getMessage('exportPlanWithTickets')} + Tickets !!`} icon="download-outline"/>),
                            width: 40,
                        },
                    });
                }
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.onSnapShot = (file) => {
            const { plan } = this.state;
            if (plan != null) {
                try {
                    SharedFiles.files = [file];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'All' };
                    if (CurrentProject.instance.getCurrentProject() != null) {
                        data.projectId = CurrentProject.instance.getCurrentProject()?.id;
                    }
                    const t = CurrentProject.instance.getCurrentTicketId();
                    if (t != null) {
                        data.type = 'Tickets';
                        data.entityId = t;
                    }
                    AddFilesPU.openDialog(data)(null);
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
        };
        this.onPressAllViews = (e) => {
            const { views, selectTab } = this.props;
            const client = {
                height: 0,
                width: 0,
                x: e.nativeEvent.pageX,
                y: e.nativeEvent.pageY,
            };
            const items = views.map((v, index) => {
                const { type } = parseAdditinalViewData(v.additionalData);
                return {
                    onPress: () => {
                        selectTab(index);
                        Menu.instance?.close();
                    },
                    thumbnail: {
                        thumbnail: <Icon icon={getIconNameFromViewType(type)} toolTip=""/>,
                        width: 40,
                    },
                    text: v.name,
                };
            });
            Menu.instance?.open({
                client,
                items,
            });
        };
        const size = TicketsDetailsView.getMaxPlanSize(props.splitViewWidth, props.size, props.childHeight);
        this.state = {
            init: false,
            planId: props.plan?.id,
            planHeight: size.height / 2,
            maxPlanHeight: size.height,
            maxPlanWidth: size.width,
            plan: props.plan,
            planVersion: props.planVersion,
            tickets: [],
            activeMultiselect: false,
            searchBarOpen: false,
            searchBarFocus: false,
        };
    }
    componentDidMount() {
        this.init(this.props).catch((err) => console.debug('cant init TicketDetailsView', err));
    }
    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const stateChanges = {};
        if (_prevState == null || this.lastZoom !== this.state.zoomTo) {
            this.zoomTo();
        }
        if (prevProps.initialTab !== this.props.initialTab) {
            stateChanges.selectedTab = this.props.initialTab;
        }
        if (Object.keys(stateChanges).length > 0) {
            this.setState(stateChanges);
        }
    }
    zoomTo() {
        if (this.zoomTile != null) {
            const { zoomTo, planVersion, plan } = this.state;
            if (zoomTo != null && (planVersion != null || plan?.activePlanId === 'MAP')) {
                const p = zoomTo.split('x');
                const pointInPage = planVersion == null
                    ? { x: Number.parseFloat(p[0]), y: Number.parseFloat(p[1]) }
                    : planVersion.getPointInPageFromRealpointInCM({
                        x: Number.parseFloat(p[0]),
                        y: Number.parseFloat(p[1]),
                    });
                if (p.length >= 2) {
                    requestAnimationFrame(() => {
                        this.lastZoom = zoomTo;
                        if (this.zoomTile != null) {
                            let zoomLevel = planVersion == null ? 16 : 3;
                            if (p.length >= 3) {
                                zoomLevel = Number.parseFloat(p[2]);
                            }
                            const id = p.length > 3 ? p[3] : undefined;
                            this.zoomTile.zoomTo(pointInPage, zoomLevel, id);
                        }
                    });
                }
            }
        }
    }
    static getDerivedStateFromProps(nextProps, _prevState) {
        const planSize = TicketsDetailsView.getMaxPlanSize(nextProps.splitViewWidth, nextProps.size, nextProps.childHeight);
        return {
            planVersion: nextProps.planVersion,
            init: true,
            planId: nextProps.plan?.id,
            plan: nextProps.plan,
            tickets: nextProps.tickets,
            zoomTo: nextProps.zTo,
            maxPlanWidth: planSize.width,
            maxPlanHeight: planSize.height,
        };
    }
    static getMaxPlanSize(splitViewWidth, size, chlidHeight) {
        let sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        sViewHeight += chlidHeight;
        const height = size.contentHeight - TicketsDetailsView.separatorSize - ThemeManager.style.headerHeight - sViewHeight;
        let width = size.contentWidth;
        if (splitViewWidth)
            width -= splitViewWidth;
        return { width, height };
    }
    async init(props) {
        this.setState({
            planVersion: props.planVersion,
            init: true,
            plan: props.plan,
            tickets: props.tickets,
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        try {
            const replacer = (key, value) => {
                if (key === 'children') {
                    return '';
                }
                return value;
            };
            const changedProps = JSON.stringify(this.props, replacer) !== JSON.stringify(nextProps, replacer);
            const changedState = JSON.stringify(this.state, replacer) !== JSON.stringify(nextState, replacer);
            return changedState || changedProps;
        }
        catch (e) {
            return true;
        }
    }
    render() {
        if (!this.state.init) {
            return (<Page>
          <Spinner />
        </Page>);
        }
        const { filterActive, onPressFilter, currentTicketFilter, onSearch, searchWords, backButton, onChangeTicketOrder, showFab, childHeight, updateTicketFilter, views, initialTab, selectTab, size, additionalViewData, viewDataHasChanged, width, setFilter, } = this.props;
        const { plan, planHeight, maxPlanHeight, planVersion, tickets, activeMultiselect, searchBarOpen, searchBarFocus } = this.state;
        const selectedView = additionalViewData['type'] ?? 'list';
        const isGreaterThanBreakpointM = ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM;
        const selectedPlanVersionId = plan != null && plan.activePlanId != null ? plan.activePlanId : null;
        const { maxPlanWidth } = this.state;
        let thumb;
        if (plan != null &&
            (plan.activePlanId === 'MAP' || (planVersion != null && planVersion.id != null && planVersion.id.length > 0))) {
            const markers = [];
            if (planVersion != null || plan?.activePlanId === 'MAP') {
                for (let i = 0; i < tickets.length; i += 1) {
                    const t = tickets[i];
                    if (t.planPositionY != null && t.planPositionX != null) {
                        const pointInPage = planVersion == null
                            ? { x: t.planPositionX, y: t.planPositionY }
                            : planVersion.getPointInPageFromRealpointInCM({
                                x: t.planPositionX,
                                y: t.planPositionY,
                            });
                        markers.push(new MapMarker(t.id, TicketStatusColor.getMarkerForStatus(t.ticketStatus, t.completionOn), pointInPage, (<View style={{
                                position: 'relative',
                                width: 236,
                                height: 'auto',
                                zIndex: 8,
                                overflow: 'visible',
                                padding: 0,
                            }}>
                    {Platform.OS === 'web' ? (<TicketCardContent project={CurrentProject.instance.getCurrentProject()} ticket={t}/>) : (<TicketCallOut ticket={t}/>)}
                  </View>)));
                    }
                }
            }
            thumb =
                planHeight === 0 ? null : (<PlanZoomTileMapper onSnapShot={this.onSnapShot} plan={plan} planVersion={planVersion} type={planVersion == null ? 'map' : 'plan'} zoomAble ref={this.setMapRef} key={`tickets_plan_${planVersion == null ? plan.id : planVersion.id}`} onCalloutPress={this.openTicket} marker={markers} url={planVersion == null
                        ? 'map'
                        : `${UpmeshClient.instance.url}/plan/tiles/${planVersion.projectId}/planVersion/${planVersion.id}`} onRegionChange={this.onRegionChange} offlineData={planVersion == null
                        ? undefined
                        : OfflineDataDownloader.isPlanVersionSynced(planVersion.projectId, planVersion.id)} onLongPress={this.createTicket}/>);
        }
        let style;
        let webStyle;
        if (Platform.OS === 'web') {
            style = { cursor: 'n-resize' };
        }
        const useThreeDots = !isGreaterThanBreakpointM;
        const icons = [];
        const filterIcon = (<Icon key={`filterButton${filterActive}`} icon={filterActive ? 'filter-remove' : 'filter-outline'} toolTip={I18n.m.getMessage('filter')} onPress={onPressFilter} color={filterActive ? ThemeManager.style.brandPrimary : ThemeManager.style.defaultIconColor}/>);
        if (searchBarOpen) {
            icons.push(<Icon key="closeSearchIcon" icon="keyboard-backspace" toolTip="" onPress={() => this.setState({ searchBarOpen: false })}/>);
        }
        else if (useThreeDots || searchBarFocus) {
            if (!isGreaterThanBreakpointM)
                icons.push(<Icon key="searchIcon" icon="magnify" toolTip={I18n.m.getMessage('searchTickets')} onPress={() => this.setState({ searchBarOpen: true })} color={searchWords && searchWords.length > 0
                        ? ThemeManager.style.brandPrimary
                        : ThemeManager.style.defaultIconColor}/>);
            icons.push(filterIcon, <Icon {...this.getViewIcon()} key="viewIcon"/>, <Icon key="threeDotKey" toolTip={I18n.m.getMessage('more')} icon="dots-vertical" color={ThemeManager.style.defaultIconColor} disabled={false} onPress={this.openThreeDots}/>);
        }
        else {
            icons.push(filterIcon);
            icons.push(<TicketTagFilterIcon currentFilter={currentTicketFilter} setFilter={setFilter} tickets={tickets}/>);
            if (selectedView !== 'table') {
                icons.push(<Icon key="sortButton" toolTip={I18n.m.getMessage('sort')} icon="sort" onPress={this.openSortDialog}/>);
            }
            icons.push(<Icon {...this.getViewIcon()} key="viewIcon"/>);
            icons.push(<ReportIcon key="reportButton" selectedTickets={tickets} filterActive={filterActive} onPressFilter={onPressFilter}/>, <Icon key="bulkChangeIcon" toolTip={I18n.m.getMessage('bulkChangesIconTooltip')} icon="checkbox-multiple-marked-outline" onPress={() => this.onChangeMultiSelectStatus(true)}/>, <Icon key="ticketImportAndExportMenu" toolTip={I18n.m.getMessage('ticketImportAndExportMenu')} icon="dots-vertical" color={ThemeManager.style.defaultIconColor} outerSize={36} onPress={this.openExportImportMenu}/>);
        }
        let sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 96 : 48;
        sViewHeight += childHeight;
        let searchBarWidth;
        if (isGreaterThanBreakpointM && searchBarFocus)
            searchBarWidth = 294 - 50;
        else if (isGreaterThanBreakpointM)
            searchBarWidth = 150 - 50 + (selectedView === 'table' ? 36 : 0);
        else if (!searchBarOpen)
            searchBarWidth = 0;
        const tabBarWidth = width - 428;
        const hasOverflow = MINIMUM_TAB_WIDTH * views.length > tabBarWidth;
        const tabActions = [];
        if (isGreaterThanBreakpointM) {
            if (hasOverflow)
                tabActions.push(<Icon key="allViewsChevron" icon="chevron-down" toolTip={I18n.m.getMessage('ticketsViewAllViews')} onPress={this.onPressAllViews}/>);
            tabActions.push(<Icon key="newViewIcon" icon="plus" toolTip={I18n.m.getMessage('ticketsCreateNewView')} onPress={onPressNewView}/>);
        }
        else {
            tabActions.push(<Ripple style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 2,
                    flex: 1,
                }} onPress={() => {
                    BottomSheet.instance?.open({
                        height: (ResizeEvent.current.contentHeight / 3) * 2,
                        content: (<TicketViewSelector views={views} viewDataHasChanged={viewDataHasChanged} additionalViewData={additionalViewData} selectTab={selectTab} selectedTab={initialTab}/>),
                    });
                }}>
          <View style={{ flex: 1 }}>
            <MaterialText numberOfLines={1}>{views[initialTab]?.name}</MaterialText>
          </View>
          {viewDataHasChanged && (<View style={{
                        marginLeft: 8,
                        height: 6,
                        width: 6,
                        borderRadius: 3,
                        backgroundColor: ThemeManager.style.brandPrimary,
                    }}/>)}
          <Icon icon="chevron-down" toolTip=""/>
        </Ripple>);
        }
        return (<PageView onLayout={this.onLayout} key="Plan" headerProps={{
                title: '',
                searchBarProps: {
                    searchBarValue: searchWords,
                    searchBarPlaceholder: I18n.m.getMessage('searchTickets'),
                    searchOnChange: onSearch,
                    tooltip: I18n.m.getMessage('searchTickets'),
                    onFocus: () => this.setState({ searchBarFocus: true }),
                    onBlur: () => this.setState({ searchBarFocus: false }),
                },
                searchBarOnly: searchBarOpen,
                persistRightIcons: true,
                searchBarWidth,
                leftButtons: !backButton
                    ? undefined
                    : [
                        <Icon key="BackButton" toolTip={I18n.m.getMessage('tooltipBackToTicketSite')} icon="arrow-left" onPress={this.goBack} outerSize={36}/>,
                    ],
                rightButtons: icons,
                tabBarProps: isGreaterThanBreakpointM
                    ? {
                        minItemWidth: MINIMUM_TAB_WIDTH,
                        tabs: views.map((view) => ({ title: view.name })),
                        selected: initialTab,
                        hideOverflow: true,
                        hideMoreButton: true,
                        alwaysShowSelected: true,
                        customTabRenderer: (tab, index, dimensions) => (<TicketTab tab={tab} index={index} dimensions={dimensions} view={views[index]} selected={initialTab === index} onSelect={() => selectTab(index)} additionalData={additionalViewData} viewDataHasChanged={viewDataHasChanged}/>),
                    }
                    : undefined,
                tabActions,
            }}>
        {this.props.children != null && (<View style={{ width: '100%', height: childHeight }}>{this.props.children}</View>)}
        <View style={{
                position: 'relative',
                height: size.contentHeight - sViewHeight,
                width: maxPlanWidth,
            }}>
          {selectedPlanVersionId != null && selectedPlanVersionId.length > 0 ? (<View style={{
                    width: maxPlanWidth,
                    height: size.contentHeight - sViewHeight,
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                }} ref={(r) => {
                    this.secondView = r;
                }}>
              <View key={`tickets_plan_${selectedPlanVersionId}`} style={{
                    ...webStyle,
                    position: 'absolute',
                    top: 0,
                    height: planHeight,
                    left: 0,
                    right: 0,
                    overflow: 'hidden',
                }}>
                {thumb}
              </View>
              <View style={[
                    {
                        position: 'absolute',
                        top: planHeight,
                        flexDirection: 'row',
                        left: 0,
                        right: 0,
                        height: TicketsDetailsView.separatorSize,
                        width: maxPlanWidth,
                        backgroundColor: 'transparent',
                        borderTopWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'rgba(0,0,0,0.3)',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        alignItems: 'center',
                    },
                ]}>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                }}>
                  {planHeight === 0 && size.windowWidth > 600 ? (<ContainedButton title={I18n.m.getMessage('ticketsShowPlan')} onPress={this.showPlan} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>) : null}
                  {planHeight === maxPlanHeight && size.windowWidth > 600 ? (<ContainedButton title={I18n.m.getMessage('ticketsShowTickets')} onPress={this.showTickets} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>) : null}
                </View>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                  <Icon icon="chevron-up" onPress={this.pressedUp} disabled={planHeight === 0} toolTip={I18n.m.getMessage('tooltipUp')}/>
                  <Icon toolTip={I18n.m.getMessage('tooltipDown')} icon="chevron-down" onPress={this.pressedDown} disabled={planHeight === maxPlanHeight}/>
                </View>
                <View style={{
                    flexDirection: 'row',
                    width: '33%',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                }}>
                  <View style={[
                    {
                        height: 8,
                        width: 54,
                        backgroundColor: 'transparent',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    },
                    style,
                ]}/>
                </View>
              </View>
              <View style={{
                    position: 'absolute',
                    top: planHeight + TicketsDetailsView.separatorSize,
                    left: 0,
                    right: 0,
                    width: maxPlanWidth,
                    bottom: 8,
                    height: size.contentHeight -
                        planHeight -
                        TicketsDetailsView.separatorSize -
                        ThemeManager.style.headerHeight,
                }}>
                <TicketsOverView key="overview" size={size} onChangeTicketOrder={onChangeTicketOrder} activeMultiselect={activeMultiselect} onChangeMultiselectStatus={this.onChangeMultiSelectStatus} showFab={showFab} pressCreateTicket={this.props.pressCreateTicket} currentTicketFilter={currentTicketFilter} updateTicketFilter={updateTicketFilter} onPressFilter={onPressFilter} filterActive={filterActive} width={maxPlanWidth} height={size.contentHeight - planHeight - TicketsDetailsView.separatorSize - sViewHeight} style={{
                    position: 'relative',
                    top: 0,
                    left: 0,
                    right: 0,
                    width: maxPlanWidth,
                    height: size.contentHeight - planHeight - TicketsDetailsView.separatorSize - sViewHeight,
                }} tickets={tickets} planId={this.props.plan != null ? this.props.plan.id : undefined} selectedView={selectedView} views={views} selectedTab={initialTab}/>
              </View>
            </View>) : (<TicketsOverView size={size} onChangeTicketOrder={onChangeTicketOrder} activeMultiselect={activeMultiselect} onChangeMultiselectStatus={this.onChangeMultiSelectStatus} pressCreateTicket={this.props.pressCreateTicket} currentTicketFilter={currentTicketFilter} onSearch={onSearch} searchWords={searchWords} onPressFilter={onPressFilter} filterActive={filterActive} width={maxPlanWidth} height={size.contentHeight - sViewHeight} showFab={showFab} tickets={tickets} selectedView={selectedView} updateTicketFilter={updateTicketFilter} views={views} selectedTab={initialTab}/>)}
        </View>
      </PageView>);
    }
}
TicketsDetailsView.separatorSize = 32;
TicketsDetailsView.defaultProps = {
    title: 'upmesh',
};
